import { defineApi } from '../common/api-definition';
import { SLPaymentSchedule, SLPaymentScheduleUtilisation } from '../structured-loan/SLPaymentScheduleType';
import { EventViewRequest } from './EventViewRequest';
import { PeriodDetailsWithDays } from './PeriodDetailsType';

export type CustomDynamicScheduleViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      sequenceId: string;
      periodIndex: number;
    }
  | EventViewRequest;

export type CustomDynamicScheduleView = {
  type: 'next' | 'past';
  loanId: string;
  sequenceId: string;
  version: number;
  loanLevel: string;
  currency: string;
  period: PeriodDetailsWithDays;
  externalTrancheId: string | undefined;
  utilisation: SLPaymentScheduleUtilisation;
  paymentSchedules: SLPaymentSchedule[];
};

export const customDynamicScheduleViewApi = defineApi<{
  body: CustomDynamicScheduleViewRequest;
  response: CustomDynamicScheduleView;
}>({
  url: 'structured-loans/custom-dynamic-schedule-view',
  method: 'post',
});
