import { object, Schema } from 'yup';
import { LoanStartConfigurationValidation, ValidationOptions } from './LoanValidation';

const getPartialValidationSchema = (fields: string[], schema: Record<string, Schema<any>>) => {
  const result: any = {};

  Object.entries(schema).forEach(([key, value]) => {
    if (fields.includes(key)) {
      result[key] = value;
    }
  });

  return object(result);
};

export const getLoanStartConfigurationValidationSchema = (
  fields: (keyof ReturnType<typeof LoanStartConfigurationValidation>)[],
  options?: ValidationOptions
) => {
  return getPartialValidationSchema(fields, LoanStartConfigurationValidation(options));
};

export function isNumeric(n: any): n is string | number {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
