import { defineApi } from '../common/api-definition';
import {
  StructuredAgreementType,
  StructuredConstructionType,
  StructuredTranchesType,
} from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';
import { StructuredStartDrawdownType, StructuredStartUtilisationType } from './structured-start-configuration-type';

export type StartConfigurationView = UtilisationStartConfigurationView | ConstructionStartConfigurationView;

export type UtilisationStartConfigurationView = SharedStartConfigurationView & {
  type: 'utilisation';
  utilisation?: StructuredStartUtilisationType;
};

export type ConstructionStartConfigurationView = SharedStartConfigurationView & {
  type: 'construction';
  drawdown?: StructuredStartDrawdownType;
  construction?: StructuredConstructionType;
};

export type SharedStartConfigurationView = {
  loanId: string;
  facilityId: string;
  sequenceId: string;
  version: number;
  state: StructuredLoanState;
  loan: StructuredAgreementType;
  currency?: string;
  tranche: StructuredTranchesType;

  // Set if this is a new sequence that can be amended freely.
  amendNewSequence?: true;
};

export type StartConfigurationViewRequest = {
  loanId: string;
  facilityId: string;
  sequenceId: string;
};

export const startConfigurationViewApi = defineApi<{
  body: StartConfigurationViewRequest;
  response: StartConfigurationView;
}>({
  url: 'structured-loans/start-configuration-view',
  method: 'post',
});
