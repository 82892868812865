import { defineApi } from '../common/api-definition';
import {
  OutboundNotificationAction,
  OutboundNotificationDTO,
  OutboundNotificationState,
} from './outbound-notifications.type';

export interface UpdateOutboundNotificationsBody {
  readonly action: OutboundNotificationAction;
  readonly notificationsData: readonly { readonly id: string; readonly version: number }[];
}

export interface SucceededAndErrors {
  succeeded: string[];
  errors: string[];
}

export type UpdateOutboundNotificationsResult = {
  successfulOutboundNotificationsNr: number;
  failedOutboundNotificationsNr: number;
};

export type GetOutboundNotifications = {
  states: readonly OutboundNotificationState[];
};

export const outboundNotificationsApi = {
  getAll: defineApi<{ body: GetOutboundNotifications; response: readonly OutboundNotificationDTO[] }>({
    url: 'outbound-notifications/get-all',
    method: 'post',
  }),
  updateAll: defineApi<{
    body: UpdateOutboundNotificationsBody;
    response: UpdateOutboundNotificationsResult;
  }>({
    url: 'outbound-notifications/update-all',
    method: 'post',
  }),
  sendReady: defineApi<{ response: SucceededAndErrors }>({ url: 'outbound-notifications/send-ready', method: 'post' }),
} as const;
