import RsList from '../../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../../components/rs-elements/rs-list/RsListItem';
import { getStaticMedia } from '../../../../../constants/Aws';

export default function NegotiationServiceTemplates() {
  const items: RsListItem[] = [
    new RsListItem(
      'The platform offers LMA-based loan agreement templates for different types of CRE loans and jurisdictions'
    ),
    new RsListItem('Add own loan agreement templates to platform'),
    new RsListItem('Possibility for both parties to specify which content in the loan agreements can be edited'),
  ];
  return (
    <div className="section is-grey">
      <h4 className="has-text-centered headline-underlined">
        USE YOUR OWN LOAN AGREEMENT TEMPLATES OR TEMPLATES AVAILABLE ON THE PLATFORM
      </h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-6">
            <div className="img-container">
              <img src={getStaticMedia('/images/static-pages/negotiation_1.png')} />
            </div>
          </div>
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
        </div>
      </div>
    </div>
  );
}
