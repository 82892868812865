import { InterestRateTypeKey } from '../structured-loan/InterestRateType';
import { GenericOption } from './options';

export const InterestRateTypeOptions: GenericOption<InterestRateTypeKey, string>[] = [
  {
    value: 'fixed',
    label: 'Fixed',
  },
  {
    value: 'floating',
    label: 'Floating',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
  {
    value: 'custom-dynamic',
    label: 'Custom Dynamic',
  },
];

export const InterestRateTypeOptionsOmitCustomDynamic: GenericOption<InterestRateTypeKey, string>[] = [
  {
    value: 'fixed',
    label: 'Fixed',
  },
  {
    value: 'floating',
    label: 'Floating',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];
