import Auth from '@aws-amplify/auth';
import { capitalize, concat } from 'lodash';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSWRConfig } from 'swr';
import Notifications from '../../../components/notifications/Notifications';
import { ChatContactStatus } from '../../../constants/ChatConstants';
import { setLoading, syncBetweenTabs } from '../../../redux/redux-app/app-actions';
import { getCurrentUser, getUserCompanies } from '../../../redux/redux-auth/auth-selectors';
import { getCompanyInformationOrNull, getCompanyOrNull } from '../../../redux/redux-company/company-selectors';
import { resetCompanies, resetLoanState } from '../../../redux/redux-loans/loan-actions';
import ClientPath from '../../../routes/ClientPath';
import { NavigationContext } from '../../../routes/NavigationContext';
import { RsApplicationLinks } from '../../../routes/RsApplicationLinks';
import { updatePresenceStatusPromise } from '../../../services/api/chat-module';
import { getAndPopulateCurrentUser } from '../../../services/api/get-and-populate-current-user';
import { isRouteAllowed, useAccessRightsRoutes } from '../../../services/permissions/permissions';
import { signOut } from '../../../services/utils/sign-out';
import { COMPANY_KYC_STATUSES } from '../../../types/Company';
import { UserCompanyType } from '../../../types/UserTypes';
import { RsNavigationLink } from '../classes/RsNavigationLink';
import { clearUserData } from './clear-user-data';
import RsNavLink from './RsNavLink';
import RsNavLinkDropdown from './RsNavLinkDropdown';

const LoggedInMenuComponent = () => {
  const { loading: _navigationLoading, mutateNavigationLoading } = useContext(NavigationContext);
  const [state, setState] = useState<any>({ currentUserFullName: null, middleLinks: [], endLinks: [] });
  const companyInformation = useSelector(getCompanyInformationOrNull);
  const currentUser = useSelector(getCurrentUser);
  const company = useSelector(getCompanyOrNull);
  const userCompanies = useSelector(getUserCompanies);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { cache } = useSWRConfig();
  const accessRightsMap = useAccessRightsRoutes();

  const logOut = async () => {
    try {
      mutateNavigationLoading(true);
      dispatch(setLoading('Signing you out...'));
      navigate(ClientPath.home);

      try {
        await updatePresenceStatusPromise(ChatContactStatus.offline);
      } catch (er) {
        // eslint-disable-next-line no-console
        console.log(er);
      }
      await signOut();
      clearUserData({ swrCache: cache });
      dispatch(setLoading(false));
      window.location.href = '/'; // reload makes sure everything is refreshed.
    } catch (error) {
      dispatch(setLoading(false));
      mutateNavigationLoading(false);
      navigate(ClientPath.home);
    } finally {
      mutateNavigationLoading(true);
    }
  };

  useEffect(() => {
    const showAdministration =
      Boolean(company && company.completed) &&
      company?.kycStatus === COMPANY_KYC_STATUSES.APPROVED &&
      isRouteAllowed(accessRightsMap, {
        module: 'administration',
        objectType: 'loan',
        action: 'read',
      });

    const showNegotiation =
      Boolean(company && company.completed) &&
      company?.kycStatus === COMPANY_KYC_STATUSES.APPROVED &&
      isRouteAllowed(accessRightsMap, {
        module: 'negotiation',
        objectType: 'loan-negotiation',
        action: 'read',
      });

    const showMarketplace =
      Boolean(company && company.completed) &&
      company?.kycStatus === COMPANY_KYC_STATUSES.APPROVED &&
      isRouteAllowed(accessRightsMap, {
        module: 'marketplace',
        objectType: 'loan-application',
        action: 'read',
      });

    const hasLoanAdminAccess = isRouteAllowed(accessRightsMap, {
      module: 'platform-admin',
      objectType: 'multiple',
      action: 'upload-templates',
    });

    const hasUsersAdminAccess = isRouteAllowed(accessRightsMap, {
      module: 'platform-admin',
      objectType: 'multiple',
      action: 'read-users',
    });

    const hasCompaniesAdminAccess = isRouteAllowed(accessRightsMap, {
      module: 'platform-admin',
      objectType: 'multiple',
      action: 'read-company',
    });

    const showRealstocksAdmin =
      Boolean(company) && (hasLoanAdminAccess || hasUsersAdminAccess || hasCompaniesAdminAccess);

    setState({
      currentUserFullName: `${currentUser.attributes['custom:firstName']} ${currentUser.attributes['custom:surname']}`,
      middleLinks: [
        new RsNavigationLink('Marketplace', ClientPath.modules.loanApplication.index).setAllow(showMarketplace),
        new RsNavigationLink('Negotiation', ClientPath.modules.negotiation.index).setAllow(showNegotiation),
        new RsNavigationLink('Administration', ClientPath.modules.slAdministration.index).setAllow(showAdministration),
        RsApplicationLinks().realstocksAdmin(
          {
            companies: hasCompaniesAdminAccess,
            users: hasUsersAdminAccess,
            loans: hasLoanAdminAccess,
          },
          showRealstocksAdmin
        ),
      ],
      endLinks: concat(
        userCompanies && userCompanies.length > 0
          ? userCompanies
              .filter((company) => {
                return !(currentUser.attributes['custom:company'] === company.id);
              })
              .map((company: UserCompanyType) =>
                new RsNavigationLink(
                  company.companyInformation.companyName.length > 20
                    ? `${company.companyInformation.companyName.slice(0, 20)}...`
                    : company.companyInformation.companyName
                )
                  .setAllow(true)
                  .setOptions({
                    customActiveCondition:
                      currentUser.attributes['custom:company'] === company.id &&
                      location.pathname.indexOf(ClientPath.company.create()) < 0,
                  })
                  .setCallback(async () => {
                    mutateNavigationLoading(true);
                    try {
                      if (currentUser.attributes['custom:company'] !== company.id) {
                        dispatch(setLoading(`Switching to ${capitalize(company.companyInformation.companyName)}`));
                        await Auth.updateUserAttributes(currentUser, {
                          'custom:company': company.id,
                        });
                        clearUserData({ swrCache: cache });
                        dispatch(resetLoanState());
                        dispatch(resetCompanies());
                        await getAndPopulateCurrentUser();
                        await navigate(ClientPath.company.view);
                        syncBetweenTabs();
                      } else {
                        clearUserData({ swrCache: cache });
                        dispatch(resetLoanState());
                        dispatch(resetCompanies());
                        await navigate(ClientPath.company.view);
                      }
                    } catch (error) {
                      toast.error(`We were unable to switch your company. Please contact us if the problem persists.`);
                    } finally {
                      mutateNavigationLoading(false);
                      dispatch(setLoading(false));
                    }
                  })
              )
          : [],
        [
          new RsNavigationLink('Add New Company', ClientPath.company.lookup).setAllow(true).setOptions({
            divider: userCompanies && userCompanies.length > 1 ? true : false,
            customActiveCondition: location.pathname.indexOf(ClientPath.company.create()) >= 0,
          }),
          new RsNavigationLink('My Company', ClientPath.company.view).setAllow(true),
          new RsNavigationLink('My Profile', ClientPath.account.profile).setAllow(true),
          new RsNavigationLink('Log out')
            .setOptions({ classes: 'login control' })
            .setCallback(() =>
              setTimeout(() => {
                logOut();
              }, 0)
            )
            .setAllow(Boolean(currentUser)),
        ]
      ),
    });
  }, [location.pathname, companyInformation?.companyName, userCompanies?.length, accessRightsMap]);

  let dropdownComponent = '';

  if (companyInformation) {
    dropdownComponent =
      companyInformation.companyName.length > 20
        ? `${companyInformation.companyName.slice(0, 20)}...`
        : companyInformation.companyName;
  } else dropdownComponent = 'Account';

  return (
    <Fragment>
      <div className="navbar-start">
        {state.middleLinks.map((middleLink: RsNavigationLink) =>
          middleLink.allow ? (
            <RsNavLink key={`middle_link_${middleLink.path}_${middleLink.label}`} link={middleLink} />
          ) : (
            ''
          )
        )}
      </div>

      <div className="navbar-end">
        <Notifications className="is-hidden-touch" />
        <RsNavLinkDropdown label={dropdownComponent} links={state.endLinks}></RsNavLinkDropdown>
      </div>
    </Fragment>
  );
};

export default LoggedInMenuComponent;
