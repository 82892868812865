import { RsCognitoUser } from '@realstocks/types';
import { createSelector } from 'reselect';
import { AppState, AuthState } from '../../store';
import { UserCompanyType } from '../../types/UserTypes';
/**
 * Get 'auth' state.
 *
 * @param {Object} state
 */
const getAuth = (state: AppState) => state.auth;

/**
 * Return true/false if the user is logged in or not.
 *
 * @param {*} state
 */
export const getIsLoggedIn = createSelector(getAuth, (authState: AuthState) => authState.isLoggedIn);

/**
 * Get current user instance
 *
 * @param {*} state
 */
export const getCurrentUser = createSelector(getAuth, (authState: AuthState): RsCognitoUser => {
  if (authState.currentUser) return authState.currentUser;

  throw Error('Current user must be set');
});

export const getCurrentUserCurrency = createSelector(
  getCurrentUser,
  (cognitoUser) => cognitoUser.attributes['custom:currency']
);

export const getCurrentUserOrNull = createSelector(getAuth, (authState: AuthState): AuthState['currentUser'] => {
  return authState.currentUser;
});

/**
 * Get current user instance
 *
 * @param {*} state
 */
export const getUserCompanies = createSelector(
  getAuth,
  (authState: AuthState): UserCompanyType[] | null => authState.companies
);
