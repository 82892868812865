import { defineApi } from '../common/api-definition';
import { AmortisationTypeKey } from './AmortisationType';
import { InterestRateTypeKey } from './InterestRateType';

export type SLPaymentSchedule = {
  date: string;
  outstandingStartPeriod: number;
  outstandingEndPeriod: number;
  interest?: number;
  interestRate?: number;
  coverage?: number;
  amortisation?: number;
  prepayment?: number;
  dynamicInputEnabled?: boolean;
};

export type SLPaymentScheduleUtilisation = {
  utilisation_date?: string;
  utilisation_amount?: number;
};

export type SLPaymentTableScheduleType = {
  utilisation: SLPaymentScheduleUtilisation;
  paymentSchedules: SLPaymentSchedule[];
  scheduleColumns?: ScheduleColumns[];
  interestType?: InterestRateTypeKey;
  amortisationType?: AmortisationTypeKey;
};

export type ScheduleColumns =
  | 'coverage'
  | 'interestRate'
  | 'amortisation'
  | 'prepayments'
  | 'dynamicInterest'
  | 'dynamicAmortisation';

export type SequencePaymentScheduleRequest = { loanId: string; sequenceId: string };

/** The payment schedule for the current tranche of the sequence */
export const sequencePaymentScheduleApi = defineApi<{
  body: SequencePaymentScheduleRequest;
  response: SLPaymentTableScheduleType;
}>({
  url: 'structured-loans/sequence-payment-schedule',
  method: 'post',
});
