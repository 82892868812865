import { getMonth, getYear, isValid } from 'date-fns';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import { range } from 'lodash';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import { DATEPICKER_MIN_YEAR } from '../../../constants/Generic';
import {
  DATE_FORMAT,
  DAYLESS_DATE_FORMAT,
  formatDate,
  QUARTERS_FORMAT,
  YEARLESS_DATE_FORMAT,
} from '../../../services/utils/format-date';
import RsInfo from '../../rs-elements/rs-info/RsInfo';
import RsLoading from '../../rs-loading/RsLoading';
import { RsLabel } from '../rs-label/RsLabel';
import RsFieldError from '../RsFieldError';
import { rsFieldDisabledClass, RsFormContext } from '../RsForm';
import { getInputErrorClass } from '../utils/form-helpers';
import './RsDatePicker.scss';

type Props = {
  id: string;
  label: string;
  onChange?: Function;
  minDate?: Date;
  maxDate?: Date;
  children?: React.ReactNode; // tooltip
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  loading?: boolean;
  yearless?: { year: number };
  dayless?: boolean;
  icon?: boolean;
  quarters?: boolean;
  datePickerProps?: ReactDatePickerProps;
} & FieldAttributes<any>; // TODO: this intersection type just reduces to `any`

const getYearlessRange = (year: number): { min: Date; max: Date } => {
  return {
    min: new Date(`01-01-${year}`),
    max: new Date(`12-31-${year}`),
  };
};

export default function RsDatePicker(props: Props) {
  const [field, meta, actions] = useField(props);
  const [calendarRef, setCalendarRef] = useState<any>();
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const {
    mode,
    disabledForm,
    showValidatingText,
    getFieldIssues,
    ignoreRequireTouched,
    loading: formLoading,
  } = useContext(RsFormContext);
  const { isValidating: isValidatingGlobal, setFieldValue, setFieldTouched } = useFormikContext();

  const {
    isClearable = true,
    yearless = false,
    datePickerProps = {},
    icon = true,
    dayless = false,
    quarters = false,
  } = props;

  const years = range(
    props.minDate ? getYear(props.minDate) : DATEPICKER_MIN_YEAR,
    props.maxDate ? getYear(props.maxDate) + 1 : getYear(new Date()) + 1,
    1
  );

  const getMinMaxDates = (props: any) => {
    if (yearless) {
      const { min, max } = getYearlessRange(yearless.year);

      return {
        minDate: min,
        maxDate: max,
      };
    }

    return {
      minDate: props.minDate || undefined,
      maxDate: props.maxDate || new Date(),
    };
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    if (!isValidatingGlobal) {
      setIsValidating(false);
    }
  }, [isValidatingGlobal]);

  const onChange = async (date: Date | null, event: React.SyntheticEvent<any> | undefined) => {
    setFieldTouched(field.name, true, true);
    if (date) {
      setFieldValue(field.name, formatDate(date));
    } else if (isClearable) {
      setFieldValue(field.name, undefined);
    }

    setIsValidating(true);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const onTouch = () => {
    actions.setTouched(true, true);
  };

  const renderHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: any) => (
    <div className={`calendar-container`}>
      <button
        type="button"
        className="button button-item is-inverted"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        {'<'}
      </button>

      <select value={getYear(date)} onChange={(event) => changeYear(event.target.value)}>
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        type="button"
        className="button button-item is-inverted"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        {'>'}
      </button>
    </div>
  );

  const renderYearOnlyHeader = ({
    date,
    changeYear,
    increaseYear,
    decreaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }: any) => (
    <div className={`calendar-container`}>
      <button
        type="button"
        className="button button-item is-inverted"
        onClick={decreaseYear}
        disabled={prevYearButtonDisabled}
      >
        {'<'}
      </button>

      <select value={getYear(date)} onChange={(event) => changeYear(event.target.value)}>
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        type="button"
        className="button button-item is-inverted"
        onClick={increaseYear}
        disabled={nextYearButtonDisabled}
      >
        {'>'}
      </button>
    </div>
  );

  const renderYearlessHeader = ({
    date,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: any) => (
    <div className={`calendar-container`}>
      <button
        type="button"
        className="button button-item is-inverted"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        {'<'}
      </button>

      <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        type="button"
        className="button button-item is-inverted"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        {'>'}
      </button>
    </div>
  );

  const { minDate, maxDate } = getMinMaxDates(props);

  const errorClass = getInputErrorClass(props.name, meta, ignoreRequireTouched, getFieldIssues);
  const isDisabled = props.disabled || disabledForm;

  if ([yearless, dayless, quarters].filter((x) => x).length > 1) {
    throw new Error('RsInput: only one of [yearless, dayless, quarters] can be enabled');
  }

  return (
    <Fragment>
      <div className={`rs-field rs-calendar-container ${props.disabled ? rsFieldDisabledClass : ''} ${errorClass}`}>
        <div className="rs-field-content">
          {props.label && <RsLabel {...props} />}

          <div
            className={`field-wrapper ${icon ? 'has-label-after-input' : ''}  ${
              props.required ? 'required-input' : ''
            } ${props.fullWidth ? 'full-width' : ''}`}
          >
            {props.loading || formLoading ? (
              <RsLoading height="34px" />
            ) : (
              <>
                {mode === 'edit' && (
                  <>
                    <DatePicker
                      ref={(c) => setCalendarRef(c)}
                      disabled={isDisabled}
                      isClearable={isClearable && !isDisabled}
                      minDate={minDate}
                      maxDate={maxDate}
                      popperClassName={'rs-datepicker-popper'}
                      placeholderText={props.placeholder}
                      autoComplete="off"
                      id={props.id || props.name}
                      dateFormat={
                        yearless
                          ? YEARLESS_DATE_FORMAT
                          : dayless
                          ? DAYLESS_DATE_FORMAT
                          : quarters
                          ? QUARTERS_FORMAT
                          : DATE_FORMAT
                      }
                      showMonthYearPicker={dayless}
                      showQuarterYearPicker={quarters}
                      onChangeRaw={onTouch}
                      renderCustomHeader={
                        yearless ? renderYearlessHeader : quarters || dayless ? renderYearOnlyHeader : renderHeader
                      }
                      selected={field.value && isValid(new Date(field.value)) ? new Date(field.value) : null}
                      onChange={onChange}
                      {...datePickerProps}
                    />

                    {icon && (
                      <span
                        className="label-after-input"
                        onClick={() => {
                          if (calendarRef) {
                            calendarRef.setOpen(!calendarRef.isCalendarOpen());
                          }
                        }}
                      >
                        <i className="far fa-calendar-alt"></i>
                      </span>
                    )}

                    {props.children ? (
                      <RsInfo id={`${props.name}_${Math.random()}`} type="dark">
                        {props.children}
                      </RsInfo>
                    ) : (
                      ''
                    )}
                  </>
                )}

                {mode === 'view' && (
                  <Fragment>
                    {field.value && isValid(new Date(field.value)) ? (
                      <p className="has-text-weight-bold rs-no-margin">
                        {formatDate(new Date(field.value), yearless ? YEARLESS_DATE_FORMAT : DATE_FORMAT)}
                      </p>
                    ) : formLoading ? (
                      <RsLoading height="22px" />
                    ) : (
                      <p className="has-text-weight-bold rs-no-margin">-</p>
                    )}
                  </Fragment>
                )}
              </>
            )}
          </div>
        </div>

        {mode === 'edit' && <RsFieldError name={props.name} />}
        {showValidatingText && isValidating && (
          <span style={{ textAlign: 'right', width: '100%', fontSize: '0.6rem', color: '#5a709a' }}>VALIDATING...</span>
        )}
      </div>
    </Fragment>
  );
}
