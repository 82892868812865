import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSetLoading } from '../../effects/useSetLoading';
import ClientPath from '../../routes/ClientPath';
import './SomethingWentWrong.scss';

const SomethingWentWrongPage = () => {
  const navigate = useNavigate();
  const setLoading = useSetLoading();
  useEffect(() => setLoading(false), []);

  return (
    <div className="something-went-wrong-page">
      <h2 className="has-text-centered">We are sorry, something went wrong!</h2>
      <p className="has-text-centered">
        Please contact us if problem persists at <a href="mailto:support@realstocks.io">support@realstocks.io</a>
      </p>

      <div className="columns is-centered is-vcentered">
        <button
          type="button"
          className="button is-primary go-back-btn"
          onClick={() => {
            navigate(ClientPath.company.view, { replace: true });
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default SomethingWentWrongPage;
