import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useOutsideAlerter } from '../../layout/rs-main-navigation/components/RsNavLinkDropdown';
import {
  getNewNotificationsWithActions,
  getNewNotificationsWithoutActions,
} from '../../redux/redux-notifications/notifications-selectors';
import { AppState } from '../../store';
import { TNotification } from '../../types/notifications';
import RsCloseBtn from '../rs-elements/rs-close-btn/RsCloseBtn';
import AllNotifications from './AllNotifications';
import NewNotificationsWithActions from './NewNotificationsWithActions';
import NewNotificationsWithoutActions from './NewNotificationsWithoutActions';
import './Notifications.scss';
import NotificationNavIcon from './NotificationsNavIcon';

type Props = {
  newNotificationsWithActions: TNotification[];
  newNotificationsWithoutActions: TNotification[];
  className?: string;
};

const Notifications = (props: Props) => {
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const [_windowWidth, setWindowWidth] = React.useState<number | null>(null);
  const [selectedTab, setSelectedTab] = React.useState<string>('new_actions');
  const dropdownRef = useRef(null);

  useOutsideAlerter(dropdownRef, setShowDropdown);

  React.useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [window.innerWidth]);

  React.useEffect(() => {
    if (showDropdown === true) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showDropdown]);

  const handleKeyDown = (evt: any) => {
    let isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape) {
      setShowDropdown(false);
    }
  };

  const handleResizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  return (
    <div
      ref={dropdownRef}
      className={`${props.className || ''} navbar-item rs-nav-link-dropdown-component has-dropdown notification ${
        showDropdown ? 'rs-expanded' : 'rs-collapsed'
      }`}
    >
      <NotificationNavIcon
        setShowDropdown={() => {
          setShowDropdown(true);
        }}
      />
      <div className={`navbar-dropdown is-boxed notification-popup`}>
        <div className="close-btn-container">
          <RsCloseBtn onClick={() => setShowDropdown(!showDropdown)} />
        </div>
        <div className="tab-header">
          <div
            className={selectedTab === 'new_actions' ? 'actions active' : 'actions'}
            onClick={() => setSelectedTab('new_actions')}
          >
            Actions needed{' '}
            {props.newNotificationsWithActions && props.newNotificationsWithActions.length > 0
              ? `(${props.newNotificationsWithActions.length})`
              : ''}
          </div>
          <div
            className={selectedTab === 'new_no_actions' ? 'new active middle' : 'new'}
            onClick={() => setSelectedTab('new_no_actions')}
          >
            New{' '}
            {props.newNotificationsWithoutActions && props.newNotificationsWithoutActions.length > 0
              ? `(${props.newNotificationsWithoutActions.length})`
              : ''}
          </div>
          <div className={selectedTab === 'all' ? 'all active right' : 'all'} onClick={() => setSelectedTab('all')}>
            All
          </div>
        </div>
        {selectedTab === 'new_actions' && <NewNotificationsWithActions closeDropdown={closeDropdown} />}
        {selectedTab === 'new_no_actions' && <NewNotificationsWithoutActions />}
        {selectedTab === 'all' && <AllNotifications />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    newNotificationsWithActions: getNewNotificationsWithActions(state),
    newNotificationsWithoutActions: getNewNotificationsWithoutActions(state),
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
