import { format } from 'date-fns';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const QUARTERS_FORMAT = 'yyyy-QQQ';
export const YEARLESS_DATE_FORMAT = 'MMM dd';
export const DAYLESS_DATE_FORMAT = 'yyyy MMM';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;
export const DATE_TIME_WITH_SECONDS_FORMAT = `${DATE_FORMAT} HH:mm:ss`;

/**
 * Format all dates in a certain format.
 *
 * @param date
 */
export function formatDate(date: Date, dateFormat = DATE_FORMAT): string {
  return format(date, dateFormat);
}

/**
 * Format date in date and time format.
 *
 * @param date
 */
export function formatDateWithTime(date: Date) {
  return format(date, DATE_TIME_FORMAT);
}

/**
 * Format date in date and time format.
 *
 * @param date
 */
export function formatDateWithTimeWithSeconds(date: Date) {
  return format(date, DATE_TIME_WITH_SECONDS_FORMAT);
}
