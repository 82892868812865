import Auth from '@aws-amplify/auth';
import { ClientMetaData, SignInOpts } from '@aws-amplify/auth/lib-esm/types';
import { RsCognitoUser } from '@realstocks/types';
import { AWS_CHALLENGES } from '../../constants/Aws';
import { getAndPopulateCurrentUser } from '../api/get-and-populate-current-user';
import { writeInactiveTimeCookie } from '../api/RsApi';
import { registerUserSession } from '../aws/register-user-session';

export async function rsSignIn(
  usernameOrSignInOpts: string | SignInOpts,
  pw?: string,
  clientMetadata?: ClientMetaData
): Promise<RsCognitoUser | any> {
  const signInResponse = await Auth.signIn(usernameOrSignInOpts, pw, clientMetadata);

  if (
    signInResponse.challengeName &&
    [AWS_CHALLENGES.newPasswordRequired, AWS_CHALLENGES.forceChangePassword].indexOf(signInResponse.challengeName) >= 0
  ) {
    return signInResponse;
  }

  writeInactiveTimeCookie();
  const currentUser = await getAndPopulateCurrentUser(false);

  if (currentUser) await registerUserSession(currentUser);

  return signInResponse;
}
