import { AxiosRequestConfig } from 'axios';
import { RsApiConfigInterface, RsApiQueryParams } from './RsApi';

type AxiosResponseType = 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream' | undefined;

export class RsApiConfig implements AxiosRequestConfig, RsApiConfigInterface {
  public headers?: any;
  public response?: true;
  public responseType?: AxiosResponseType;
  public queryStringParameters?: RsApiQueryParams;
  public body?: any;

  constructor() {
    return this;
  }

  public setHeaders(headers: any) {
    this.headers = headers;

    return this;
  }

  public setBody(body: any) {
    this.body = body;

    return this;
  }
}
