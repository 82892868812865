import CloseBtn from '../../components/close-btn/CloseBtn';
import RsList from '../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../components/rs-elements/rs-list/RsListItem';
import { textColumnPolicy } from '../../components/rs-table/rs-columns/RsTableColumns';
import RsTableExtended from '../../components/rs-table/RsTable';
import { ProcessEnv } from '../../constants/ProcessEnv';
import './PrivacyPolicy.scss';

export default function PrivacyPolicy() {
  const personalDataTypes = [
    {
      category: 'User information: Information gathered at registration*',
      text: `Name, email, phone number, address, nationality, date of birth. This information we require from users to register with our service.`,
    },
    {
      category: 'Identification information: Information gathered during onboarding*',
      text: `BankID, social security number, passport number and related details. We are obliged to collect certain documentation of such information, for instance in the form of copies of your passport, driver’s license, or the like.`,
    },
    {
      category: 'Information related to legal requirements*',
      text: `Information collected to fulfill for example customer due diligence and Know Your Customer requirement as well as anti-money laundering, terrorist financing, and fraud detection, prevention and investigation.`,
    },
    {
      category: 'Technical behavioural data and identification data',
      text: `Monitoring the data subject’s web behaviour and use of Services, for example by means of cookies or similar technical identification data.
        Examples of data that can be collected include unique device identifiers, IP addresses, device software version numbers and identifiers, and rough location information via GPS, IP address or mobile networks.`,
    },
    {
      category: 'Rights management of the data subject, such as consents and prohibitions',
      text: `Platform related rights management.
        Marketing prohibitions and consents.
        Communication and measures relating to the rights of the data subject (see the rights of the data subject below).`,
    },
  ];

  const retentionPeriodItems = [
    {
      category: 'Information you give to us',
      text: `As long as necessary to fulfill the purpose personal data has been gathered for or as required by applicable laws.`,
    },
    {
      category: 'Technically gathered data',
      text: `As long as necessary to fulfill the purpose personal data has been gathered for or as required by applicable laws.`,
    },
  ];

  const personalDataUsageItems = [
    new RsListItem(`Provide the Services as requested by you;`),
    new RsListItem(`Improve and personalize the Services, as well as to develop new products and services;`),
    new RsListItem(`Correspond with you regarding customer service issues;`),
    new RsListItem(`For marketing and communication purposes;`),
    new RsListItem(`To enforce the terms of the Services;`),
    new RsListItem(`Ensure the functioning and maintenance of the Services;`),
    new RsListItem(`Ensure you of compliance with the Terms and this Privacy policy; and`),
    new RsListItem(`To comply with legal obligations applicable to Realstocks`),
  ];

  return (
    <div className="container privacy-policy">
      <div className="close-btn-container">
        <CloseBtn />
      </div>

      <h2 className="has-text-centered">PRIVACY POLICY</h2>

      <div className="columns is-centered">
        <a
          href={`${ProcessEnv.aws.s3.buckets.media}/pages/privacy-policy/Realstocks-Privacy-Policy.pdf`}
          download="Realstocks - Lender Terms of Service"
        >
          <button className="button is-primary download-btn">
            <span>
              <i className="fas fa-file-pdf"></i>
            </span>
            Download
          </button>
        </a>
      </div>

      <p>
        The Deon Digital AG platform <b>Realstocks</b> (“<b>We</b>”, “<b>Our</b>”) respects the privacy of those using
        Realstocks for providing and managing collateralized real estate loans, bonds and other financial instruments,
        including the Realstocks website at www.realstocks.io as well as any other service provided by Realstocks
        related to it (together “Services”). This Privacy Policy helps you understand what personal data and
        non-personal data We collect, and how such data is used and processed. We receive, collect and process your
        personal data when you as a data subject (“<b>you</b>”, “<b>data subject</b>”), use the Services.
      </p>
      <p>
        “<b>Personal Data</b>” refers to data which can identify a data subject directly or indirectly as defined in the
        European Union’s General Data Protection Regulation 2016/679 (“<b>GDPR</b>”). This is opposite to “non-personal
        data” meaning data that cannot be used to specifically identify any person. When processing your personal data,
        We comply with the GDPR and other applicable laws regulating the processing of personal data. Key terms used in
        this Privacy Policy, such as data subject, processing, controller and data subject’s rights shall have the
        meaning defined in the GDPR.
      </p>
      <p>
        We reserve the right to modify this Privacy Policy at any time, so please review it frequently. If We make
        significant changes to this Privacy Policy, We will post a notice in the Services along with the updated Privacy
        Policy or contact you by email (using the address you have provided in the Services).
      </p>
      <h3>LEGAL BASIS FOR PROCESSING</h3>
      <p>
        The legal basis for the processing of your Personal Data is, where not stated otherwise or another legal basis
        is otherwise applicable, the performance of the contract for providing Realstocks’ Services to you, as permitted
        by GDPR article 6(1)(b).
      </p>
      <p>
        Realstocks can also process your Personal Data where We have a legitimate interest to do so, as permitted by
        GDPR article 6(1)(f), such as for fraud prevention or marketing purposes. Where Realstocks relies on legitimate
        interests as a reason and legal basis for processing personal data, it has considered whether or not those
        interests are overridden by the rights and freedoms of the data subjects and has concluded that they are not.
      </p>
      <p>
        Where the processing is such that your consent is required by the applicable legislation, We will state so and
        obtain your consent, and this will be the legal basis for the processing of your Personal Data, as permitted by
        GDPR article 6(1)(a). However, you have the right to withdraw that consent at any time, without affecting the
        lawfulness of processing based on consent before its withdrawal. If such withdrawal means that We are no longer
        able to provide the Services to you, We may cease providing the Services.
      </p>

      <h3>TYPES OF PERSONAL DATA AND SOURCES</h3>
      <p>
        Personal Data collected and processed by Realstocks is mainly collected directly from the data subject.
        Realstocks will only collect such Personal Data that is relevant for the purposes described in this Privacy
        Policy, including information you give to us, and technically gathered information when you use Our Services.
      </p>
      <p>
        While the provisioning of certain Personal Data is necessary for the use of Our services, certain Personal Data
        is provided voluntarily. Personal Data may be updated and supplemented by collecting data from private and
        public sources, such as commercially available directories and websites.
      </p>
      <p>
        Personal Data and non-personal data is collected directly from you, such as through registration or by logging
        the activities in the Services.
      </p>
      <p>This data may include:</p>

      <div className="column is-12 has-table-header table-extended-no-header">
        <RsTableExtended
          columns={[
            textColumnPolicy('Category of personal data', 'category', { bold: true }),
            textColumnPolicy('Examples of information content', 'text'),
          ]}
          title=""
          data={personalDataTypes}
          searchByFields={[]}
          noDataMessage="No personal data"
          clickableRows
          noPagination
          conditionalRowStyles={[
            {
              when: () => true,
              style: {
                height: 'fit-content',
                padding: '16px 0',
              },
            },
          ]}
        />
      </div>

      <p>
        *The marked sections are necessary to provide the Services and fulfill legal obligations. Furthermore, the
        blocking or the non-provisioning of certain personal data may affect the proper functioning of the Services or
        Realstocks may not be able to provide the Services if said data is not provided.
      </p>
      <p>
        If you do not provide Realstocks the required information, this may mean that We are not able to provide the
        Services to you, perform the contract necessary for the provisioning of the Services or to comply with
        Realstocks’ legal obligations.
      </p>

      <p>
        In addition to Personal Data, Realstocks will collect non-personal data relating to you. This information
        includes either anonymous or anonymized data or data linked to Personal data that is generated during the use of
        the Services, such as actions in the Services and access times.
      </p>

      <p>
        Some features of the Services may enable you to permit Realstocks to make certain information, including your
        actions in the Services, available to other users of the Services (as specified in more detail in the Services).
      </p>

      <p>
        Realstocks retains the Personal Data collected pursuant to this Privacy Policy for the period necessary to
        fulfil the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by
        law. Thereafter, if the collected Personal Data is no longer needed for purposes specified in this Privacy
        Policy, Realstocks will delete all aforementioned Personal Data in its possession within a reasonable timeframe
        or render it anonymous, meaning “non-personal data”. Further, Realstocks will store the following categories of
        your Personal Data for the retention periods outlines below:
      </p>

      <div className="column is-12 has-table-header table-extended-no-header">
        <RsTableExtended
          columns={[
            textColumnPolicy('Category', 'category', { bold: true }),
            textColumnPolicy('Retention period', 'text'),
          ]}
          title=""
          data={retentionPeriodItems}
          searchByFields={[]}
          noDataMessage="No personal data"
          clickableRows
          noPagination
          conditionalRowStyles={[
            {
              when: () => true,
              style: {
                height: 'fit-content',
                padding: '16px 0',
              },
            },
          ]}
        />
      </div>

      <p>
        * This means that Realstocks keeps the personal data for as long as necessary for the performance of a contract
        and as required by retention requirements in laws and regulations. Where Realstocks keeps the personal data for
        other purposes than those of the performance of a contract, such as for anti-money laundering and bookkeeping,
        Realstocks keeps the data only if necessary and/or mandated by laws and regulations for the respective purpose.
        For example preventing, detecting and investigating money laundering, terrorist financing and fraud: minimum
        five years after termination of the business connection or the performance of the individual transaction.
      </p>

      <h3>HOW PERSONAL DATA IS USED</h3>

      <p>Your Personal Data may be used in order to:</p>

      <RsList checkmarked={false} items={personalDataUsageItems}></RsList>

      <p>
        Realstocks will use your Personal Data for targeted in-service marketing and other similar purposes, and upon
        your consent, to email marketing by Realstocks. You can opt-out of receiving promotional emails by following the
        instructions in those emails. If you opt-out, Realstocks can still send you non-promotional customer
        information, such as emails about your account, providing the Services or Our ongoing business relations.
      </p>
      <p>Realstocks does not use automatic decision-making.</p>

      <h3>THIRD PARTY ANALYTICS TOOLS AND DISCLOSING INFORMATION</h3>

      <p>
        For the purposes stated in this Privacy Policy, Personal Data may be disclosed to third parties, such as
        third-party service providers. In such a case, the Personal Data will only be disclosed for purposes set-out in
        this Privacy Policy. A list of the processors and other recipients can be provided upon request.
      </p>

      <p>
        In order to analyse and improve the Services, Realstocks uses third party service providers and tools provided
        by the third party service providers to collect and use non-personal data. Data Subjects can decline the
        collection and storage of such data from their devices’ settings.
      </p>

      <p>
        Realstocks will not disclose your stored Personal Data to third parties for direct marketing, market research,
        polls or public registers without your consent or except as part of a specific program or feature for which you
        will have the ability to opt-in or opt-out. Realstocks has the right to share non-personal data (such as
        anonymous usage data, platform types, asset views, number of clicks, etc.) with interested third-parties to
        assist them in understanding the usage patterns for certain content, services, advertisements, promotions,
        and/or functionality of the Services.
      </p>

      <p>
        Personal Data may also be transferred outside the European Union and the European Economic Area (“EU/EEA”) to
        Realstocks’ service providers. In case Personal Data is transferred outside EU/EEA, such transfers are either
        made to a country that is deemed to provide a sufficient level of privacy protection by the European Commission
        or transfers are carried out by using appropriate safeguards such as standard data protection clauses adopted or
        otherwise approved by the EU Commission or competent data protection authority in accordance with the GDPR. More
        information can be provided upon request.
      </p>

      <h3>SECURITY</h3>

      <p>
        Realstocks takes reasonable measures to protect Personal Data as the data is transferred from your devices to
        Realstocks and to protect the data from loss, misuse, and unauthorized access, disclosure, alteration, and
        destruction. However, you should always keep in mind that no Internet transmission is 100% secure or free from
        errors. For the avoidance of doubt, the safeguarding of all account registration information and credentials are
        your responsibility.
      </p>

      <p>
        The register will be maintained on external service providers’ servers with appropriate safeguards. The register
        is protected by appropriate industry standard, technical and organizational safety measures. Although Realstocks
        makes good faith efforts to store the data collected from the Services in a secure operating environment that is
        not available to the public, Realstocks cannot guarantee the absolute security of that information during its
        transmission or its storage on Our systems. Realstocks will post a notice in the Services in case material
        security breach that endangers your privacy or Personal Data, and if necessary, will contact affected Data
        Subjects directly. Realstocks may also temporarily shut down the Services to protect the Personal Data.
      </p>

      <h3>COOKIES AND OTHER TRACKING TECHNOLOGIES</h3>

      <p>
        A cookie is a string of information or a small text file that a website stores on a visitor’s device, and that
        the visitor’s browser provides to the website each time the visitor returns. Realstocks may utilize cookies to
        help it identify and track visitors, their usage of the Services’ website access preferences, improving quality,
        tailoring recommendations, and developing the Services. Data subjects who do not wish to have cookies placed on
        their computers can set their browsers to refuse cookies before using the Realstocks websites, with the drawback
        that certain features of the website may not function properly without the aid of cookies.
      </p>

      <p>
        If Realstocks will use other tracking technologies in addition to cookies, Realstocks shall notify you of use of
        such tracking technologies.
      </p>

      <p>
        You can set or amend your web browser controls to accept or reject cookies. If you choose to reject cookies, you
        may still use our websites and some services, however your access to some functionality and areas of our website
        or services may be restricted substantially.{' '}
      </p>
      <p>For more information, see our Cookie Policy.</p>

      <h3>RIGHTS OF DATA SUBJECTS AND CONTACT INFORMATION</h3>

      <div className="rights-of-data">
        <p>
          <b>Right to access</b>
        </p>
        <p>
          You may contact Realstocks and Realstocks will inform you what Personal Data Realstocks has stored regarding
          you, and the purposes such data are used for.{' '}
        </p>
        <p>
          <b>Right to rectification</b>
        </p>
        <p>
          You have the right to have Realstocks correct any incorrect, incomplete, outdated, or unnecessary Personal
          Data stored about you by contacting Realstocks.
        </p>
        <p>
          <b>Right to erasure</b>
        </p>
        <p>
          You may also ask Realstocks to delete your Personal Data from Our systems. Realstocks will comply with such
          request unless Realstocks has a legitimate ground to not delete the data. After the data has been deleted,
          Realstocks may not immediately be able to delete all residual copies from all of Our systems. Such copies
          shall be deleted as soon as reasonably possible.
        </p>
        <p>
          <b>Right to Object or Restrict Processing</b>
        </p>
        <p>
          You may object to certain use of Personal Data when such processing is based on legitimate interest, including
          direct marketing or profiling. You may opt-out of receiving promotional emails by following the instructions
          in those emails. If You opt-out, we may still send you non-promotional customer information, such as emails
          about your account, providing Our services and products or Realstocks’ ongoing relationship with You.
          <br />
          You may request Realstocks to restrict processing of certain Personal Data. Your Personal Data will then only
          be stored and not processed otherwise; this may however lead to fewer possibilities to use the Services. If
          such restriction means that We are no longer able to provide the Services to you, We shall be entitled to stop
          providing the Services.
        </p>
        <p>
          <b>Right to data portability</b>
        </p>
        <p>
          You have the right to receive Personal Data provided by you to Realstocks in a structured and commonly used
          format. Realstocks provides no guarantee that this information will be compatible, relevant or useful to any
          other service.
        </p>
        <p>
          <b>How to exercise your rights</b>
        </p>
        <p>
          These rights may be used by sending a letter or e-mail to Realstocks on the addresses set out below. Your
          identity will be verified before the information is given out, which is why We may have to ask for necessary
          additional details. We will respond to the request within a reasonable amount of time and, where possible,
          within one month of the request and the verification of identity. If your request cannot be met, the refusal
          shall be communicated to you in writing. Realstocks may reject requests that are unreasonably repetitive,
          excessive or manifestly unfounded.
        </p>
        <p>
          <b>Complaints</b>
        </p>
        <p>
          If you consider Our processing activities of Personal Data to be inconsistent with the applicable data
          protection laws, you have the right to lodge a complaint with a supervisory authority for data protection.
        </p>
      </div>

      <h3>Contact info:</h3>
      <p>
        <b>Deon Digital AG</b>
        <br />
        <br /> Address: Herostrasse 9, 8048 Zurich, Switzerland
        <br />
        Email: <a href="mailto:support@realstocks.io">support@realstocks.io</a> <br />
        www: <a href="https://www.realstocks.io">www.realstocks.io</a>
        Version: 2024.05.30
      </p>

      <div className="columns is-centered">
        <CloseBtn />
      </div>
    </div>
  );
}
