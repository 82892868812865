import { CompanyConfigurations, platformInteractionsInitialValues } from '@realstocks/types';
import { createSelector } from 'reselect';
import { AppState } from '../../store';
import { CompanyInformationType, CompanyModel } from '../../types/Company';

/**
 * Get the full company entity
 *
 * The reason these are split is because we are saving them independent on each other
 * - Company Information
 * - Representatives
 * - Ubo
 * - etc
 *
 * @param {Object} state
 */
export const getCompany = (state: AppState): CompanyModel => {
  if (!state.company.instance) throw new Error('Company must be set');

  return state.company.instance;
};
export const getCompanyOrNull = (state: AppState) => (state.company.instance ? state.company.instance : null);

/**
 * Fetch the company id only
 */
export const getCompanyId = createSelector(getCompanyOrNull, (companyState: CompanyModel | null) =>
  companyState && companyState.id ? companyState.id : null
);

/**
 * Fetch the company platform interactions data only
 */
export const getCompanyPlatformInteractionsData = createSelector(
  getCompanyOrNull,
  (companyState: CompanyModel | null) =>
    companyState && companyState.platformInteractions
      ? companyState.platformInteractions
      : {
          ...platformInteractionsInitialValues,
        }
);

/**
 * Fetch the company kyc status
 */
export const getCompanyKycStatus = createSelector(getCompanyOrNull, (companyState: CompanyModel | null) =>
  companyState && companyState.kycStatus ? companyState.kycStatus : null
);

/**
 * Get the company isComplete flag
 */
export const isCompanyCompleted = createSelector(getCompanyOrNull, (companyState: CompanyModel | null) =>
  companyState && (companyState.completed === true || companyState.completed === false) ? companyState.completed : false
);

/**
 * Fetch the company information only
 */
export const getCompanyInformation = createSelector(
  getCompanyOrNull,
  (companyState: CompanyModel | null): CompanyInformationType & { id: string } => {
    if (companyState && companyState.companyInformation) {
      return { ...companyState.companyInformation, id: companyState.id };
    } else {
      throw Error('Company should be set');
    }
  }
);

/**
 * Fetch the company configurations only
 */
export const getCompanyConfigurations = createSelector(
  getCompanyOrNull,
  (companyState: CompanyModel | null): CompanyConfigurations | undefined => {
    if (companyState && companyState.configurations) {
      return { ...companyState.configurations };
    }
  }
);

export const getCompanyInformationOrNull = createSelector(
  getCompanyOrNull,
  (companyState: CompanyModel | null): CompanyInformationType | null =>
    companyState && companyState.companyInformation ? companyState.companyInformation : null
);

/**
 * Fetch the company type
 */
export const getCompanyType = createSelector(
  getCompanyInformation,
  (companyInformation: CompanyInformationType) => companyInformation.type
);

/**
 * Fetch the company representatives only
 */
export const getCompanyRepresentatives = createSelector(getCompanyOrNull, (companyState: CompanyModel | null) =>
  companyState && companyState.representatives ? companyState.representatives : null
);

/**
 * Get if company exists or not
 */
export const getCompanyExists = createSelector(getCompanyOrNull, (company: CompanyModel | null) =>
  Boolean(company) === true ? true : null
);

/**
 * Get the isFetched status
 */
export const isCompanyFetched = (state: AppState) => {
  return state.company.fetched;
};

/**
 * Fetch the company documents only
 */
export const getCompanyDocuments = createSelector(getCompanyOrNull, (companyState: CompanyModel | null) =>
  companyState && companyState.documents ? companyState.documents : null
);

/**
 * Fetch company prefilled data
 */
export const getCompanyPrefilledData = createSelector(getCompanyOrNull, (companyState: CompanyModel | null) =>
  companyState && companyState.prefilledData ? companyState.prefilledData : null
);
