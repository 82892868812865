export const dateRangeDirections = { next: 'next', previous: 'previous' } as const;
export type DateRangeDirection = (typeof dateRangeDirections)[keyof typeof dateRangeDirections];

export const dateRangeTimeScales = { month: 'month', quarter: 'quarter', year: 'year' } as const;
export type DateRangeTimeScale = (typeof dateRangeTimeScales)[keyof typeof dateRangeTimeScales];

export interface RelativeDateRangeDto {
  readonly tag: 'relative';
  readonly direction: DateRangeDirection;
  readonly count: number;
  readonly timeScale: DateRangeTimeScale;
}
