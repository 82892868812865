import { ProcessEnv } from 'constants/ProcessEnv';
import { Route, Routes } from 'react-router-dom';
import ClientPath from '../../routes/ClientPath';
import { TermsEmbed } from './TermsEmbed';

export function TosRoutes() {
  return (
    <Routes>
      <Route
        path={ClientPath.static.tos}
        element={
          <TermsEmbed
            id={'lender-terms-of-service-object'}
            pdfUrl={`${ProcessEnv.aws.s3.buckets.media}/pages/tos/Realstocks-Lender-Terms-of-Service.pdf`}
          />
        }
      />
      <Route
        path={'lender'}
        element={
          <TermsEmbed
            id={'lender-terms-of-service-object'}
            pdfUrl={`${ProcessEnv.aws.s3.buckets.media}/pages/tos/Realstocks-Lender-Terms-of-Service.pdf`}
          />
        }
      />
      <Route
        path={'borrower'}
        element={
          <TermsEmbed
            id={'borrower-terms-of-service-object'}
            pdfUrl={`${ProcessEnv.aws.s3.buckets.media}/pages/tos/Realstocks-Borrower-Terms-of-Service.pdf`}
          />
        }
      />
    </Routes>
  );
}
