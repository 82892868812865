import type { History } from 'history';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

// Source: https://github.com/remix-run/react-router/issues/8139

/** @description Blocks all navigation attempts.
 *  @param when {boolean}  Whether to start intercepting navigation.
 *  @example
 *  const [flag, setFlag, next] = usePrompt(false);
 *  useEffect(() => {
 *     if (flag) {
 *  //     do something like show a dialog etc;
 *  //     at the right time resume bocked navigate
 *         next();
 *     }
 *   }, [flag]);

 */
export const usePrompt = (when = false) => {
  const [flag, setFlag] = useState<boolean>(false);
  const confirm = useRef<any>(null);
  const context = useRef<any>(null);
  const { navigator } = useContext(NavigationContext);
  const blockNavigator = navigator as History;

  const next = useCallback(() => {
    confirm.current();
    context.current?.retry?.();
  }, [flag]);

  useEffect(() => {
    if (!when) return;
    const unblock = blockNavigator.block((tx) => {
      setFlag(true);
      context.current = tx;
    });
    confirm.current = unblock;
    return unblock;
  }, [blockNavigator, when]);

  return [flag, setFlag, next] as const;
};
