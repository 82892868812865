import { DashboardDefinition } from './dashboard-definition.type';

export const dashboardVisibilities = {
  private: 'private',
  shared: 'shared',
  platformDefault: 'platform-default',
} as const;
export type DashboardVisibility = (typeof dashboardVisibilities)[keyof typeof dashboardVisibilities];

export interface DashboardPermissions {
  canEdit: boolean;
  canDelete: boolean;
}

export interface Dashboard {
  id: string;
  ownerId: string;
  ownerName?: string;
  companyId: string;
  visibility: DashboardVisibility;
  dashboardDefinition: DashboardDefinition;
  createdAt: Date;
  updatedAt: Date;
  favorite: boolean;
  permissions: DashboardPermissions;
}
