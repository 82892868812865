import { defineApi } from '../common/api-definition';
import { NextPastType } from '../common/NextPastType';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { LoanMetricType } from '../covenants/LoanMetricType';
import { SLReportingEventConfig, SLReportingEventConfigDraft } from '../covenants/ReportingEventConfigType';
import { CovenantCertificateStatus, SLCovenant } from '../covenants/ReportingEventDTO';
import { SLCovenantReportEventType } from './EventType';
import { EventViewRequest } from './EventViewRequest';
import { SLCovenantReportNextCommandType } from './NextCommandType';

export type SLReportingEventViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      reportingEventId: string;
      checkDate: string;
    }
  | EventViewRequest;

export type SLReportingEventView = {
  loanId: string;
  reportingEventId: string;
  checkDate: string;
  name: string;
  metrics: { [metricId: string]: LoanMetricType };
  nextPast: RENextPastType;
  reportingEvent: SLReportingEventConfigDraft | SLReportingEventConfig;
  currentCalculation?: { covenants: SLCovenant[] };
  certificateStatus: CovenantCertificateStatus;
  requiredFiles: { requiredFileId: string; file: RsUploadedFileType }[];
  additionalFiles: RsUploadedFileType[];
  allowedActions: {
    signComplianceCertificate: boolean;
  };
};

export type RENextPastType = NextPastType<SLCovenantReportNextCommandType, SLCovenantReportEventType>;

export const reportingEventViewApi = defineApi<{
  body: SLReportingEventViewRequest;
  response: SLReportingEventView;
}>({
  url: 'structured-loans/reporting-event-view',
  method: 'post',
});
