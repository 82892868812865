import { AggregationOfNumericalMetricInputDto } from './aggregation-of-numerical-metric-input.dto';
import { BarVisualization, LineVisualization, PieVisualization } from './dashboard-visualization.type';
import { DistributionOfBinnedMetricInputDto } from './distribution-of-binned-metric-input.dto';
import { DistributionOfCategoricalMetricInputDto } from './distribution-of-categorical-metric-input.dto';
import { TimeSeriesOfNumericalMetricInputDto } from './time-series-of-numerical-metric-input.dto';

export const widgetTypes = {
  kpi: 'kpi',
  categoryDist: 'distribution',
  binnedDist: 'binned-distribution',
  timeSeries: 'time-series',
} as const;

export type WidgetType = (typeof widgetTypes)[keyof typeof widgetTypes];

interface WidgetBase {
  id: string;
  title: string;
}

export type KpiWidgetDefinition = WidgetBase & {
  tag: (typeof widgetTypes)['kpi'];
  data: AggregationOfNumericalMetricInputDto;
};

export type CategoricalDistributionWidgetDefinition = WidgetBase & {
  tag: (typeof widgetTypes)['categoryDist'];
  visualization: PieVisualization | BarVisualization | LineVisualization;
  data: DistributionOfCategoricalMetricInputDto;
};

export type BinnedDistributionWidgetDefinition = WidgetBase & {
  tag: (typeof widgetTypes)['binnedDist'];
  visualization: PieVisualization | BarVisualization | LineVisualization;
  data: DistributionOfBinnedMetricInputDto;
};

export type TimeSeriesWidgetDefinition = WidgetBase & {
  tag: (typeof widgetTypes)['timeSeries'];
  visualization: BarVisualization | LineVisualization;
  data: TimeSeriesOfNumericalMetricInputDto;
};

export type WidgetDefinition =
  | KpiWidgetDefinition
  | CategoricalDistributionWidgetDefinition
  | BinnedDistributionWidgetDefinition
  | TimeSeriesWidgetDefinition;
