const timeSeriesTypes = {
  spanOfTime: 'span-of-time',
  pointInTime: 'point-in-time',
} as const;

type TimeSeriesType = (typeof timeSeriesTypes)[keyof typeof timeSeriesTypes];

export interface GenericMetric {
  name: string;
  isCategory?: boolean;
  canBin?: boolean;
  timeSeriesType?: TimeSeriesType;
  availableForTimeSeries?: boolean;
  availableForCurrent?: boolean;
  canSum?: boolean;
  canAverage?: boolean;
  canMedian?: boolean;
  requiresCurrency?: boolean;
}

export const metrics = {
  country: {
    name: 'country',
    availableForCurrent: true,
    isCategory: true,
  },
  currency: {
    name: 'currency',
    availableForCurrent: true,
    isCategory: true,
  },
  propertyType: {
    name: 'propertyType',
    availableForCurrent: true,
    isCategory: true,
  },
  borrowerName: {
    name: 'borrowerName',
    availableForCurrent: true,
    isCategory: true,
  },
  totalCommitment: {
    name: 'totalCommitment',
    canBin: true,
    availableForCurrent: true,
    canSum: true,
    canAverage: true,
    canMedian: true,
    requiresCurrency: true,
  },
  grossInterest: {
    name: 'grossInterest',
    availableForTimeSeries: true,
    timeSeriesType: timeSeriesTypes.spanOfTime,
    canSum: true,
    canAverage: true,
    canMedian: true,
    requiresCurrency: true,
  },
  outstandingDebt: {
    name: 'outstandingDebt',
    availableForCurrent: true,
    availableForTimeSeries: true,
    timeSeriesType: timeSeriesTypes.pointInTime,
    canSum: true,
    canAverage: true,
    canMedian: true,
    requiresCurrency: true,
  },
  principalDue: {
    name: 'principalDue',
    availableForTimeSeries: true,
    timeSeriesType: timeSeriesTypes.spanOfTime,
    canSum: true,
    canAverage: true,
    canMedian: true,
    requiresCurrency: true,
  },
} as const;

export type Metric = (typeof metrics)[keyof typeof metrics];

/**
 * Utility type to help with the next definitions
 */
type Test<T extends GenericMetric, U extends object> = T extends U ? T : never;

/**
 * The different types of metrics supported by the dashboard, as discriminated unions.
 */
export type CategoricalMetric = Test<
  (typeof metrics)[keyof typeof metrics],
  { isCategory: true; availableForCurrent: true }
>['name'];
export type BinnedMetric = Test<
  (typeof metrics)[keyof typeof metrics],
  { canBin: true; availableForCurrent: true }
>['name'];
export type CurrentSummableMetric = Test<
  (typeof metrics)[keyof typeof metrics],
  { canSum: true; availableForCurrent: true }
>['name'];
export type CurrentAverageableMetric = Test<
  (typeof metrics)[keyof typeof metrics],
  { canAverage: true; availableForCurrent: true }
>['name'];
export type CurrentMedianMetric = Test<
  (typeof metrics)[keyof typeof metrics],
  { canMedian: true; availableForCurrent: true }
>['name'];
export type TimeSeriesSummableMetric = Test<
  (typeof metrics)[keyof typeof metrics],
  { canSum: true; availableForTimeSeries: true }
>['name'];
export type TimeSeriesAverageableMetric = Test<
  (typeof metrics)[keyof typeof metrics],
  { canAverage: true; availableForTimeSeries: true }
>['name'];
export type TimeSeriesMedianMetric = Test<
  (typeof metrics)[keyof typeof metrics],
  { canMedian: true; availableForTimeSeries: true }
>['name'];

/**
 * Lists of the metrics supported by the dashboard.
 */
export const categoricalMetrics = Object.fromEntries(
  Object.values(metrics as Record<string, GenericMetric>)
    .filter(o => o.isCategory && o.availableForCurrent)
    .map(o => [o.name, o.name])
) as Record<CategoricalMetric, CategoricalMetric>;
export const binnedMetrics = Object.fromEntries(
  Object.values(metrics as Record<string, GenericMetric>)
    .filter(o => o.canBin && o.availableForCurrent)
    .map(o => [o.name, o.name])
) as Record<BinnedMetric, BinnedMetric>;
export const currentSummableMetrics = Object.fromEntries(
  Object.values(metrics as Record<string, GenericMetric>)
    .filter(o => o.canSum && o.availableForCurrent)
    .map(o => [o.name, o.name])
) as Record<CurrentSummableMetric, CurrentSummableMetric>;
export const currentAverageableMetrics = Object.fromEntries(
  Object.values(metrics as Record<string, GenericMetric>)
    .filter(o => o.canAverage && o.availableForCurrent)
    .map(o => [o.name, o.name])
) as Record<CurrentAverageableMetric, CurrentAverageableMetric>;
export const currentMedianMetrics = Object.fromEntries(
  Object.values(metrics as Record<string, GenericMetric>)
    .filter(o => o.canMedian && o.availableForCurrent)
    .map(o => [o.name, o.name])
) as Record<CurrentMedianMetric, CurrentMedianMetric>;
export const timeSeriesSummableMetrics = Object.fromEntries(
  Object.values(metrics as Record<string, GenericMetric>)
    .filter(o => o.canSum && o.availableForTimeSeries)
    .map(o => [o.name, o.name])
) as Record<TimeSeriesSummableMetric, TimeSeriesSummableMetric>;
export const timeSeriesAverageableMetrics = Object.fromEntries(
  Object.values(metrics as Record<string, GenericMetric>)
    .filter(o => o.canAverage && o.availableForTimeSeries)
    .map(o => [o.name, o.name])
) as Record<TimeSeriesAverageableMetric, TimeSeriesAverageableMetric>;
export const timeSeriesMedianMetrics = Object.fromEntries(
  Object.values(metrics as Record<string, GenericMetric>)
    .filter(o => o.canMedian && o.availableForTimeSeries)
    .map(o => [o.name, o.name])
) as Record<TimeSeriesMedianMetric, TimeSeriesMedianMetric>;
