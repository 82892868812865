import React from 'react';
import './RsSubmit.scss';

type Props = {
  label: string;
  disabled?: boolean | undefined;
  loading?: boolean;
  footerButtons?: React.ReactNode;
  secondaryButton?: React.ReactNode;
};

export default function RsSubmit(props: Props) {
  const SecondaryButton = props.secondaryButton;

  const PrimarySubmitButton = (
    <button
      className={`button is-primary is-submit ${props.loading ? 'is-loading' : ''}`}
      type="submit"
      disabled={!!props.disabled}
    >
      {props.label}
    </button>
  );
  return (
    <div className="rs-submit-btn-container">
      {props.secondaryButton ? (
        <div className="columns is-multiline is-centered">
          <div className="column is-6">{SecondaryButton}</div>
          <div className="column is-6">{PrimarySubmitButton}</div>
        </div>
      ) : (
        PrimarySubmitButton
      )}

      {!!props.footerButtons && props.footerButtons}
    </div>
  );
}
