import * as datefns from 'date-fns';
import { isEqual } from 'lodash';
import { DataPointValue } from '../covenants/DataPoint';
import { AnyPeriodType, NormalizedPeriodType } from '../covenants/Period';
import { SLDataPoint } from '../covenants/SLDataPoint';
import { InterestPeriodType } from '../loan/InterestPeriodType';
import { endOfDay, parseIsoDate, startOfDay, utc } from './date-utils';
import { formatDate } from './format-date';
import { dateToQuarter, stringifyQuarter } from './quarter';
import { matchString } from './strings';

function prettyNormalizedPeriod(nperiod: NormalizedPeriodType) {
  let { start, end } = nperiod;

  if (datefns.differenceInCalendarDays(end, start) <= 1) {
    return formatDate(start);
  }
  const daysInMonth = datefns.getDaysInMonth(new Date(end.getUTCFullYear(), end.getUTCMonth(), end.getDate()));

  if (start.getUTCDate() === 1 && end.getUTCDate() === daysInMonth) {
    const month = start.getUTCMonth();
    const year = start.getUTCFullYear();

    const monthDiff = datefns.differenceInCalendarMonths(datefns.addDays(end, 1), start);
    if (monthDiff === 1) return formatDate(utc(year, month, 1), 'MMM yyyy');
    if (monthDiff === 3) return stringifyQuarter(dateToQuarter(start));
    if (monthDiff === 6) return `H${Math.floor(month / 6) + 1} ${year}`;
    if (monthDiff === 12) return year.toString();
  }

  return `${formatDate(start)} to ${formatDate(end)}`;
}

export function prettyPeriod(period: AnyPeriodType) {
  const nperiod = normalizePeriod(period);
  return prettyNormalizedPeriod(nperiod);
}

export function averageValuePerDay(dp: SLDataPoint): { average: DataPointValue; days: number } {
  const days = getDaysInPeriod(dp);
  let average: DataPointValue = 0;
  switch (typeof dp.value) {
    case 'string':
      average = Number(dp.value) / days;
      break;
    case 'number':
      average = dp.value / days;
      break;
    case 'boolean':
      average = dp.value;
      break;
    default:
      throw new Error(`Unexpected datapoint value ${dp.value}`);
  }
  return { average, days };
}

export const normalizePeriod = (p: AnyPeriodType): NormalizedPeriodType => {
  const start = typeof p.start === 'string' ? parseIsoDate(p.start) : p.start;
  const end = typeof p.end === 'string' ? parseIsoDate(p.end) : p.end instanceof Date ? p.end : endOfDay(start);
  return { start, end };
};

export const getOverlappingDaysInPeriods = (period1: AnyPeriodType, period2: AnyPeriodType) => {
  const nperiod1 = normalizePeriod(period1);
  const nperiod2 = normalizePeriod(period2);
  if (isEqual(nperiod1, nperiod2)) {
    return getDaysInPeriod(period1);
  }

  // datefns uses end-exclusive interals, so add an extra day to end of periods
  nperiod1.end = datefns.addDays(nperiod1.end, 1);
  nperiod2.end = datefns.addDays(nperiod2.end, 1);

  try {
    return datefns.getOverlappingDaysInIntervals(nperiod1, nperiod2);
  } catch (e) {
    throw new Error(
      `Invalid periods for overlapping: ${JSON.stringify(nperiod1)} vs ${JSON.stringify(nperiod2)}. ${e}`
    );
  }
};

export function getDaysInPeriod(period: AnyPeriodType) {
  const { start, end } = normalizePeriod(period);
  return datefns.differenceInCalendarDays(end, start) + 1;
}

/**
 * Get the period of span `interestPeriod` that ends on `date`
 * @param date
 * @param interestPeriod
 * @returns
 */
export function getPeriodUntil(date: Date, interestPeriod: InterestPeriodType): NormalizedPeriodType {
  const nperiod = matchString(interestPeriod, {
    monthly: () => ({ start: startOfDay(datefns.subMonths(date, 1)), end: date }),
    quarterly: () => ({
      start: startOfDay(datefns.subMonths(date, 3)),
      end: date,
    }),
    'semi-yearly': () => ({ start: startOfDay(datefns.subMonths(date, 6)), end: date }),
    yearly: () => ({ start: startOfDay(datefns.subYears(date, 1)), end: date }),
  });
  if (datefns.isLastDayOfMonth(date)) {
    nperiod.start = lastUTCDayOfMonth(nperiod.start);
  }
  return nperiod;
}

function lastUTCDayOfMonth(date: Date) {
  return utc(date.getUTCFullYear(), date.getUTCMonth(), datefns.getDaysInMonth(date));
}
