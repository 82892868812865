import { RsCognitoUser } from '@realstocks/types';
import { clearUserData } from 'layout/rs-main-navigation/components/clear-user-data';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { connect, useDispatch } from 'react-redux';
import { useSWRConfig } from 'swr';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';
import { IDLE_TIME } from '../../constants/ProcessEnv';
import { setLoading } from '../../redux/redux-app/app-actions';
import { getAppInitialized } from '../../redux/redux-app/app-selectors';
import { getCurrentUserOrNull } from '../../redux/redux-auth/auth-selectors';
import { writeInactiveTimeCookie } from '../../services/api/RsApi';
import { signOut } from '../../services/utils/sign-out';
import { AppState } from '../../store';
import './InactivityManager.scss';
import useCountdown from './useCountdown';

// const showTimer = process.env.REACT_APP_DOMAIN !== 'https://realstocks.io';
const showTimer = false;

export const getInactiveTimeAndNow = () => {
  const [inactiveTimeKeyValue] = Object.entries(localStorage).filter(
    (keyPairArray: string[]) => keyPairArray[0] === LocalStorageKeys.inactiveTime
  );
  const inactiveTime = inactiveTimeKeyValue && inactiveTimeKeyValue[1] ? Number(inactiveTimeKeyValue[1]) : null;
  const now = Number(new Date().valueOf());

  return { inactiveTime, now };
};

const InactivityManager = React.memo((props: ReduxStateProps) => {
  const { currentUser, appInitialized } = props;

  const [idleTimerID, setIdleTimerID] = useState<number | null>(null);
  const idleTimerIDRef = React.useRef(idleTimerID);
  const workerRef = React.useRef<any>(null);
  const dispatch = useDispatch();
  const [timer, setTimer] = useState<number | null>(null);
  const { cache } = useSWRConfig();

  const handleOnAction = (_event: any) => {
    if (currentUser) {
      writeInactiveTimeCookie();
    }
  };

  const idleTimePassedCallback = async () => {
    const { inactiveTime, now } = getInactiveTimeAndNow();
    if ((!inactiveTime || now >= inactiveTime) && currentUser) {
      dispatch(setLoading('Signing you out...'));
      await signOut();
      dispatch(setLoading(false));
      clearUserData({ swrCache: cache });
      window.location.href = '/'; // reload makes sure everything is refreshed.
    }
  };

  useIdleTimer({
    timeout: IDLE_TIME,
    onAction: handleOnAction,
    events: ['keydown', 'wheel', 'mousewheel', 'mousedown', 'touchstart', 'MSPointerDown'],
  });

  useEffect(() => {
    /**
     * Listen for current logged in user ID in localstorage where amplify saves it automatically
     * and refresh the page when the user changes.
     *
     * The use case is when the user has multiple browser tabs opened at the same time and logs out
     * on one tab and / or logins into another account - all tabs should be refreshed
     *
     * @param e
     */

    const { inactiveTime: temp } = getInactiveTimeAndNow();

    setTimer(temp);

    window.onstorage = onStorageCallback;
    window.onfocus = () => idleTimePassedCallback();
    window.addEventListener('visibilitychange', idleTimePassedCallback);
    return () => {
      window.removeEventListener('visibilitychange', idleTimePassedCallback);
    };
  }, []);

  useEffect(() => {
    if (appInitialized) {
      if (!props.currentUser && idleTimerID) {
        window.clearTimeout(idleTimerID);
        setIdleTimerID(null);
      }
      if (props.currentUser) {
        if (!workerRef.current) {
          workerRef.current = new Worker(`${process.env.PUBLIC_URL}/workers/sleep-worker.js`);

          workerRef.current.onmessage = (ev: any) => {
            if (ev && ev.data === 'wakeup') {
              idleTimePassedCallback();
            }
          };
        }

        resetTimer();
      } else {
        if (workerRef.current) {
          workerRef.current.terminate();
          workerRef.current = null;
        }
      }

      window.addEventListener('LocalStorageInactiveTime', resetTimer);
      return () => {
        if (idleTimerID) {
          window.clearTimeout(idleTimerID);
          setIdleTimerID(null);
        }
        window.removeEventListener('LocalStorageInactiveTime', resetTimer);
      };
    }
  }, [appInitialized, currentUser]);

  const [days, hours, minutes, seconds] = useCountdown(timer);

  const onStorageCallback = async (e: StorageEvent) => {
    if (e.key === LocalStorageKeys.cognitoSession) {
      if (e.oldValue !== e.newValue) {
        if (idleTimerID) {
          window.clearTimeout(idleTimerID);
        }
        window.location.reload();
      }
    }
  };

  const resetTimer = () => {
    const oldIdleTimerID = idleTimerIDRef.current;

    if (typeof oldIdleTimerID === 'number') {
      window.clearTimeout(oldIdleTimerID);
    }

    if (props.currentUser) {
      // window.setTimeout returns an Id that can be used to start and stop a timer
      const newIdleTimerID = window.setTimeout(idleTimePassedCallback, IDLE_TIME);
      setIdleTimerID(newIdleTimerID);
      idleTimerIDRef.current = newIdleTimerID;

      const { inactiveTime: temp } = getInactiveTimeAndNow();
      setTimer(temp);
    }
  };

  if (showTimer) {
    const expired = days + hours + minutes + seconds <= 0;

    return (
      <div className="inactivity-timer">
        {expired && <p>Expired</p>}
        {!expired && (
          <p>
            {minutes} : {seconds}
          </p>
        )}
      </div>
    );
  }

  return null;
});

InactivityManager.displayName = 'InactivityManager';

type ReduxStateProps = {
  currentUser: RsCognitoUser | null;
  appInitialized: boolean;
};

const mapStateToProps = (state: AppState): ReduxStateProps => {
  return {
    currentUser: getCurrentUserOrNull(state),
    appInitialized: getAppInitialized(state),
  };
};

export default connect(mapStateToProps, {})(InactivityManager);
