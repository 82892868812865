import { useEffect } from 'react';
import { useSetLoading } from '../../effects/useSetLoading';

const LoadingPage = () => {
  const setLoading = useSetLoading();
  useEffect(() => setLoading('Loading...'), []);
  return <div></div>;
};

export default LoadingPage;
