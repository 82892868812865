import RsList from '../../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../../components/rs-elements/rs-list/RsListItem';
import { getStaticMedia } from '../../../../../constants/Aws';

export default function NegotiationServiceOfferings() {
  const items: RsListItem[] = [
    new RsListItem('Review every change in a tamperproof and timestamped history'),
    new RsListItem('Secure peer-to-peer chat functionality available throughout all modules on the platform'),
    new RsListItem('Comment, reply, resolve on any change on the platform and in the exported document(s)'),
    new RsListItem('Find all loan related documents in one repository available to all accepted parties'),
  ];
  return (
    <div className="section is-grey">
      <h4 className="has-text-centered headline-underlined">
        FULL HISTORY, REALTIME COMMUNICATION & CENTRAL DOCUMENT REPOSITORY
      </h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-6">
            <div className="img-container">
              <img src={getStaticMedia('/images/static-pages/negotiation_3.png')} />
            </div>
          </div>
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
        </div>
      </div>
    </div>
  );
}
