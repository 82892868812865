import { ProcessEnv } from './ProcessEnv';

export const AWS_CHALLENGES = {
  newPasswordRequired: 'NEW_PASSWORD_REQUIRED',
  forceChangePassword: 'FORCE_CHANGE_PASSWORD',
  unAuthorizedException: 'NotAuthorizedException',
};

/**
 *
 * @param path Absolute path starting with /
 */
export function getStaticMedia(path: string) {
  return `${ProcessEnv.aws.s3.buckets.media}${path}`;
}

export const AuthenticationTypes = {
  custom: 'CUSTOM_AUTH',
  regular: 'USER_SRP_AUTH',
};
