import { getStaticMedia } from '../../../../../constants/Aws';
import {
  HomepageServiceBlock,
  HomepageServiceSquareComponent,
} from '../../../../home/components/home-page-services/HomepageServiceBlock';

export default function NegotiationServiceHeader() {
  return (
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6">
            <div className="container-center">
              <HomepageServiceBlock
                service={
                  new HomepageServiceSquareComponent(
                    'Negotiation',
                    getStaticMedia('/icons/static-pages/negotiation.svg'),
                    '',
                    '',
                    true
                  )
                }
              />
            </div>
          </div>
          <div className="column is-6">
            <h4 className="has-text-centered headline-underlined">DIGITALISED LOAN AGREEMENT PROCESS</h4>
            <p className="has-text-weight-bold has-text-centered">
              Configure and negotiate complex commercial real estate loans in a fully digital and easy to use process.
            </p>
            <p className="has-text-weight-bold has-text-centered">
              Have full access to loan documents, the change history and your negotiation status in one place.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
