import RegisterNowComponent from '../../../../components/register-now/RegisterNowComponent';
import AdministrationServiceDocuments from './components/AdministrationServiceDocuments';
import AdministrationServiceHeader from './components/AdministrationServiceHeader';
import AdministrationServiceImport from './components/AdministrationServiceImport';
import AdministrationServiceLifecycle from './components/AdministrationServiceLifecycle';
import './RealstocksServiceAdministration.scss';

export function RealstocksServiceAdministration() {
  return (
    <div className="realstocks-service-administration">
      <AdministrationServiceHeader />
      <AdministrationServiceLifecycle />
      <AdministrationServiceDocuments />
      <AdministrationServiceImport />
      <RegisterNowComponent />
    </div>
  );
}
