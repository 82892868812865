import RsList from '../../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../../components/rs-elements/rs-list/RsListItem';
import { getStaticMedia } from '../../../../../constants/Aws';

export default function AdministrationServiceLifecycle() {
  const items: RsListItem[] = [
    new RsListItem('All running loans of your company in one administration panel'),
    new RsListItem('Overview of all upcoming lifecycle events'),
    new RsListItem('Calculation and payment details & status for each payment'),
    new RsListItem('Review the next scheduled interest & principal payment'),
  ];
  return (
    <div className="section is-grey">
      <h4 className="has-text-centered headline-underlined">
        CENTRAL ADMINISTRATION PANEL TO MANAGE THE LIFECYCLE OF YOUR LOAN PORTFOLIO
      </h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-6">
            <div className="img-container">
              <img src={getStaticMedia('/images/static-pages/administration_1.png')} />
            </div>
          </div>
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
        </div>
      </div>
    </div>
  );
}
