import Amplify from '@aws-amplify/core';
import { ProcessEnv } from '../../constants/ProcessEnv';

export async function initializeAmplify() {
  Amplify.configure({
    Auth: {
      mandatorySignIn: ProcessEnv.aws.userPool.mandatorySignIn,
      region: ProcessEnv.aws.region,
      userPoolId: ProcessEnv.aws.userPool.id,
      identityPoolId: ProcessEnv.aws.identityPool.id,
      userPoolWebClientId: ProcessEnv.aws.userPool.webClient,
    },
    Storage: {
      AWSS3: {
        region: ProcessEnv.aws.region,
      },
    },
    API: {
      endpoints: [
        {
          name: ProcessEnv.aws.api.name,
          endpoint: ProcessEnv.aws.api.base,
          region: ProcessEnv.aws.region,
        },
      ],
    },
  });
}
