import { useEffect } from 'react';
import * as notFoundAnimation from '../../assets/lottie/not-found-animation.json';
import LottieComponent from '../../components/lottie/LottieComponent';
import { useSetLoading } from '../../effects/useSetLoading';
import './NotFoundPage.scss';

export default function NotFoundPage() {
  const setLoading = useSetLoading();
  useEffect(() => setLoading(false));

  return (
    <div className="not-found-page">
      <LottieComponent options={{ loop: true }} animation={notFoundAnimation} />{' '}
      <h2 className="has-text-centered">Sorry, we couldn&apos;t find the page you are looking for.</h2>{' '}
    </div>
  );
}
