import { Formik, FormikHelpers } from 'formik';
import { findIndex } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import RsInput from '../../../../../components/rs-form/rs-input/RsInput';
import RsForm from '../../../../../components/rs-form/RsForm';
import { EidBankCodes } from '../../../../../constants/BankEid';
import { ProcessEnv } from '../../../../../constants/ProcessEnv';
import { RsApiEndpoints } from '../../../../../constants/RsApiEndpoints';
import { useSetLoading } from '../../../../../effects/useSetLoading';
import ClientPath from '../../../../../routes/ClientPath';
import { RsApi } from '../../../../../services/api/RsApi';
import LoginFooter from '../../../components/login-footer/LoginFooter';
import './BankEidLoginForm.scss';

function BankEidLoginForm(props: { submitCallback: Function }) {
  const navigate = useNavigate();
  const setLoading = useSetLoading();
  const [prefilledEmail] = useState(new URLSearchParams(window.location.search).get('email'));

  const [validationSchema] = useState<Yup.ObjectSchemaDefinition<{ email: string }>>({
    email: Yup.string().email('Invalid email address.').required('Email is required.'),
  });
  const [formModel, setFormModel] = useState<{ email: string }>({ email: '' });

  useEffect(() => {
    if (prefilledEmail) {
      setFormModel({ ...formModel, email: decodeURIComponent(prefilledEmail) });
    }
  }, [prefilledEmail]);

  const submit = async (values: { email: string }, actions: FormikHelpers<{ email: string }>) => {
    try {
      setLoading('Loading...');
      actions.setErrors({});
      const bankResponse = await RsApi.get({
        path: RsApiEndpoints.user.getBankByUserEmail,
        config: {
          queryStringParameters: {
            email: values.email,
            userPoolId: ProcessEnv.aws.userPool.id,
          },
        },
      });

      const bank = bankResponse.message;

      const isValidBank = findIndex(EidBankCodes, { code: bank }) >= 0;

      if (isValidBank) {
        props.submitCallback(actions, bank, values.email);
      } else {
        setLoading(false);
        toast.error('Something went wrong, please try again.');
      }
    } catch (err) {
      setLoading(false);
      const error: any = err;
      if (error.status === 400) {
        navigate(`${ClientPath.auth.login}?email=${encodeURIComponent(values.email)}`);
      }
      toast.error(error.data && error.data.message ? error.data.message : 'Something went wrong');
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={Yup.object(validationSchema)}
      initialValues={formModel}
      onSubmit={submit}
    >
      {(actions) => {
        return (
          <div className="columns is-multiline login-with-bankeid-form">
            <div className="column is-12">
              <RsForm
                status={actions.status}
                submitLabel="Login with Bank eID"
                className="columns is-multiline is-centered create-company-form rs-form-contained"
              >
                <RsInput type="email" label="Email" icon="far fa-envelope" name="email" />
              </RsForm>

              <p className="has-text-centered">
                Your Bank eID is not added to your account yet ? <br />
                <button
                  className="button is-text"
                  onClick={() =>
                    navigate(
                      formModel.email !== ''
                        ? `${ClientPath.auth.login}?email=${encodeURIComponent(formModel.email)}`
                        : ClientPath.auth.login
                    )
                  }
                >
                  Login with Username & Password.
                </button>
              </p>
            </div>

            <div className="column is-12">
              <LoginFooter email={formModel.email} />
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default BankEidLoginForm;
