import { AuthState } from '../../store';
import { ReduxAction } from '../redux';
import { AUTH_ACTIONS } from './auth-actions';

/**
 * Error Reducer takes care of the global error messages inside the application.
 *
 * @param {*} state
 * @param {*} action
 */
export const AuthReducer = (
  state: AuthState = {
    isLoggedIn: null,
    currentUser: null,
    companies: null,
  },
  action: ReduxAction
) => {
  switch (action.type) {
    case AUTH_ACTIONS.setLoggedIn:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case AUTH_ACTIONS.setCurrentUser:
      return {
        ...state,
        currentUser: action.payload,
        isLoggedIn: Boolean(action.payload),
      };
    case AUTH_ACTIONS.setUserCompaniesList:
      return {
        ...state,
        companies: action.payload,
      };
    default:
      return state;
  }
};
