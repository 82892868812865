import { IssueLevelType } from '@realstocks/types';
import { Form, FormikContextType, FormikProps, useFormikContext } from 'formik';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { usePrompt } from '../../effects/usePrompt';
import RsFormError from './rs-form-error/RsFormError';
import RsSubmit from './rs-submit/RsSubmit';
import './RsForm.scss';
import { createErrorStatus } from './utils/create-error-status';

type Props = {
  /** @deprecated */
  actions?: FormikProps<any>;
  /** @deprecated */
  status?: any;
  hideFormFieldError?: boolean;
  readOnly?: boolean;
  overwriteSubmit?: JSX.Element | boolean;
  footerButtons?: React.ReactNode;
  secondaryButton?: React.ReactNode;
  isSubmitting?: boolean;
  className?: string;
  disabled?: boolean;
  noStyle?: boolean;
  submitLabel?: string;
  id?: string;
  ignoreRequireTouched?: boolean;
  disabledOnError?: boolean;
  mode?: 'view' | 'edit';
  showValidatingText?: boolean;
  setValues?: (values: Record<string, any>) => void;
  prompt?: boolean;
  getFieldIssues?: (fieldName: string) => FieldIssuesType | undefined;
  loading?: boolean;
  customSubmit?: boolean;
};

export type FieldIssuesType = {
  // The error level. Defaults to 'error'.
  level?: IssueLevelType;
  messages: React.ReactNode[];
};

export const rsFieldDisabledClass = 'rs-field-disabled';

export const RsFormContext = React.createContext<{
  loading: boolean;
  ignoreRequireTouched: boolean;
  showValidatingText: boolean;
  mode: 'view' | 'edit';
  disabledForm: boolean;
  mutateFormLoading: (loading: boolean) => void;
  getFieldIssues?: (fieldName: string) => FieldIssuesType | undefined;
}>({
  showValidatingText: true,
  ignoreRequireTouched: false,
  mode: 'edit',
  loading: false,
  disabledForm: false,
  mutateFormLoading: () => {},
});

/**
 * Form wrapper with defined styling per component
 *
 * @param props
 */
// TODO Refactor this to include Formik inside and add formik props through this.
// Repetition is avoided and you can have full control over Formik rendering throughout the app
export default function RsForm(props: PropsWithChildren<Props>) {
  const [rsFormLoading, setRsFormLoading] = useState<boolean>(true);
  const actions: FormikContextType<any> = useFormikContext<any>();
  const {
    mode = 'edit',
    ignoreRequireTouched = false,
    showValidatingText = true,
    setValues,
    disabled = false,
    disabledOnError = false,
    prompt = false,
    getFieldIssues,
    loading: inducedLoading,
  } = props;
  const { status, values, isSubmitting } = actions;

  const [flag, setFlag, next] = usePrompt(actions.dirty && prompt);

  useEffect(() => {
    if (inducedLoading === false || inducedLoading === true) {
      setRsFormLoading(inducedLoading);
    } else setRsFormLoading(false);
  }, [inducedLoading]);

  useEffect(() => {
    if (flag) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave this page?')) {
        next();
      } else {
        setFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag]);

  const [firstError, setfirstError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (setValues) setValues(values);
  }, [values, setValues]);

  useEffect(() => {
    let temp: any = null;

    if (actions?.errors && Object.keys(actions.errors).length > 0) {
      for (const fieldName of Object.keys(actions.errors)) {
        if (actions.getFieldMeta(fieldName).touched || ignoreRequireTouched) {
          temp = actions.errors[fieldName];
          break;
        }
      }
    }

    // some errors have keys (for example if a field name is a.x.z then the error for that field is under a.x.z)
    // traverse the object deep and get the error message only.
    let objectTraversDone = false;

    while (!objectTraversDone) {
      if (temp !== null && typeof temp === 'object') {
        const firstKey: string = Object.keys(temp)[0];
        temp = temp[firstKey];
      } else {
        objectTraversDone = true;
      }
    }

    setfirstError(temp);
  }, [actions.errors, firstError, setfirstError]);

  return (
    <RsFormContext.Provider
      value={{
        loading: rsFormLoading,
        mutateFormLoading: setRsFormLoading,
        mode,
        ignoreRequireTouched,
        disabledForm: disabled,
        showValidatingText,
        getFieldIssues,
      }}
    >
      <Form noValidate className={`mode-${mode} ${props.noStyle ? '' : 'rs-form'} ${props.className || ''}`}>
        {props.children}
        {status && <RsFormError response={status.serverError} />}
        {firstError && !props.hideFormFieldError && !status && (
          <RsFormError response={createErrorStatus({ message: firstError }).serverError} />
        )}

        {!props.customSubmit && !props.overwriteSubmit && !props.readOnly && mode === 'edit' && (
          <RsSubmit
            disabled={disabled || (disabledOnError && Object.keys(actions.errors).length > 0)}
            loading={rsFormLoading || props.isSubmitting || isSubmitting}
            secondaryButton={props.secondaryButton}
            label={props.submitLabel || 'Save'}
            footerButtons={props.footerButtons}
          />
        )}

        {mode === 'edit' && props.overwriteSubmit ? props.overwriteSubmit : ''}

        <div className="clear-both" />
      </Form>
    </RsFormContext.Provider>
  );
}
