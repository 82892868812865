type Props = {
  type: 'outstanding' | 'all';
};

const EmptyState = ({ type }: Props) => {
  return (
    <div className="empty-notifications">
      {type === 'outstanding' ? 'No outstanding notifications' : 'No notifications'}
    </div>
  );
};

export default EmptyState;
