import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import { RsApiEndpoints } from '../../constants/RsApiEndpoints';
import { RsError } from '../../constants/ServerError';
import { RsApi } from './RsApi';
import { RsApiConfig } from './RsApiConfig';
import { RsApiParams } from './RsApiParams';

interface BankEidTransactionResponse {
  message: {
    accessUrl: string;
    id: string;
  };
}

interface BankEidLoginResponse {
  message: {
    email: string;
    challenge: string;
  };
}

export class BankEidService {
  private static instance: BankEidService;

  private constructor() {}

  public static getInstance(): BankEidService {
    if (!BankEidService.instance) {
      BankEidService.instance = new BankEidService();
    }

    return BankEidService.instance;
  }

  public static async createTransaction(
    method: string,
    provider: string,
    redirectUrl: string,
    headers?: any
  ): Promise<BankEidTransactionResponse> {
    return RsApi.post(
      new RsApiParams(
        RsApiEndpoints.user.createEidTransaction,
        new RsApiConfig()
          .setBody({
            method: method,
            provider: provider,
            redirectUrl: redirectUrl,
          })
          .setHeaders(headers)
      )
    );
  }

  public static async login(transactionId: string | null, email: string): Promise<BankEidLoginResponse> {
    if (isNull(transactionId) || !email || email === '') {
      toast.error('Something went wrong logging you in with your Bank eID...');
      throw new RsError('Something went wrong logging you in with your Bank eID...');
    }

    return RsApi.post(
      new RsApiParams(
        RsApiEndpoints.user.loginWithBankeid,
        new RsApiConfig().setBody({
          transactionId: transactionId,
          email: email,
        })
      )
    );
  }
}
