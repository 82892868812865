import React, { ComponentType, Fragment, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getIsLoggedIn } from '../../redux/redux-auth/auth-selectors';
import ClientPath from '../../routes/ClientPath';
import { AppState } from '../../store';
import { withNavigation } from '../navigation-hoc/withNavigation';

type Props = {
  history: any;
  isLoggedIn: boolean | null;
};

const mapStateToProps = (state: AppState) => {
  return {
    isLoggedIn: getIsLoggedIn(state),
  };
};

/**
 *
 * HOC auth logged out required function which renders the Page component ONLY
 * if the user is logged out
 *
 * i.e. only logged out users should be able to access Login page.
 *
 * @param WrappedComponent
 */
export default function onlyUnauthenticated(WrappedComponent: ComponentType<any> | FunctionComponent<any>) {
  return withNavigation(
    connect(mapStateToProps)(
      class extends React.Component<Props> {
        render() {
          return (
            <Fragment>
              {this.props.isLoggedIn === true && <Navigate to={ClientPath.home} replace />}
              {this.props.isLoggedIn === false && <WrappedComponent {...this.props} />}
            </Fragment>
          );
        }
      }
    )
  );
}
