import { connect } from 'react-redux';
import { getStaticMedia } from '../../../../constants/Aws';
import { getCurrentUserOrNull } from '../../../../redux/redux-auth/auth-selectors';
import { AppState } from '../../../../store';
import './AboutUsPageHeader.scss';

function AboutUsPageHeader() {
  return (
    <div
      className="about-us-banner-component"
      style={{
        backgroundImage: `url(${getStaticMedia('/pages/about-us/about-us-header.png')}`,
      }}
    >
      <div className="container">
        <div className="columns">
          <div className="column is-half-desktop is-full left-side-container-column">
            <div className="left-side-container">
              <h1>Our Company</h1>
              <p>
                We will change the way European CRE loans are conducted by automating and digitalizing the full loan
                negotiation process together with all lifecycle events. This will benefit both professional borrowers
                and lenders through time & cost savings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state: AppState) => {
  return {
    currentUser: getCurrentUserOrNull(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUsPageHeader);
