import React from 'react';
import Xarrow from 'react-xarrows';
import { getStaticMedia } from '../../../../constants/Aws';
import { Breakpoints } from '../../../../constants/Generic';
import ClientPath from '../../../../routes/ClientPath';
import { HomepageServiceBlock, HomepageServiceSquareComponent } from './HomepageServiceBlock';
import './HomePageServices.scss';

export default function HomePageServices() {
  const [screen, setScreen] = React.useState<'desktop' | 'bigger' | 'mobile' | null>(null);

  React.useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [window.innerWidth]);

  const handleResizeWindow = () => {
    const w = window.innerWidth;

    setScreen(
      w < Breakpoints.desktop ? 'mobile' : w >= Breakpoints.desktop && w < Breakpoints.widescreen ? 'desktop' : 'bigger'
    );
  };

  const services = [
    new HomepageServiceSquareComponent(
      'Negotiation',
      getStaticMedia('/icons/static-pages/negotiation.svg'),
      (
        <p className="has-text-centered">
          Digitalised loan
          <br />
          agreement process
        </p>
      ),
      ClientPath.static.realstocksServices.negotiation
    ),
    new HomepageServiceSquareComponent(
      'Administration',
      getStaticMedia('/icons/static-pages/administration.svg'),
      (
        <p className="has-text-centered">
          Automated loan
          <br /> lifecycle events
        </p>
      ),
      ClientPath.static.realstocksServices.administration
    ),
    new HomepageServiceSquareComponent(
      'Transfers',
      getStaticMedia('/icons/static-pages/transfers.svg'),
      (
        <p className="has-text-centered">
          Loan transfers
          <br />& syndication
        </p>
      ),
      ClientPath.static.realstocksServices.transfers
    ),
  ];

  return (
    <div className="home-page-services">
      <div className="container">
        <h4 className="has-text-centered headline-underlined">
          END-TO-END SOLUTION FOR COMMERCIAL REAL ESTATE LOANS
          <br />
          AI SUPPORTED LOAN IMPORT AND DATA EXTRACTION
        </h4>
        <div className="services-container">
          {services.map((s, idx) => (
            <HomepageServiceBlock key={s.title} id={`service-${idx}`} service={s} />
          ))}

          {services.map((s, idx) => {
            if (idx < services.length - 1) {
              return (
                <Xarrow
                  key={`service-arrow-${idx}-${idx + 1}`}
                  startAnchor={screen === 'mobile' ? 'bottom' : screen === 'desktop' && idx % 2 > 0 ? 'left' : 'right'}
                  endAnchor={screen === 'mobile' ? 'top' : screen === 'desktop' && idx % 2 > 0 ? 'right' : 'left'}
                  color={'#aa2227'}
                  path="smooth"
                  strokeWidth={2}
                  dashness={true}
                  start={`service-${idx}`}
                  end={`service-${idx + 1}`}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}
