import React, { CSSProperties, HTMLAttributes, PropsWithChildren } from 'react';

export type RsButtonPropsType = HTMLAttributes<HTMLButtonElement> & {
  type?: 'primary' | 'default' | 'success' | 'danger';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  inverted?: boolean;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  minWidth?: string;
  submit?: boolean;
  loading?: boolean;
  hidden?: boolean;
  title?: string;
};

export default function RsButton(props: PropsWithChildren<RsButtonPropsType>) {
  const {
    disabled,
    title,
    loading = false,
    submit,
    onClick,
    className,
    type,
    inverted,
    children,
    fullWidth,
    hidden,
    minWidth,
    ...buttonProps
  } = props;
  const otherStyling: CSSProperties = {};

  if (minWidth) otherStyling.minWidth = minWidth;

  return (
    <button
      title={title || ''}
      type={submit ? 'submit' : 'button'}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
        event.currentTarget.blur();
      }}
      disabled={disabled || loading}
      style={{ visibility: `${hidden ? 'hidden' : 'initial'}`, ...otherStyling }}
      className={`button ${type ? 'is-' + type : ''} ${inverted ? 'is-inverted' : ''} ${
        fullWidth ? 'is-full-width' : ''
      } ${className || ''} ${loading ? 'is-loading' : ''}`}
      {...buttonProps}
    >
      {children}
    </button>
  );
}
