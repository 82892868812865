import { connect } from 'react-redux';
import { getNewNotificationsWithActions } from '../../redux/redux-notifications/notifications-selectors';
import { AppState } from '../../store';
import { TNotification } from '../../types/notifications';

type Props = {
  newNotificationsWithActions: TNotification[];
  setShowDropdown: () => void;
};

const NotificationNavIcon = ({ newNotificationsWithActions, setShowDropdown }: Props) => {
  return (
    <div className="nav-container" onClick={setShowDropdown}>
      <div className={`icon ${newNotificationsWithActions.length > 0 ? 'active' : ''}`}>
        <i className="far fa-bell"></i>
        {newNotificationsWithActions.length > 0 && <div className="badge">{newNotificationsWithActions.length}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    newNotificationsWithActions: getNewNotificationsWithActions(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationNavIcon);
