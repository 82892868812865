import RsList from '../../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../../components/rs-elements/rs-list/RsListItem';
import { getStaticMedia } from '../../../../../constants/Aws';

export default function NegotiationServiceLegal() {
  const items: RsListItem[] = [
    new RsListItem('Use pre-selected loan conditions and default inputs'),
    new RsListItem('Easy to use and intuitive user interface (accept, reject or counter-offer each configuration)'),
    new RsListItem('Export and import functionality for MS Word'),
    new RsListItem('Invite additional stakeholders (e.g. lawyers) to the platform on a loan by loan basis'),
  ];
  return (
    <div className="section text-bottom">
      <h4 className="has-text-centered headline-underlined">NEGOTIATE THE COMMERCIAL & LEGAL TERMS FULLY DIGITALLY</h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
          <div className="column is-6">
            <div className="img-container">
              <img src={getStaticMedia('/images/static-pages/negotiation_2.png')} />
            </div>
          </div>
        </div>
      </div>

      <h4 className="has-text-centered section-footer">
        <img
          className="digital-signing-headline"
          width="30"
          src={getStaticMedia('/icons/static-pages/digital-signature.png')}
        />
        DIGITAL SIGNATURES AVAILABLE FOR LOAN AGREEMENT, NDAs & COMPLIANCE CERTIFICATES
      </h4>
    </div>
  );
}
