import { boolean, object, string, StringSchema } from 'yup';
import { CompanyAddressValidation } from './CompanyValidation';
import { PlatformInteractionsReceiveData, PlatformInteractionsReceiveDataExtended } from './options';

const options = PlatformInteractionsReceiveData.map(option => option.value);
const optionsExtended = PlatformInteractionsReceiveDataExtended.map(option => option.value);

export const PlatformInteractionsValidation = (nullable: boolean = false) => {
  return {
    overwrite: boolean(),
    'receive-interest-fixing-notification': string().oneOf(options),
    'email-for-interest-fixing-notification': string()
      .when('receive-interest-fixing-notification', {
        is: val => val === 'emails',
        then: (schema: StringSchema) =>
          schema
            .required(
              "E-mail for interest fixing notification is required when Receive interest fixing notification is set to 'Emails'."
            )
            .nullable(nullable)
            .default(null),
      })
      .nullable(),
    'receive-payment-instruction': string().oneOf(optionsExtended).nullable(),
    'email-for-payment-instruction': string()
      .when('receive-payment-instruction', {
        is: val => val === 'emails',
        then: (schema: StringSchema) =>
          schema
            .required(
              "E-mail for payment instruction is required when Receive payment notification is set to 'Emails'."
            )
            .nullable(nullable)
            .default(null),
      })
      .nullable(),

    'receive-incoming-payment-notification': string().oneOf(options).nullable(),
    'email-for-payment-notification': string()
      .when('receive-incoming-payment-notification', {
        is: val => val === 'emails',
        then: (schema: StringSchema) =>
          schema
            .required(
              "E-mail for payment notifications is required when Receive incoming payment notification is set to 'Emails'."
            )
            .nullable(nullable)
            .default(null),
      })
      .nullable(),

    'receive-covenant-reports': string().oneOf(options).nullable(),
    'email-for-covenant-reports': string()
      .when('receive-covenant-reports', {
        is: val => val === 'emails',
        then: (schema: StringSchema) =>
          schema
            .required("E-mail for covenant-reports is required when Receive covenant reports is set to 'Emails'.")
            .nullable(nullable)
            .default(null),
      })
      .nullable(),

    'company-communication-address': object(CompanyAddressValidation(nullable)).nullable(nullable).default(null),
  };
};
