export const CompanyTypes = {
  platform_admin: 'platform_admin',
  agent: 'agent',
  borrower: 'borrower',
  lender: 'lender',
  government: 'government',
} as const;

export const CompanyTypeList = Object.keys(CompanyTypes) as CompanyTypeType[];

export type CompanyTypeType = (typeof CompanyTypes)[keyof typeof CompanyTypes];
