import Auth from '@aws-amplify/auth';
import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import RsInput from '../../../../../components/rs-form/rs-input/RsInput';
import RsForm from '../../../../../components/rs-form/RsForm';
import { createErrorStatus } from '../../../../../components/rs-form/utils/create-error-status';
import { setLoading } from '../../../../../redux/redux-app/app-actions';
import { rsSignIn } from '../../../../../services/utils/rs-sign-in';
import './ResetPasswordForm.scss';

type ResetPasswordFieldsType = {
  email: string;
  code: string;
  password: string;
  passwordRep: string;
};

type Props = {
  email: string;
};

function ResetPasswordForm(props: Props) {
  const dispatch = useDispatch();
  const [validationSchema] = useState(
    Yup.object({
      email: Yup.string().email('Invalid email address.').required('Email is required.'),
      code: Yup.string().required('Password reset code is required.'),
      password: Yup.string().required('Password is required.').min(8, 'Password must be at least 8 characters long.'),
      passwordRep: Yup.string()
        .test('match', 'Confirmation password should match the password.', function (confirmedPassword: string) {
          const { password } = this.parent;
          return confirmedPassword === password;
        })
        .required('Password confirmation is required.'),
    })
  );

  const [formFields] = useState({
    email: props.email ? props.email : '',
    code: '',
    password: '',
    passwordRep: '',
  });

  const onSubmit = async (values: ResetPasswordFieldsType, actions: FormikHelpers<ResetPasswordFieldsType>) => {
    try {
      dispatch(setLoading('Resetting your password...'));
      await Auth.forgotPasswordSubmit(values.email, values.code, values.password);
      dispatch(setLoading('Logging you in...'));
      await rsSignIn({ username: values.email, password: values.password });
      dispatch(setLoading(false));
    } catch (error) {
      actions.setStatus(createErrorStatus(error));
      dispatch(setLoading(false));
    }
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={formFields} onSubmit={onSubmit}>
      {({ status }) => (
        <RsForm status={status} submitLabel="Reset your password" className="reset-password-form">
          <div className="columns is-multiline is-centered">
            <div className="column is-12">
              <RsInput type="email" label="Email" icon="far fa-envelope" name="email" />
            </div>

            <div className="column is-12">
              <RsInput type="text" label="Password Reset Code" icon="fas fa-shield-alt" name="code" />
            </div>

            <div className="column is-12">
              <RsInput type="password" label="Password" icon="fas fa-lock" name="password">
                Your password needs to:
                <br />
                <ul>
                  <li>be at least 8 characters long</li>
                  <li>include both minimum one number and one special character</li>
                  <li>include both minimum one uppercase and one lowercase letter</li>
                </ul>
              </RsInput>
            </div>

            <div className="column is-12">
              <RsInput type="password" label="Confirm Password" icon="fas fa-lock" name="passwordRep" />
            </div>
          </div>
        </RsForm>
      )}
    </Formik>
  );
}

export default ResetPasswordForm;
