export enum Report {
  STATE_TREASURY_IPD = 'state-treasury-ipd',
  STATE_TREASURY_MONTHLY = 'state-treasury-monthly',
  QUARTERLY_LENDER = 'quarterly-lender',
  SCHEDULE = 'schedule',
  BOOKINGS = 'bookings',
  PROPERTY_DETAILS = 'property-details',
  LOAN_DETAILS = 'loan-details',
}

export type Format = { format?: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };

export type StateTreasuryIpdForm = { report: Report.STATE_TREASURY_IPD; ipd: string } & Format;
export type StateTreasuryMonthlyForm = { report: Report.STATE_TREASURY_MONTHLY; month: string } & Format;
export type ScheduleForm = { report: Report.SCHEDULE; ipd: string } & Format;
export type QuarterlyForm = { report: Report.QUARTERLY_LENDER; year: number; quarter: number } & Format;
export type BookingsForm = { report: Report.BOOKINGS; date: string } & Format;
export type PropertyDetailsForm = { report: Report.PROPERTY_DETAILS } & Format;
export type AllLoansDetailsForm = { report: Report.LOAN_DETAILS } & Format;

export type ReportType =
  | StateTreasuryIpdForm
  | StateTreasuryMonthlyForm
  | ScheduleForm
  | QuarterlyForm
  | BookingsForm
  | PropertyDetailsForm
  | AllLoansDetailsForm;
