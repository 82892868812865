import RsList from '../../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../../components/rs-elements/rs-list/RsListItem';
import { getStaticMedia } from '../../../../../constants/Aws';

export default function TransfersServiceTransferability() {
  const items: RsListItem[] = [
    new RsListItem(
      'All lender transfers can be done through the platform (whole loan and parts of it) as opposed to a manual transfer certificate process'
    ),
    new RsListItem(
      'Lenders can offer loan transfers directly on the platform to other accepted lenders, specified by the borrower'
    ),
    new RsListItem('All required signatures (NDA etc) done digitally on the platform'),
  ];
  return (
    <div className="section is-grey">
      <h4 className="has-text-centered headline-underlined">DIGITAL TRANSFERABILITY OF LOANS ON THE PLATFORM</h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-6">
            <div className="img-container">
              <img src={getStaticMedia('/images/static-pages/transfers_1.png')} />
            </div>
          </div>
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
        </div>
      </div>
    </div>
  );
}
