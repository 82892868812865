import {
  AssetUnderManagementType,
  CompanyAddressType,
  CompanyConfigurations,
  CompanyLMMConfiguration,
  CompanyTypeType,
  InvestorType,
  PlatformInteractionsType,
  RsUploadedFileType,
} from '@realstocks/types';

export const COMPANY_KYC_STATUSES = {
  NEW: 'new', // initial status
  READY: 'ready', // ready to be sent to KYC provider
  PENDING: 'pending', // sent to KYC provider, awaiting result
  APPROVED: 'approved', // approved by KYC provider, FINAL STATE
  REJECTED: 'rejected', // rejected by KYC provider
  RETRYING: 'retrying', // retrying KYC, awaiting result
};

export type CompanyKycStatusType = 'new' | 'ready' | 'pending' | 'approved' | 'rejected' | 'retrying';

export type BorrowerOrLenderType = 'borrower' | 'lender';

export type CompanyInformationType = {
  companyName: string;
  registrationNumber: string;
  phone: string;
  email: string;
  contactPerson: string;
  address: CompanyAddressType;
  legalForm: string;
  industry: string;
  incorporatedDate: string;
  sourceOfFunds: string;
  website: string;
  professionalInvestorStatus: string;
  type: CompanyTypeType;
  listed: boolean | string;
  id?: string;
  euStockExchange?: string;
  kycStatus?: string;
  iban?: string;
  bic?: string;
  aum: AssetUnderManagementType | '';
  investorType: InvestorType | '';
};

export type RepresentativeType = {
  dateOfBirth: string | null;
  name: string;
  nationality: string;
  role: string;
  surname: string;
  verificationType: string;
  isPep: boolean | string;
  id?: string;
  passport?: RsUploadedFileType[];
};

export const UboEntity: { naturalPerson: 'natural-person'; legalEntity: 'legal-entity' } = {
  naturalPerson: 'natural-person',
  legalEntity: 'legal-entity',
};

export const UboIsLegalEntityOrNaturalPersonKey = 'isLegalEntityOrNaturalPerson';

const UboLegalEntityKey = 'legalEntity';

const UboNaturalPersonKey = 'naturalPerson';

export interface UboType {
  [UboNaturalPersonKey]?: UboNaturalPersonType;
  [UboLegalEntityKey]?: UboLegalEntityType;
  [UboIsLegalEntityOrNaturalPersonKey]?: 'natural-person' | 'legal-entity';
  id?: string;
}

export interface UboNaturalPersonType {
  name: string;
  nationality: string;
  politicalExposedPerson?: boolean;
  sharePercentage: number | '';
  surname: string;
  dateOfBirth: string | null;
  passport?: RsUploadedFileType[];
  positionOfPep?: string;
  pepCountry?: string;
}

export interface UboLegalEntityType {
  name: string;
  registrationNumber: string;
  country: string;
  shareAmount: number | '';
}

export type CompanyDocumentsKeysType =
  | 'extractFromTradeRegister'
  | 'articlesOfAssociation'
  | 'ownershipStructure'
  | 'powerOfAttorney';

export type CompanyDocumentsType = {
  extractFromTradeRegister?: RsUploadedFileType[];
  articlesOfAssociation?: RsUploadedFileType[];
  ownershipStructure?: RsUploadedFileType[];
  powerOfAttorney?: RsUploadedFileType[];
  allowedToSign?: boolean;
};

export const CompanyDocumentKeys = [
  'extractFromTradeRegister',
  'articlesOfAssociation',
  'ownershipStructure',
  'powerOfAttorney',
];

export class CompanyModel {
  id: string;
  openedBy: string; // userId value for the company admin.
  companyInformation: CompanyInformationType;
  platformInteractions: PlatformInteractionsType;
  representatives: RepresentativeType[];
  documents: CompanyDocumentsType;
  kycStatus: CompanyKycStatusType;
  createdAt: number;
  isFetched: boolean | null;
  exists: boolean | null;
  prefilledData: any;
  prototype?: any;
  completed?: boolean;
  ubo?: UboType[] | null;
  configurations?: CompanyConfigurations;
  lmmConfig?: CompanyLMMConfiguration;

  constructor(data: {
    id: string;
    openedBy: string;
    companyInformation: CompanyInformationType;
    platformInteractions: PlatformInteractionsType;
    representatives: RepresentativeType[];
    documents: CompanyDocumentsType;
    kycStatus: CompanyKycStatusType;
    createdAt: number;
    isFetched: boolean | null;
    exists: boolean | null;
    prefilledData: any;
    prototype?: any;
    completed?: boolean;
    ubo?: UboType[] | null;
    configurations?: CompanyConfigurations;
    lmmConfig?: CompanyLMMConfiguration;
  }) {
    this.id = data.id;
    this.openedBy = data.openedBy;
    this.completed = data.completed;
    this.companyInformation = data.companyInformation;
    this.platformInteractions = data.platformInteractions;
    this.representatives = data.representatives;
    this.ubo = data.ubo;
    this.documents = data.documents;
    this.kycStatus = data.kycStatus;
    this.createdAt = data.createdAt;
    this.isFetched = data.isFetched;
    this.exists = data.exists;
    this.prefilledData = data.prefilledData;
    this.prototype = data.prototype;
    this.configurations = data.configurations;
    this.lmmConfig = data.lmmConfig;
  }
}

export const CompanyTypes = {
  borrower: 'borrower',
  lender: 'lender',
  agent: 'agent',
  platform_admin: 'platform_admin',
};
