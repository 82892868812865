import { connect } from 'react-redux';
import { getInvisibleOverlay } from '../../redux/redux-app/app-selectors';
import { AppState } from '../../store';
import './RsOverlay.scss';

type Props = ReduxStateProps & ReduxDispatchProps & {};

function RsOverlay(props: Props) {
  return <div className="rs-overlay" style={{ display: props.showOverlay ? 'block' : 'none' }}></div>;
}

const mapStateToProps = (state: AppState): ReduxStateProps => {
  return {
    showOverlay: getInvisibleOverlay(state),
  };
};

type ReduxStateProps = {
  showOverlay: boolean;
};

type ReduxDispatchProps = {};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RsOverlay);
