import { useState } from 'react';
import { Link, NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withNavigation } from '../../../components/navigation-hoc/withNavigation';
import ClientPath from '../../../routes/ClientPath';
import ResendVerificationForm from './components/resend-verification-form/ResendVerificationForm';
import './ResendVerificationCodePage.scss';

type Props = { navigate: NavigateFunction };

function ResendVerificationCodePage(props: Props) {
  const [autofilledEmail] = useState(new URLSearchParams(window.location.search).get('email'));

  const successCallback = (email: string) => {
    toast.success('Verification code sent! Please check your email for the new code.');
    props.navigate(`${ClientPath.auth.verifyAccount}/?email=${encodeURIComponent(email) || ''}`);
  };

  const email = autofilledEmail ? decodeURIComponent(autofilledEmail) : '';

  return (
    <div className="input-page resend-verification-page">
      <div className="form-headline">
        <h1 className="title">Welcome Back</h1>
        <p className="subtitle">Please enter your login details</p>
      </div>
      <div className="columns is-centered">
        <div className="column box section">
          <ResendVerificationForm email={email} successCallback={successCallback} />

          <div className="columns is-centered verify-account-btn-container">
            <Link
              className="button is-text"
              to={`${ClientPath.auth.verifyAccount}/?email=${encodeURIComponent(email) || ''}`}
            >
              Confirm / Verify Account
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withNavigation(ResendVerificationCodePage);
