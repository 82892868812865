import * as Yup from 'yup';
import { ObjectStripUnknown, RequiredArray, TextStripEmpty } from './atoms';

const SequenceStructure = ObjectStripUnknown({
  id: TextStripEmpty,
  name: TextStripEmpty.required(),
  construction: Yup.object(),
});

const PropertyStructure = ObjectStripUnknown({
  id: TextStripEmpty,
  name: TextStripEmpty.required('Property Name is required'),
});

export const LoanStructureValidation = ObjectStripUnknown({
  id: TextStripEmpty,
  name: TextStripEmpty.required(),
  properties: RequiredArray(PropertyStructure),
  sequences: RequiredArray(SequenceStructure),
});
