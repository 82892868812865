import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import RsLoading from '../../components/rs-loading/RsLoading';
import { getStaticMedia } from '../../constants/Aws';
import { Breakpoints } from '../../constants/Generic';
import { ImageConstants } from '../../constants/images';
import { getCurrentUserOrNull } from '../../redux/redux-auth/auth-selectors';
import ClientPath from '../../routes/ClientPath';
import { NavigationContext } from '../../routes/NavigationContext';
import LoggedInMenuComponent from './components/LoggedInMenuComponent';
import LoggedOutMenuComponent from './components/LoggedOutMenuComponent';
import './RsMainNavigation.scss';

function RsMainNavigation() {
  const [mobileMenuIsOpened, _setMobileMenuIsOpened] = useState<boolean>(false);
  const mobileMenuIsOpenedRef = React.useRef<boolean>(mobileMenuIsOpened);
  const [navRef, _setNavRef] = useState(React.createRef<HTMLDivElement>());
  const [windowWidth, setWindowWidth] = useState<number | null>(null);
  const currentUser = useSelector(getCurrentUserOrNull);
  const location = useLocation();
  const { loading: loadingNav } = useContext(NavigationContext);
  const rsNavRef = useRef<HTMLDivElement>(null);

  let lastKnownScrollPosition = 0;
  let ticking = false;

  useEffect(() => {
    if (windowWidth !== null) {
      if (windowWidth < Breakpoints.desktop) {
        if (mobileMenuIsOpenedRef.current) {
          document.addEventListener('click', handleOutsideClick, { once: true });
        } else {
          document.removeEventListener('click', handleOutsideClick, true);
        }
      } else {
        if (navRef) {
          setMobileMenuIsOpened(false);
        }
      }
    }
  }, [mobileMenuIsOpenedRef.current, windowWidth]);

  useEffect(() => {
    setMobileMenuIsOpened(false);
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const handleResizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const setMobileMenuIsOpened = (newState: boolean) => {
    mobileMenuIsOpenedRef.current = newState;
    _setMobileMenuIsOpened(newState);
  };

  function handleOutsideClick(e: any) {
    // ignore clicks on the component itself
    if (
      (navRef.current && navRef.current.contains(e.target)) ||
      e.target.closest(`.navbar-burger`) ||
      e.target.closest(`.logo`)
    ) {
      return;
    }

    setMobileMenuIsOpened(!mobileMenuIsOpenedRef.current);
  }

  const noPathShadowCondition = () =>
    location.pathname !== '/' &&
    (location.pathname.indexOf(ClientPath.loans.index) >= 0 ||
      location.pathname.indexOf(ClientPath.trade.index) >= 0 ||
      location.pathname.indexOf(ClientPath.company.index) >= 0 ||
      location.pathname.indexOf(ClientPath.realstocksAdmin.index) >= 0 ||
      location.pathname.indexOf(ClientPath.portfolio.index) >= 0 ||
      location.pathname.indexOf(ClientPath.static.tos) >= 0 ||
      location.pathname.indexOf(ClientPath.account.index) >= 0);

  const handlePathDropShadow = (): void => {
    if (noPathShadowCondition()) {
      if (rsNavRef.current) {
        rsNavRef.current.classList.remove('drop-shadowed');
      }
    } else {
      if (rsNavRef.current) {
        rsNavRef.current.classList.add('drop-shadowed');
      }
    }
  };

  const noScrollShadowCondition = () =>
    location.pathname === '/' ||
    location.pathname === ClientPath.static.aboutUs ||
    location.pathname === ClientPath.static.contact;

  const handleScrollDropShadow = () => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(function () {
        if (lastKnownScrollPosition >= 523) {
          if (!noScrollShadowCondition() && rsNavRef.current) {
            rsNavRef.current.classList.remove('drop-shadowed');
          }
        } else {
          if (!noPathShadowCondition() && rsNavRef.current) {
            rsNavRef.current.classList.add('drop-shadowed');
          }
        }
        ticking = false;
      });

      ticking = true;
    }
  };

  useEffect(() => {
    handlePathDropShadow();
    handleScrollDropShadow();
    window.addEventListener('resize', handlePathDropShadow);
    window.addEventListener('scroll', handleScrollDropShadow);
    return () => {
      window.removeEventListener('resize', handlePathDropShadow);
      window.removeEventListener('scroll', handleScrollDropShadow);
    };
  }, [location.pathname]);

  const showDropShadow = !noPathShadowCondition();

  return (
    <nav
      ref={rsNavRef}
      className={`navbar rs-main-navigation is-fixed-top ${showDropShadow ? 'drop-shadowed' : ''}`}
      role="navigation"
      aria-label="Realstocks Main Navigation"
    >
      <div className="container is-fullhd">
        <div className="rs-main-navigation-content">
          <div className="navbar-brand">
            <Link className="navbar-item logo !max-h-[initial]" to={ClientPath.home}>
              <img
                src={getStaticMedia(`/${ImageConstants.header.logo}`)}
                alt="Realstocks logo"
                className="h-12 w-auto !max-h-[initial]"
              />
            </Link>

            {loadingNav ? (
              <div className="columns is-loading-container is-centered is-full">
                <div className="column is-8"></div>
                <div className="column is-4">
                  <RsLoading width="100%" height="15px" />
                </div>
              </div>
            ) : (
              windowWidth !== null &&
              windowWidth < Breakpoints.desktop && (
                <>
                  <div
                    className="toggle-menu-container"
                    onClick={() => {
                      setMobileMenuIsOpened(!mobileMenuIsOpenedRef.current);
                    }}
                  >
                    <div className="navbar-burger" data-target="rs-navigation-menu">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </>
              )
            )}
          </div>

          {loadingNav ? (
            <div className="columns is-loading-container is-centered is-full">
              <div className="column is-2"></div>
              <div className="column is-4">
                <RsLoading width="100%" height="15px" />
              </div>
              <div className="column is-3"></div>
              <div className="column is-2">
                <RsLoading width="100%" height="15px" />
              </div>
            </div>
          ) : (
            <div
              ref={navRef}
              id="rs-navigation-menu"
              className={`navbar-menu ${
                windowWidth !== null && windowWidth < Breakpoints.desktop
                  ? mobileMenuIsOpened
                    ? 'expanded animate__animated animate__slideInDown'
                    : 'collapsed animate__animated animate__slideOutUp'
                  : ''
              }`}
            >
              {!currentUser && <LoggedOutMenuComponent />}
              {currentUser && <LoggedInMenuComponent />}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default RsMainNavigation;
