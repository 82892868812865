import { getStaticMedia } from '../../../../constants/Aws';
import { ProcessEnv } from '../../../../constants/ProcessEnv';
import './TeamMember.scss';

export type TeamMemberType = {
  name: string;
  status: string;
  img: string;
  linkedin?: string;
  description: string;
  expanded: boolean;
};

type Props = {
  member: TeamMemberType;
  row: string;
  toggleMember: Function;
};

export default function TeamMember(props: Props) {
  return (
    <div className="team-member-container column is-half-tablet is-half-mobile is-one-third-desktop">
      <div className="columns is-multiline">
        <div
          className={`column is-6 member-img-block ${
            props.member.expanded ? 'team-member-expanded' : 'team-member-collapsed'
          }`}
        >
          {props.member.img && (
            <img
              className="member-img"
              src={`${ProcessEnv.aws.s3.buckets.media}/pages/about-us/team/${props.member.img}`}
            />
          )}

          {!props.member.img && (
            <div className="member-img-placeholder">
              <p className="has-text-centered">No image available.</p>
            </div>
          )}
        </div>

        <div className="column is-6 team-member-details">
          <div>
            <p
              className="has-text-weight-bold member-name"
              dangerouslySetInnerHTML={{
                __html: props.member.name,
              }}
            ></p>
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: props.member.status,
              }}
            ></p>
          </div>

          <div className="team-member-details-footer">
            {props.member.linkedin && (
              <a href={props.member.linkedin} target="_blank" rel="noreferrer">
                <img className="linkedin-icon" src={`${getStaticMedia('/icons/linkedin-icon.png')}`} />
              </a>
            )}
            <div
              className="details-link"
              onClick={() => {
                props.toggleMember(props.row, props.member);
              }}
            >
              <p className={`${props.member.expanded ? 'team-member-expanded' : 'team-member-collapsed'}`}>Details</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
