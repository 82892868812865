export type SubLinksType = { [key: string]: RsNavigationLink };

type RsNavigationLinkOptionsType = {
  classes?: string;
  divider?: boolean;
  hidden?: boolean;
  customActiveCondition?: boolean;
};

export class RsNavigationLink {
  public allow: boolean = true;
  public sublinks: SubLinksType = {};
  public options: RsNavigationLinkOptionsType = {};
  public state: any = {};
  public search: string = '';
  public callback?: Function;
  public fa?: string;

  constructor(public label: string, public path: string = '', public noActive: boolean = false) {
    return this;
  }

  public setPath(p: string): this {
    this.path = p;

    return this;
  }

  public setIcon(fa: string): this {
    this.fa = fa;

    return this;
  }

  public setAllow(allow?: boolean): this {
    this.allow = allow === undefined ? true : allow;

    return this;
  }

  public setState(state: any): this {
    this.state = state;

    return this;
  }

  public setSearch(search: string = ''): this {
    this.search = search || '';

    return this;
  }

  public setSubLinks(sublinks: SubLinksType): this {
    this.sublinks = sublinks;

    return this;
  }

  public setCallback(callback: Function): this {
    this.callback = callback;

    return this;
  }

  public setOptions(options: RsNavigationLinkOptionsType): this {
    this.options = options;

    return this;
  }
}
