import { useState } from 'react';
import { getStaticMedia } from '../../../constants/Aws';
import RsModalBodyComponent from '../../rs-modal/RsModalBodyComponent';
import RsModalComponent from '../../rs-modal/RsModalComponent';
import RsModalHeaderComponent from '../../rs-modal/RsModalHeaderComponent';
import './RsImage.scss';

type Props = {
  alt: string;
  src: string;
  style?: any;
  desktopImgStyle?: any;
};

function RsImage(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const close = () => setIsModalOpen(false);

  return (
    <div className="rs-image" style={props.style || {}}>
      <img style={props.desktopImgStyle || {}} alt={props.alt} src={props.src} />
      <div
        className="zoom-in is-hidden-desktop"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <img src={getStaticMedia('/icons/zoom-icon.svg')} alt="Zoom In" />
      </div>

      <RsModalComponent
        className="rs-image-modal is-hidden-desktop"
        isOpen={isModalOpen}
        contentLabel="Image Zoom"
        overlayClassName="rs-image-modal-overlay"
        onRequestClose={close}
      >
        <RsModalHeaderComponent close={close} />
        <RsModalBodyComponent>
          <img alt={props.alt} src={props.src} />
        </RsModalBodyComponent>
      </RsModalComponent>
    </div>
  );
}

export default RsImage;
