export type CovenantTestingDatesType = 'Q1' | 'Q2' | 'Q3' | 'Q4' | 'Q1-Q3' | 'Q2-Q4' | 'Q1-Q2-Q3-Q4';

export const AllCovenantTestingDateLabels: Record<CovenantTestingDatesType, string> = {
  Q1: 'March 31',
  Q2: 'June 30',
  Q3: 'September 30',
  Q4: 'December 31',
  'Q1-Q3': 'March 31, September 30',
  'Q2-Q4': 'June 30, December 31',
  'Q1-Q2-Q3-Q4': 'March 31, June 30, September 30, December 31',
} as const;
