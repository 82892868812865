import { ChatConversations, ChatMessage, ChatState, ChatWindow } from '../../types/chat';
import { CHAT_ACTIONS } from './chat-actions';

const initialState = {
  chatWindows: [],
  contacts: [],
  contactsLoading: false,
  contactsError: false,
  conversations: {},
  conversationsLoading: false,
  conversationsError: false,
};

/**
 * Error Reducer takes care of the global error messages inside the application.
 *
 * @param {*} state
 * @param {*} action
 */

export const ChatReducer = (state: ChatState = initialState, action: any) => {
  switch (action.type) {
    case CHAT_ACTIONS.resetChatState: {
      return {
        ...initialState,
      };
    }
    case CHAT_ACTIONS.setContactsLoading:
      return {
        ...state,
        contactsLoading: action.payload,
      };

    case CHAT_ACTIONS.getConversations:
      const newConversations = action.payload;
      let conversations: ChatConversations = {};

      // update existing conversations
      for (let key in state.conversations) {
        const c = state.conversations[key];
        if (newConversations[key]) {
          conversations[key] = {
            ...c,
            ...newConversations[key],
          };
          delete newConversations[key];
        } else {
          conversations[key] = {
            ...c,
          };
        }
      }

      // add new conversations
      for (let key in newConversations) {
        const c = newConversations[key];
        conversations[key] = {
          ...c,
        };
      }

      return {
        ...state,
        conversations: conversations,
      };
    case CHAT_ACTIONS.addConversation:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [action.payload.conversationID]: action.payload,
        },
      };
    case CHAT_ACTIONS.getMessages: {
      const conversations = {
        ...state.conversations,
      };
      conversations[action.payload.conversationID] = {
        ...conversations[action.payload.conversationID],
        messages: action.payload.messages,
      };
      return {
        ...state,
        conversations,
      };
    }
    case CHAT_ACTIONS.setConversationAsRead: {
      const conversations: ChatConversations = {
        ...state.conversations,
        [action.payload]: {
          ...state.conversations[action.payload],
          isUnread: false,
        },
      };
      return {
        ...state,
        conversations,
      };
    }
    case CHAT_ACTIONS.setUserPresence: {
      const conversations = {
        ...state.conversations,
      };
      for (let key of Object.keys(conversations)) {
        const c = state.conversations[key];
        if (c.recipient.contactID === action.payload.contactID) {
          conversations[key].recipient.status = action.payload.status;
        }
      }
      return {
        ...state,
        conversations,
      };
    }
    case CHAT_ACTIONS.getCompanyContacts:
      return {
        ...state,
        companies: action.payload,
      };
    case CHAT_ACTIONS.getChatContacts:
      return {
        ...state,
        chatContacts: action.payload,
      };
    case CHAT_ACTIONS.searchContacts:
      return {
        ...state,
        contacts: action.payload,
      };
    case CHAT_ACTIONS.addChatWindow:
      // check if window already open
      const existing = state.chatWindows.filter((w) => w.conversationID === action.payload.conversationID);
      if (existing.length > 0) {
        return {
          ...state,
        };
      }

      let win: ChatWindow[] = [...state.chatWindows, action.payload];
      // check if too many open
      if (win.length > 3) {
        win.shift();
      }

      return {
        ...state,
        chatWindows: win,
      };
    case CHAT_ACTIONS.setChatMessages: {
      const conversations = {
        ...state.conversations,
      };
      conversations[action.payload.conversationID] = {
        ...conversations[action.payload.conversationID],
        messages: action.payload.messages || [],
      };
      return {
        ...state,
        conversations: conversations,
      };
    }
    case CHAT_ACTIONS.closeChatWindow:
      let newWin = [...state.chatWindows];
      if (newWin.length > 1) {
        newWin.splice(action.payload, 1);
      } else {
        newWin = [];
      }
      return {
        ...state,
        chatWindows: newWin,
      };
    case CHAT_ACTIONS.pushNewMessage: {
      const getMessages = (conversationID: string, newMessage: ChatMessage) => {
        if (state.conversations[conversationID]?.messages?.length > 0) {
          return [...state.conversations[conversationID].messages, newMessage];
        } else {
          return [newMessage];
        }
      };
      const { conversationID } = action.payload.message;
      const conversations = {
        ...state.conversations,
        [conversationID]: {
          ...state.conversations[conversationID],
          messages: getMessages(conversationID, action.payload.message),
        },
      };
      return {
        ...state,
        conversations,
      };
    }
    // case CHAT_ACTIONS.pushNewMessage:
    //   let contacts = JSON.parse(JSON.stringify(state.chatContacts));
    //   let windowIndexForNewMessage = windows
    //     .map((window: ChatWindowType) => window.channelID)
    //     .indexOf(action.payload.message.channelID);
    //   if (windowIndexForNewMessage !== -1) {
    //     windows[windowIndexForNewMessage].chatMessages.push(action.payload.message);
    //   }

    //   if (state.chatContacts) {
    //     let contactIndex = contacts
    //       .map((contact: ChatContactType) => contact.channelID)
    //       .indexOf(action.payload.message.channelID);
    //     if (contactIndex !== -1) {
    //       contacts[contactIndex].unreadMsgCount = (contacts[contactIndex].unreadMsgCount || 0) + 1;
    //     }
    //   }
    //   return {
    //     ...state,
    //     chatWindows: windows,
    //     chatContacts: contacts,
    //   };
    default:
      return state;
  }
};
