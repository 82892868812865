import { defineApi } from '../common/api-definition';
import { CompanyInterface } from '../company/company.interface';
import { AvailableTrancheAmendment } from '../loan/NextCommandType';
import { StructuredConstructionType, StructuredTranchesType } from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';

export type SequenceTrancheViewRequest = { loanId: string; trancheId: string };

export type SequenceTrancheView = TermsSequenceTrancheView | ConstructionSequenceTrancheView;

export type TermsSequenceTrancheView = ISequenceTrancheView & {
  type: 'terms';
  tranche: StructuredTranchesType;
};

export type ConstructionSequenceTrancheView = ISequenceTrancheView & {
  type: 'construction';
  tranche: StructuredConstructionType;
};

export type ISequenceTrancheView = {
  loanId: string;
  facilityId: string;
  sequenceId: string;
  trancheId: string;
  version: number;
  state: StructuredLoanState;
  currency: string | undefined;
  owner?: CompanyInterface;

  // Always set if the loan is in amend mode.
  amend?:
    | {
        // Amendment of a tranche that already exists.
        type: 'tranche';

        // The attributes of the tranche that can be amended. The attribute is
        // undefined, if no attributes can be set.
        tranche?: AvailableTrancheAmendment;
      }
    | {
        // Amendment of a new tranche that has not been sent to the LCE. The attributes
        // of the tranche can be amended freely.
        type: 'new-tranche';
      };
};

export const sequenceTrancheViewApi = defineApi<{
  body: SequenceTrancheViewRequest;
  response: SequenceTrancheView;
}>({
  url: 'structured-loans/sequence-tranche-view',
  method: 'post',
});
