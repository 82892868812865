import { useEffect } from 'react';

const getWidth = (windowWidth: number) => Math.min(Math.max(windowWidth - 60, 400), 1024);
const getHeight = (windowHeight: number) => Math.max(windowHeight - 200, 400);

export function TermsEmbed({ id, pdfUrl }: { id: string; pdfUrl: string }) {
  useEffect(() => {
    const listener = () => {
      const obj = document.getElementById(id) as HTMLObjectElement | null;
      if (obj == null) {
        return;
      }

      obj.width = getWidth(window.innerWidth).toString();
      obj.height = getHeight(window.innerHeight).toString();
    };

    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [id]);

  return (
    <div className="text-center">
      <object
        id={id}
        type="application/pdf"
        data={pdfUrl}
        width={getWidth(window.innerWidth)}
        height={getHeight(window.innerHeight)}
      >
        Loading terms...
      </object>
    </div>
  );
}
