export class RsListItem {
  public text: string;
  public prepended: string = '';
  public appended: string = '';
  public isBold: boolean = false;
  public items: RsListItem[] | null = null;
  public info?: string;

  constructor(text: string) {
    this.text = text;

    return this;
  }

  public setBold() {
    this.isBold = true;

    return this;
  }

  public setItems(items: RsListItem[]) {
    this.items = items;

    return this;
  }
}
