import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RsButton from '../rs-elements/rs-button/RsButton';
import './RsErrorFallback.scss';

const showFeedback = process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE;

function RsErrorFallback({
  eventId,
  resetErrorBoundary,
  text,
  data,
}: {
  eventId: string | undefined;
  resetErrorBoundary: () => void;
  text?: string;
  data?: unknown;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [path, setPath] = useState(location.pathname);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (path !== location.pathname) {
      resetErrorBoundary();
      setPath(location.pathname);
    }
  }, [location.pathname, path, resetErrorBoundary]);

  const handleReload = () => {
    setLoading(true);
    window.location.reload();
  };

  const handleFeedback = () => {
    Sentry.showReportDialog({ eventId });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="fallback-container">
      <h2 className="has-text-centered">
        An error has occurred.
        <br />
        We are looking into it, sorry for the inconveniences
      </h2>

      {text != null && <p>{text}</p>}

      {data != null && (
        <details>
          <summary>Error details</summary>
          <pre className="w-full overflow-x-scroll">{JSON.stringify(data, undefined, 2)}</pre>
        </details>
      )}

      <div className="buttons-container">
        <div className="mr-3">
          <RsButton type="default" inverted onClick={goBack} disabled={loading}>
            Go back
          </RsButton>
        </div>

        <RsButton type="primary" onClick={handleReload} disabled={loading}>
          {loading ? 'Please wait' : 'Reload'}
        </RsButton>

        <div className="ml-3">
          {showFeedback && (
            <RsButton type="success" onClick={handleFeedback} disabled={loading}>
              Report
            </RsButton>
          )}
        </div>
      </div>
    </div>
  );
}

export const RsErrorFallbackRaw = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleReload = () => {
    setLoading(true);
    window.location.reload();
  };

  return (
    <div className="fallback-container">
      <h2 className="has-text-centered">
        An error has occurred. <br />
        We are looking into it, sorry for the inconveniences
      </h2>

      <div className="buttons-container">
        <div className="mr-3"></div>

        <RsButton type="primary" onClick={handleReload} disabled={loading}>
          {loading ? 'Please wait' : 'Reload'}
        </RsButton>

        <div className="ml-3"></div>
      </div>
    </div>
  );
};

export default RsErrorFallback;
