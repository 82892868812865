import Auth from '@aws-amplify/auth';
import { RoutesAccessRightsMap, RsCognitoUser } from '@realstocks/types';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BankEIdMethods } from '../../../components/rs-elements/rs-link-bank-id-btn/constants/BankEIdMethods';
import { createErrorStatus } from '../../../components/rs-form/utils/create-error-status';
import { AuthenticationTypes } from '../../../constants/Aws';
import { BankEidCodeType } from '../../../constants/BankEid';
import { ProcessEnv } from '../../../constants/ProcessEnv';
import { RsError } from '../../../constants/ServerError';
import { getRoutesAccessRights } from '../../../redux/redux-access-rights/access-rights-selector';
import { setLoading } from '../../../redux/redux-app/app-actions';
import { getCurrentUserOrNull } from '../../../redux/redux-auth/auth-selectors';
import {
  getCompanyExists,
  getCompanyInformationOrNull,
  getCompanyOrNull,
  isCompanyCompleted,
  isCompanyFetched,
} from '../../../redux/redux-company/company-selectors';
import ClientPath from '../../../routes/ClientPath';
import { BankEidService } from '../../../services/api/BankEidService';
import { AppState } from '../../../store';
import { BankEidConfirmationTypes } from '../../../types/BankEidConfirmationTypes';
import { CompanyInformationType, CompanyModel } from '../../../types/Company';
import { BankEidConfirmationUrlParams } from '../bank-eid-confirmation/BankEidConfirmationPage';
import LoginHeader from '../components/login-header/LoginHeader';
import { successfulLoginRedirect } from '../login-page/successful-login-redirect';
import BankEidLoginForm from './components/bank-eid-login-form/BankEidLoginForm';
import './LoginWithBankEid.scss';

type Props = ReduxDispatchProps &
  ReduxStateProps & {
    initialized: boolean;
  };

function LoginWithBankEid(props: Props) {
  const { currentUser, isCompanyFetched, hasCompanyCompleted, companyExists, companyInformation, initialized } = props;
  const navigate = useNavigate();
  const routesAccessRightsMap: RoutesAccessRightsMap = useSelector(getRoutesAccessRights);

  useEffect(() => {
    props.setLoading('Loading');
  }, []);

  useEffect(() => {
    if (initialized && currentUser && currentUser.attributes['custom:bankid']) {
      successRedirect(routesAccessRightsMap);
    } else if (initialized) {
      props.setLoading(false);
    }
  }, [currentUser, initialized, isCompanyFetched, hasCompanyCompleted, companyExists, companyInformation]);

  const successRedirect = async (routesAccessRightsMap: RoutesAccessRightsMap) => {
    await successfulLoginRedirect({
      ...props,
      navigate,
      companyId: currentUser?.attributes['custom:company'],
      routesAccessRightsMap,
    });
  };

  const submit = async (actions: any, bankeid: BankEidCodeType, email: string) => {
    props.setLoading('Initializing...');

    const scriveRedirectWindow = window.open(ClientPath.loading);

    Auth.configure({ authenticationFlowType: AuthenticationTypes.custom });

    try {
      const redirectUrl =
        ProcessEnv.aws.domain +
        `${ClientPath.auth.bankEidConfirmation.replace(
          BankEidConfirmationUrlParams.type,
          BankEidConfirmationTypes.login
        )}?email=${encodeURIComponent(email)}`;

      const bankEidTransactionResponse = await BankEidService.createTransaction(
        BankEIdMethods.auth,
        bankeid,
        redirectUrl,
        {
          Authorization: Math.random().toString(),
        }
      );

      props.setLoading('Opening PopUp to login into your BankEid...');

      if (scriveRedirectWindow) {
        scriveRedirectWindow.location.href = bankEidTransactionResponse.message.accessUrl;
      } else {
        window.open(bankEidTransactionResponse.message.accessUrl, '_blank');
      }
    } catch (err) {
      props.setLoading(false);
      const errorPrefix = 'CreateAuthChallenge failed with error';
      const error: any = err;

      if (error.message && error.message.indexOf(errorPrefix) > -1) {
        actions.setStatus(createErrorStatus(new RsError(error.message.replace(errorPrefix, ''))));
      } else {
        actions.setStatus(createErrorStatus(new RsError('Something went wrong, please try again.')));
      }
    }
  };

  return (
    <div className="container login-with-bankid-page">
      <LoginHeader />

      <BankEidLoginForm submitCallback={submit} />
    </div>
  );
}

type ReduxDispatchProps = {
  setLoading: (loading: string | boolean) => void;
};

const mapDispatchToProps: ReduxDispatchProps = {
  setLoading: setLoading,
};

type ReduxStateProps = {
  company: CompanyModel | null;
  currentUser: RsCognitoUser | null;
  isCompanyFetched: boolean | null;
  hasCompanyCompleted: boolean | null;
  companyExists: boolean | null;
  companyInformation: CompanyInformationType | null;
};

const mapStateToProps = (state: AppState): ReduxStateProps => {
  return {
    company: getCompanyOrNull(state),
    hasCompanyCompleted: isCompanyCompleted(state),
    currentUser: getCurrentUserOrNull(state),
    isCompanyFetched: isCompanyFetched(state),
    companyExists: getCompanyExists(state),
    companyInformation: getCompanyInformationOrNull(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginWithBankEid);
