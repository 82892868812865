import Auth from '@aws-amplify/auth';
import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import RsInput from '../../../../../components/rs-form/rs-input/RsInput';
import RsForm from '../../../../../components/rs-form/RsForm';
import { createErrorStatus } from '../../../../../components/rs-form/utils/create-error-status';
import { setLoading } from '../../../../../redux/redux-app/app-actions';

type ForgotPasswordFormFieldsType = {
  email: string;
};

type Props = {
  successCallback: Function;
  email: string | null;
};

function ForgotPasswordForm(props: Props) {
  const dispatch = useDispatch();
  const [validationSchema] = useState(
    Yup.object({
      email: Yup.string().email('Invalid email address.').required('Email is required.'),
    })
  );

  const [formFields] = useState({
    email: props.email || '',
  });

  const onSubmit = async (
    values: ForgotPasswordFormFieldsType,
    actions: FormikHelpers<ForgotPasswordFormFieldsType>
  ) => {
    try {
      dispatch(setLoading('Sending verification code to the inserted email...'));
      await Auth.forgotPassword(values.email);
      dispatch(setLoading(false));
      props.successCallback(values.email);
    } catch (error) {
      actions.setStatus(createErrorStatus(error));
      dispatch(setLoading(false));
    }
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={formFields} onSubmit={onSubmit}>
      {({ status }) => (
        <RsForm status={status} submitLabel="Get Password Reset Code">
          <div className="columns is-multiline is-centered">
            <div className="column is-12">
              <RsInput type="email" label="Email" icon="far fa-envelope" name="email" />
            </div>
          </div>
        </RsForm>
      )}
    </Formik>
  );
}

export default ForgotPasswordForm;
