import { Option } from '../common/Option';

/**
 * All countries together with their cities are defined here
 *
 */
type CountryWithCitiesType = { [country: string]: string[] };
const countriesWithCities: CountryWithCitiesType = require('./countries-with-cities.json');

/**
 * Return all countries Options for select dropdowns to be used in forms
 */
export const CountriesOptions: Option[] = Object.keys(countriesWithCities).map(countryName => {
  return {
    label: countryName,
    value: countryName,
  };
});

/**
 * Return all countries Options for select dropdowns to be used in forms
 */
export const getCountryCities = (countryName: string): Option[] => {
  const cities = countriesWithCities[countryName] || [];

  return cities.map((cityName: string) => {
    return { label: cityName, value: cityName };
  });
};
