import { InferType, object, string } from 'yup';
import { defineApi } from '../common/api-definition';
import { AgreementType } from './AgreementType';

export const parseAgreementRequestValidation = object({
  url: string().required(),
});

export type ParseAgreementRequest = InferType<typeof parseAgreementRequestValidation>;

export const parseAgreementApi = defineApi<{
  body: ParseAgreementRequest;
  response: AgreementType;
}>({
  url: 'document-assembly/parse-agreement',
  method: 'post',
});
