import { getStaticMedia } from '../../../../constants/Aws';
import './JourneyOfCreLoan.scss';

export default function JourneyOfCreLoan() {
  return (
    <div className="journey-of-cre-loan">
      <h4 className="has-text-centered headline-underlined">THE JOURNEY OF CRE LOAN DEALS</h4>

      <div className="container">
        <div className="columns is-centered">
          <div className="column is-12 journey-img-container">
            <img className="journey-img" src={getStaticMedia('/icons/journey-of-cre-loan.png')} />
          </div>
        </div>
      </div>
    </div>
  );
}
