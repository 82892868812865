import { RsCognitoUser } from '@realstocks/types';
import { toString } from 'lodash';
import { getAndPopulateCurrentUser } from '../api/get-and-populate-current-user';

/**
 * After login the user session time is saved as custom attributes.
 * This is used for example in portfolio page to show last login (before the current one);
 *
 * @param currentUser
 */
export function registerUserSession(currentUser: RsCognitoUser) {
  return new Promise((resolve, reject) => {
    const attributes = [
      {
        Name: 'custom:currentSession',
        Value: toString(new Date().getTime()),
      },
    ];

    if (currentUser.attributes['custom:currentSession']) {
      attributes.push({
        Name: 'custom:lastSession',
        Value: currentUser.attributes['custom:currentSession'],
      });
    } else {
      attributes.push({
        Name: 'custom:lastSession',
        Value: toString(new Date().getTime()),
      });
    }

    currentUser.updateAttributes(attributes, async (err, result) => {
      try {
        if (err && result !== 'SUCCESS') {
          await getAndPopulateCurrentUser();
          reject(err);
        } else {
          resolve(result);
        }
      } catch (error) {}
    });
  });
}
