import { isString } from 'lodash';
import { AwsErrorResponse, RsError, ServerErrorResponse } from '../../../constants/ServerError';

/**
 * Each form after fields validation is complete and sends request can encounter more errors
 * from server. This function sets a generic status format for the form that's
 * interpreted by each component automatically. It sets an error message above the submit button.
 *
 * @param error
 */
export function createErrorStatus(error: AwsErrorResponse | ServerErrorResponse | any) {
  let errorMessage = error?.data;

  if ((error as ServerErrorResponse)?.data && (error as ServerErrorResponse)?.data?.message) {
    if (error?.data.message.message) {
      errorMessage = error?.data.message.message;
    } else {
      errorMessage = error?.data.message;
    }
  }

  if ((error as AwsErrorResponse)?.message) {
    errorMessage = error?.message;
  }

  if (!errorMessage) {
    errorMessage = isString(error) ? error : 'Something went wrong';
  }

  return {
    serverError: new RsError(errorMessage).setStatus(error?.status ? error.status : 500),
  };
}
