export enum HistorySortingKey {
  userName = 'userName',
  companyName = 'companyName',
  loanName = 'loanName',
  executedAt = 'executedAt',
  dueAt = 'dueAt',
  loanLevel = 'loanLevel',
  type = 'type',
  description = 'description',
}
