import { RoutesAccessRightsMap, RsCognitoUser } from '@realstocks/types';
import { NavigateFunction } from 'react-router-dom';
import ClientPath from '../../../routes/ClientPath';
import { CompanyInformationType, CompanyModel, CompanyTypes, COMPANY_KYC_STATUSES } from './../../../types/Company';

type ParamType = {
  currentUser: RsCognitoUser | null;
  isCompanyFetched: boolean | null;
  hasCompanyCompleted: boolean | null;
  companyExists: boolean | null;
  companyInformation: CompanyInformationType | null;
  setLoading: (loading: string | boolean) => void;
  companyId?: string;
  navigate: NavigateFunction;
  company: CompanyModel | null;
  routesAccessRightsMap: RoutesAccessRightsMap;
};

/**
 * Handle login redirects.
 * This function is needed because it is used in 2 different login methods:
 *  - username & pass
 *  - bank eid
 *
 * @param props
 */
export async function successfulLoginRedirect(props: ParamType) {
  const {
    hasCompanyCompleted,
    company,
    setLoading,
    currentUser,
    isCompanyFetched,
    companyExists,
    companyInformation,
    companyId,
    navigate,
    routesAccessRightsMap,
  } = props;
  const canSeeAdministrationModule = routesAccessRightsMap?.administration?.loan?.read || false;
  if (currentUser && isCompanyFetched) {
    if (companyExists && companyId) {
      if (hasCompanyCompleted && company && company.kycStatus === COMPANY_KYC_STATUSES.APPROVED) {
        const path = canSeeAdministrationModule
          ? ClientPath.modules.slAdministration.index
          : ClientPath.account.profile;
        navigate(path, { replace: true });
      } else {
        if (companyInformation && companyInformation.type === CompanyTypes.agent) {
          navigate(ClientPath.account.profile, { replace: true });
        } else {
          navigate(ClientPath.company.view, { replace: true });
        }
      }
    } else if (!companyExists) {
      if (companyInformation && companyInformation.type === CompanyTypes.agent) {
        navigate(ClientPath.home, { replace: true });
      } else if (!companyInformation) {
        navigate(ClientPath.company.lookup, { replace: true });
      }
    }

    setLoading(false);
  }
}
