import { combineReducers } from 'redux';
import { AccessRightsReducer } from './redux-access-rights/access-rights-reducer';
import { AppReducer } from './redux-app/app-reducer';
import { AuthReducer } from './redux-auth/auth-reducer';
import { ChatReducer } from './redux-chat/chat-reducer';
import { CompanyReducer } from './redux-company/company-reducer';
import { LoanReducer } from './redux-loans/loan-reducer';
import { NotificationsReducer } from './redux-notifications/notifications-reducer';
import { OutboundNotificationReducer } from './redux-outbound-notifications/outbound-notifications';

/**
 * Combine all reducers from within the application modules.
 */
export const AppCombinedReducers = combineReducers({
  application: AppReducer,
  auth: AuthReducer,
  company: CompanyReducer,
  chat: ChatReducer,
  loans: LoanReducer,
  notifications: NotificationsReducer,
  accessRights: AccessRightsReducer,
  outboundNotifications: OutboundNotificationReducer,
});

export type ReduxAction = any;
