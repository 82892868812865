import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import RsSecondaryNavigation from '../../layout/rs-secondary-navigation/RsSecondaryNavigation';
import ClientPath from '../../routes/ClientPath';
import { RsApplicationLinks } from '../../routes/RsApplicationLinks';
import '../home/HomePage.scss';
import HowItWorksHeader from '../how-it-works/components/how-it-works-header/HowItWorksHeader';
import { RealstocksServiceAdministration } from './components/administration/RealstocksServiceAdministration';
import { RealstocksServiceDepositAlternative } from './components/deposit-alternatives/RealstocksServiceDepositAlternative';
import { RealstocksServiceNegotiation } from './components/negotiation/RealstocksServiceNegotiation';
import { RealstocksServiceTransfers } from './components/transfers/RealstocksServiceTransfers';
import './RealstocksServices.scss';

export function RealstocksServices() {
  const location = useLocation();
  const realstocksServicesLinks = RsApplicationLinks().realstocksServices({
    marketplace: true,
    negotiation: true,
    administration: true,
    transfers: true,
    depositAlternative: true,
  });

  useEffect(() => {
    document.querySelector('html')?.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="realstocks-services-page page-home">
      <HowItWorksHeader />

      <RsSecondaryNavigation
        links={[
          realstocksServicesLinks.sublinks.negotiation,
          realstocksServicesLinks.sublinks.administration,
          realstocksServicesLinks.sublinks.transfers,
          realstocksServicesLinks.sublinks.depositAlternative,
        ]}
        sticky
      />

      <Routes>
        <Route index element={<Navigate to={ClientPath.static.realstocksServices.negotiation} replace />} />
        <Route path={'negotiation'} element={<RealstocksServiceNegotiation />} />
        <Route path={'administration'} element={<RealstocksServiceAdministration />} />
        <Route path={'transfers'} element={<RealstocksServiceTransfers />} />
        <Route path={'deposit-alternative'} element={<RealstocksServiceDepositAlternative />} />
      </Routes>
    </div>
  );
}
