export type ExitStatusType =
  | 'decline'
  | 'signing_complete'
  | 'cancel'
  | 'access_code_failed'
  | 'exception'
  | 'fax_pending'
  | 'id_check_failed'
  | 'session_timeout'
  | 'ttl_expired'
  | 'viewing_complete';

/*
  Value returned by the redirect from DocuSign (or the AWS lambda that
  processes the redirect from DocuSign).

  Descriptions are taken from here:
  https://stackoverflow.com/questions/43550130/unable-to-find-signing-ceremony-events-in-docusign-esign-model
*/
export const AllExitStatusTypes: Record<ExitStatusType, string> = {
  access_code_failed: 'Recipient used incorrect access code.',
  cancel: 'Recipient canceled the signing operation, possibly by using the Finish Later option.',
  decline: 'Recipient declined to sign.',
  exception: 'A system error occurred during signing.',
  fax_pending: 'Recipient has a fax pending.',
  id_check_failed: 'Recipient failed an ID check.',
  session_timeout: 'The session timed out.',
  signing_complete: 'Recipient completed the signing ceremony.',
  ttl_expired: 'The Time To Live token for the envelope has expired.',
  viewing_complete:
    'Recipient completed viewing an envelope that is in a read-only/terminal state such as completed, declined, or voided.',
};
