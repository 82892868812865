import { TNotification } from '../../types/notifications';
import {
  CertificateNotificationItem,
  GenericNotificationItem,
  LifecycleNotificationItem,
  NegotiationNotificationItem,
} from './NotificationItemTypes';

export const renderNotificationItem = (notification: TNotification, closeDropdown: Function) => {
  switch (notification.eventType) {
    case 'GENERIC':
      return <GenericNotificationItem key={notification.id} notification={notification} />;
    case 'NEGOTIATION':
      return <NegotiationNotificationItem key={notification.id} notification={notification} />;
    case 'LIFECYCLE':
      return <LifecycleNotificationItem key={notification.id} notification={notification} />;
    case 'COMPLIANCE_CERTIFICATE':
      return <CertificateNotificationItem key={notification.id} notification={notification} />;
    default:
      return <GenericNotificationItem key={notification.id} notification={notification} />;
  }
};
