import { UserCompanyType } from '../../types/UserTypes';
import { RsApiEndpoints } from './../../constants/RsApiEndpoints';
import { RsApi } from './../api/RsApi';

export async function getAndPopulateUserCompanies() {
  try {
    const companiesResponse: { message: UserCompanyType[] } = await RsApi.get({
      path: RsApiEndpoints.user.getCompanies,
    });

    return companiesResponse.message;
  } catch (error) {
    throw error;
  }
}
