import { FunctionComponent, memo, NamedExoticComponent } from 'react';

type M = <P extends object>(
  name: string,
  Component: FunctionComponent<P>,
  propsAreEqual?: (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean
) => NamedExoticComponent<P>;

export const nMemo: M = (name, c, compare) => {
  const m = memo(c, compare);
  m.displayName = name;
  return m;
};
