import { CompanyInterface } from '../company/company.interface';
import { CompanyPublicType } from '../company/CompanyPublicType';

export const getBasicCompanyInformation = (company?: CompanyPublicType): CompanyInterface | undefined => {
  if (!company) {
    return;
  }

  return {
    id: company.id,
    name: company.companyInformation.companyName,
    type: company.companyInformation.type,
  };
};
