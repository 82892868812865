import { getStaticMedia } from '../../../constants/Aws';
import RsInfo from '../rs-info/RsInfo';
import './RsList.scss';
import { RsListItem } from './RsListItem';

type Props = {
  items: RsListItem[];
  checkmarked?: boolean;
  sublistCheckmarked?: boolean;
};

export default function RsList(props: Props) {
  return (
    <ul
      className={`rs-list checkmarked-list`}
      style={{ ['--bg-url' as string]: `url(${getStaticMedia('/icons/bullet-point.svg')})` }}
    >
      {props.items.map((item: RsListItem) => (
        <li
          key={item.text}
          className="list-none pl-[25px] before:content-[''] before:absolute before:left-0 before:top-[6px] before:bg-no-repeat before:w-[10px] before:h-[10px] before:[background-image:_var(--bg-url)]"
        >
          <div className="rs-list-item-text-container">
            {item.info ? (
              <RsInfo id={item.text} type="info">
                <p>{item.info}</p>
              </RsInfo>
            ) : (
              ''
            )}
            <p>
              <span>{item.prepended}</span>
              <span className={`${item.isBold ? 'has-text-weight-bold' : ''}`}>{item.text}</span>
              <span>{item.appended}</span>
            </p>
          </div>

          {item.items && (
            <ul
              className="rs-list-sublist"
              style={{ ['--bg-url' as string]: `url(${getStaticMedia('/icons/secondary-bullet-point.svg')})` }}
            >
              {item.items.map((subItem: RsListItem) => (
                <li
                  key={subItem.text}
                  className="list-none pl-[18px] before:content-[''] before:absolute before:left-0 before:top-[8px] before:bg-no-repeat before:w-[6px] before:h-[6px] before:[background-image:_var(--bg-url)]"
                >
                  <p>
                    <span>{subItem.prepended}</span>
                    <span className={`${subItem.isBold ? 'has-text-weight-bold' : ''}`}>{subItem.text}</span>
                    <span>{subItem.appended}</span>
                  </p>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}
