/*
  Utilities for QuarterType
*/
import { addDays } from 'date-fns';
import { QuarterType } from '../loan/QuarterType';

// Return the quarter that a date belongs to.
export function dateToQuarter(date: Date): QuarterType {
  const month = date.getMonth(); // Months are numbered 0-11.
  return {
    year: date.getFullYear(),
    quarter: month < 3 ? 0 : month < 6 ? 1 : month < 9 ? 2 : 3,
  };
}

// The first date of the quarter.
export function quarterStartDate(quarter: QuarterType): Date {
  return addDays(quarterEndDate(previousQuarter(quarter)), 1);
}

// The last date of the quarter.
export function quarterEndDate(quarter: QuarterType): Date {
  return new Date(Date.UTC(quarter.year, (quarter.quarter + 1) * 3, 0));
}

// Convert a quarter like { year: 2020, quarter: 2 } to its string representation "Q3 2020".
export function stringifyQuarter(quarter: QuarterType): string {
  return ['Q', quarter.quarter + 1, ' ' + quarter.year].join('');
}

/*
  The quarter that is n quarters from the given quarter.

  n can be positive or negative.
*/
function addQuarters(start: QuarterType, n: number): QuarterType {
  const { year, quarter } = start;

  const quarterIndex = 4 * year + quarter + n;
  return {
    year: Math.floor(quarterIndex / 4),
    quarter: quarterIndex % 4,
  };
}

function previousQuarter(quarter: QuarterType): QuarterType {
  return addQuarters(quarter, -1);
}
