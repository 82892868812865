import onlyUnauthenticated from '../../../components/auth-hoc/only-unauthenticated';
import UserForm from '../../../components/forms/user-form/UserForm';
import './SignupPage.scss';

function SignupPage() {
  return (
    <div className="signup-page">
      <div className="container">
        <div className="headline">
          <div className="columns is-centered is-multiline">
            <div className="column is-12">
              <h1 className="has-text-centered">Create an account</h1>
              <p className="has-text-centered">
                After creating your account, you can choose to login with an email & password or your BankID.
              </p>
              <p className="has-text-centered">Using BankID is mandatory for Nordic clients.</p>
            </div>
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-narrow section is-two-thirds-desktop is-half-fullhd is-four-fifths">
            <UserForm />
            <div className="columns is-multiline is-centered mt-1">
              <div className="column is-12">
                <div className="has-text-centered">Already have an account?</div>
              </div>
              <div className="column is-6 is-flex justify-content-center is-flex-direction-column">
                <a className="button is-text" href="/login">
                  Sign in
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default onlyUnauthenticated(SignupPage);
