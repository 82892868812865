import Auth from '@aws-amplify/auth';
import { RsCognitoUser } from '@realstocks/types';
import { batchActions } from 'redux-batched-actions';
import { getInactiveTimeAndNow } from '../../components/inactivity-manager/InactivityManager';
import { AuthActions } from '../../redux/redux-auth/auth-actions';
import store from '../../store';
import { getAndPopulateUserCompanies } from '../aws/user';
import { AccessRightsActions } from './../../redux/redux-access-rights/access-rights-actions';
import { AppActions } from './../../redux/redux-app/app-actions';
import { CompanyActions, setCompanyModel } from './../../redux/redux-company/company-actions';
import { getAndPopulateRoutesAccessRights } from './access-rights-routes';
import { getAndPopulateCompanyDetails } from './get-company-details';

/**
 * Gets uncached current user instance from aws-amplify
 * and updates redux store.
 *
 * Used on initializing the app, updating user information etc
 */
export async function getAndPopulateCurrentUser(bypassCache: boolean = true): Promise<RsCognitoUser | undefined> {
  try {
    const currentUser: RsCognitoUser = await Auth.currentAuthenticatedUser({ bypassCache });
    const { inactiveTime, now } = getInactiveTimeAndNow();
    if ((!inactiveTime || now >= inactiveTime) && currentUser) {
      await Auth.signOut();

      window.location.reload();
      return;
    }

    const actions: { type: string; payload: any }[] = [
      AuthActions.setCurrentUser(currentUser),
      AppActions.setInitialized(true),
    ];

    const [userCompanies] = await Promise.all([
      getAndPopulateUserCompanies(),
      Promise.all([getAndPopulateCompanyDetails(), getAndPopulateRoutesAccessRights()]).catch(() => {
        actions.push(setCompanyModel(null));
        actions.push(AccessRightsActions.setRoutesMap({}));
      }),
    ]);

    actions.push(CompanyActions.setCompanyFetched(true));
    actions.push(AuthActions.setUserCompaniesList(userCompanies));

    store.dispatch(batchActions([...actions]));

    return currentUser;
  } catch (error) {
    store.dispatch(
      batchActions([AuthActions.setCurrentUser(null), setCompanyModel(null), AppActions.setInitialized(true)])
    );

    throw error;
  }
}
