import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopComponent() {
  const { pathname } = useLocation();
  const inModuleContainerScroll = document.querySelector('.module-page');

  useEffect(() => {
    window.scrollTo(0, 0);

    if (inModuleContainerScroll) {
      setTimeout(() => {
        inModuleContainerScroll.scrollTo(0, 0);
      });
    }
  }, [pathname, inModuleContainerScroll]);

  return null;
}

export default ScrollToTopComponent;
