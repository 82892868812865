import {
  ExpandableListBottomPanelType,
  ExpandableListComponent,
} from '../../../../components/expandable-list/ExpandableListComponent';
import { RsListItem } from '../../../../components/rs-elements/rs-list/RsListItem';
import './KeyBenefitsComponent.scss';

export default function KeyBenefitsAlternatives() {
  const investorBenefits: RsListItem[] = [
    new RsListItem('New secure asset class'),
    new RsListItem('Liquid alternative to traditional commercial real estate loans'),
    new RsListItem('Once listed, highly liquid alternative to deposits'),
    new RsListItem('Higher yields vs traditional deposits/secured commercialpapers'),
    new RsListItem('Floating rate product with automatic hedge against interest rate moves'),
    new RsListItem('Standardized product with little/no own DD needed *'),
  ];

  const investorKeyCharacteristics = [
    'Investments only available for institutional investors',
    'Initially standalone safe CRE loan with low liquidity',
    'When high liquidity proven -> deposit alternative (money-market instrument)',
    'Initial solvency II capital requirement at 10%, but should fall closer to 0% when liquidity proven',
  ];

  const borrowerBenefits: RsListItem[] = [
    new RsListItem('New alternative debt financing source'),
    new RsListItem('Lower prices for loans (arranging and margin)'),
    new RsListItem('LTV > vs. e.g. bank loan'),
    new RsListItem('Faster processing/funding'),
    new RsListItem('More standardized terms/documentation'),
  ];

  const borrowerKeyRequirements = [
    'EU super core commercial real estate loans',
    'LTV 40-60%',
    'Annual margin approx. 50-200bp',
    '100% asset security + all SPV cash flows pledged',
    'Small number of tenants and long-term lease agreements',
    'No construction risk, income-producing assets only',
  ];

  const footerNote = `* DD = Due Diligence conducted by Realstocks and credit assessment based on valuation of the loan security by a third party provider (Property Valuator)`;

  return (
    <div className="rs-section ls-container-grey key-benefits-component">
      <div className="pls-container-padding">
        <div className="columns is-centered is-multiline">
          <div className="column is-half-desktop is-full">
            <div className="columns is-centered is-vcentered is-multiline">
              <h4 className="has-text-weight-bold is-uppercase has-text-centered headline-underlined">For Investors</h4>
              <ExpandableListComponent
                type={ExpandableListBottomPanelType}
                topPanelList={investorBenefits}
                bottomPanelList={investorKeyCharacteristics}
                footerNote={footerNote}
                title={`Key Benefits`}
                expandText={`KEY CHARACTERISTICS`}
                collapseText={`KEY CHARACTERISTICS`}
              />
            </div>
          </div>
          <div className="column is-half-desktop is-full">
            <div className="columns is-centered is-vcentered is-multiline">
              <h4 className="has-text-weight-bold is-uppercase has-text-centered headline-underlined">For Issuers</h4>
              <ExpandableListComponent
                type={ExpandableListBottomPanelType}
                topPanelList={borrowerBenefits}
                bottomPanelList={borrowerKeyRequirements}
                title={`Key Benefits`}
                expandText={`KEY REQUIREMENTS`}
                collapseText={`KEY REQUIREMENTS`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
