import { defineApi } from '../common/api-definition';
import { StructuredLoanState } from './structured-loan-state';

export type LoanStructure = {
  version: number;
  state: StructuredLoanState;
  structure: FacilityStructure[];
  loan_metrics: {
    id: string;
    name: string;
  }[];
  information_undertakings: {
    id: string;
    name: string;
  }[];
  reporting_events: {
    id: string;
    name: string;
  }[];
};

export type FacilityStructure = {
  id: string;
  name: string;
  properties: {
    id: string;
    name: string;
  }[];
  sequences: SequenceStructure[];
};

export type SequenceStructure = {
  id: string;
  name: string;
  utilisations?: { id: string }[];
  construction?: { id: string };
  tranches: { id: string }[];
};

export type LoanStructureRequest = {
  loanId: string;
};

export const loanStructureApi = defineApi<{
  body: LoanStructureRequest;
  response: LoanStructure;
}>({
  url: 'structured-loans/loan-structure-view',
  method: 'post',
});
