import Auth from '@aws-amplify/auth';
import { RsCognitoUser } from '@realstocks/types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Params, useLocation, useParams } from 'react-router-dom';
import * as lottieAnimationFile from '../../../assets/lottie/secured.json';
import CloseBtn from '../../../components/close-btn/CloseBtn';
import LottieComponent from '../../../components/lottie/LottieComponent';
import { AuthenticationTypes } from '../../../constants/Aws';
import { RsApiEndpoints } from '../../../constants/RsApiEndpoints';
import { RsError } from '../../../constants/ServerError';
import { setLoading } from '../../../redux/redux-app/app-actions';
import { getLoading, LoadingStateType } from '../../../redux/redux-app/app-selectors';
import { getCurrentUserOrNull } from '../../../redux/redux-auth/auth-selectors';
import ClientPath from '../../../routes/ClientPath';
import { BankEidService } from '../../../services/api/BankEidService';
import { getAndPopulateCurrentUser } from '../../../services/api/get-and-populate-current-user';
import { RsApi, writeInactiveTimeCookie } from '../../../services/api/RsApi';
import { RsApiConfig } from '../../../services/api/RsApiConfig';
import { RsApiParams } from '../../../services/api/RsApiParams';
import { registerUserSession } from '../../../services/aws/register-user-session';
import { AppState } from '../../../store';
import { BankEidConfirmationTypes } from '../../../types/BankEidConfirmationTypes';
import './BankEidConfirmationPage.scss';

type Props = ReduxStateProps & ReduxDispatchProps & {};

interface QueryParams extends Params {
  type: string;
}

function BankEidConfirmationPage(props: Props) {
  const location = useLocation();
  const { type } = useParams() as QueryParams;

  const [everythingLoaded, setEverythingLoaded] = useState<boolean>(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<RsError | null>(null);
  const confirmationType = type;
  const [transactionId, setTransactionId] = useState<string | null>(
    new URLSearchParams(window.location.search).get('transaction_id')
  );

  const [successResponse] = useState(new URLSearchParams(window.location.search).get('success'));

  useEffect(() => {
    if (!props.loading && !everythingLoaded) {
      setEverythingLoaded(true);
    }
  }, [props.loading]);

  useEffect(() => {
    if (everythingLoaded) {
      if (
        !transactionId ||
        transactionId === '' ||
        !successResponse ||
        successResponse === '' ||
        successResponse !== 'true' ||
        !confirmationType ||
        (confirmationType !== BankEidConfirmationTypes.link && confirmationType !== BankEidConfirmationTypes.login)
      ) {
        props.setLoading(false);

        setError(new RsError('Something went wrong... Please try again.'));
      } else {
        if (confirmationType === BankEidConfirmationTypes.link) {
          props.setLoading('Linking Bank eID with your account...');
          if (props.currentUser && props.currentUser.attributes['custom:bankid']) {
            setSuccess('Your account is already secured by your Bank eID.');
            props.setLoading(false);
          } else {
            linkBankEidWithYourAccount();
          }
        } else if (confirmationType === BankEidConfirmationTypes.login) {
          props.setLoading('Logging you in...');
          loginUserWithBankeid();
        }
      }
    }
  }, [transactionId, successResponse, everythingLoaded]);

  useEffect(() => {
    setTransactionId(new URLSearchParams(window.location.search).get('transaction_id'));
  }, [location.pathname]);

  const loginUserWithBankeid = async () => {
    try {
      const userEmailEncoded = new URLSearchParams(window.location.search).get('email');

      const userEmail = decodeURIComponent(userEmailEncoded ? userEmailEncoded : '');

      if (!userEmailEncoded || !userEmail || userEmail === '') {
        props.setLoading(false);
        return setError(
          new RsError(`We couldn't identify your email. Please try again by specifying your email address.`)
        );
      }

      Auth.configure({ authenticationFlowType: AuthenticationTypes.custom });

      const loginResponse = await BankEidService.login(transactionId, userEmail);
      const result = await Auth.signIn(loginResponse.message.email);

      await Auth.sendCustomChallengeAnswer(result, loginResponse.message.challenge);
      try {
        writeInactiveTimeCookie();

        const currentUser = await getAndPopulateCurrentUser(false);

        if (currentUser) await registerUserSession(currentUser);

        setSuccess('You are now logged in!');
      } catch (error) {
        setError(new RsError('Something went wrong.'));
      } finally {
        props.setLoading(false);
      }
    } catch (err) {
      const error: any = err;
      setError(
        new RsError(
          error && error.data && error.data.message ? error.data.message : 'Session might have expired, try again.'
        )
      );
      props.setLoading(false);
    }
  };

  const linkBankEidWithYourAccount = async () => {
    try {
      const response = await RsApi.post(
        new RsApiParams(
          RsApiEndpoints.user.linkBankEidToAccount,
          new RsApiConfig().setBody({
            transactionId: transactionId,
          })
        )
      );
      writeInactiveTimeCookie();

      await getAndPopulateCurrentUser();

      setSuccess(response.message);
      props.setLoading(false);
    } catch (err) {
      props.setLoading(false);
      const error: any = err;

      setError(
        error && error.data && error.data.message
          ? new RsError(error.data.message)
          : new RsError('Something went wrong... Please try again.')
      );
    }
  };

  return (
    <div className="bank-eid-confirmation-page">
      <div className="container">
        {error && (
          <div className="error-container">
            <p>{error.message}</p>
          </div>
        )}

        {!error && success && (
          <div className="success-container">
            <LottieComponent
              options={{
                loop: false,
              }}
              animation={lottieAnimationFile}
            />
            <p>{success}</p>
          </div>
        )}

        <div className="columns is-centered">
          {(error || success) && (
            <CloseBtn
              callback={() => {
                if (window.opener === null) {
                  window.history.replaceState(null, 'null', ClientPath.auth.login);
                  window.location.href = ClientPath.auth.login;
                } else {
                  window.close();
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export const BankEidConfirmationUrlParams = {
  type: ':type',
};

type ReduxStateProps = {
  currentUser: RsCognitoUser | null;
  loading: LoadingStateType;
};

type ReduxDispatchProps = {
  setLoading: Function;
};

const mapStateToProps = (state: AppState): ReduxStateProps => {
  return {
    currentUser: getCurrentUserOrNull(state),
    loading: getLoading(state),
  };
};

const mapDispatchToProps: ReduxDispatchProps = {
  setLoading: setLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankEidConfirmationPage);
