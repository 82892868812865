import React from 'react';

type Props = {
  close: (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  loading?: boolean;
  children?: any;
};

function RsModalHeaderComponent(props: Props) {
  return (
    <header className="modal-card-head">
      <button
        type="button"
        onClick={props.close}
        className="modal-close is-large"
        aria-label="close"
        disabled={props.loading}
      ></button>
      <div className="columns is-multiline is-vcentered is-mobile">
        <div className="column is-12">{props.children}</div>
      </div>
    </header>
  );
}

export default RsModalHeaderComponent;
