export type ValueOf<T> = T[keyof T];

/**
 * Make a Sum type partial except its constructor tag "type"
 */
export type PartialSum<T> = Simplify<
  T extends { type: infer K }
    ? {
        [P in Exclude<keyof T, 'type'>]?: T[P];
      } & { type: K }
    : T
>;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

/**
 * Make a Sum type partial deeply
 */
export type DeepPartialSum<T> = Simplify<
  T extends { type: infer K }
    ? {
        [P in Exclude<keyof T, 'type'>]?: DeepPartialSum<T[P]>;
      } & { type: K }
    : T extends object
    ? {
        [P in keyof T]?: DeepPartialSum<T[P]>;
      }
    : T extends Array<infer T2>
    ? Array<DeepPartialSum<T2>>
    : T
>;

export type TypesAreEqual<A, B> = [A] extends [B]
  ? [B] extends [A]
    ? true
    : { diff: Exclude<B, A>; absurd: never }
  : { diff: Exclude<A, B>; absurd: never };

/**
 * Assert that two types are equal. If not, a compile error occurs.
 *
 * Usage:
 * ```
 * type A = {a: int};
 * type B = {a: int};
 * assertTypesAreEqual<A, B>(true);
 * ```
 * @param proof just pass true, and it all works
 */
export function assertTypesAreEqual<A, B>(proof: TypesAreEqual<A, B>): void {}

/**
 * A small utility used to force Typescript to simplify a computed type.
 */
export type Simplify<T> = T extends unknown ? { [K in keyof T]: T[K] } : never;

/**
 * All keys of a union type
 */
export type AllKeys<T> = T extends unknown ? keyof T : never;

/**
 * A function to check that a type is what you expect, or to help with
 * constructing objects of a specific type.
 *
 * ## Examples
 *
 * Ensure that all cases are covered in a switch statement:
 * ```
 * const a:"a"|"b" = "a";
 * switch(a) {
 *   case "a": return true;
 *   case "b": return false;
 *   default: isType<never>(a); // Typescript will error if `a` is extended to more types.
 * }
 * ```
 *
 * Help with constructing types:
 * ```
 * type Input = {
 *   valueA: number;
 *   someOtherVeryLongName: string;
 * }
 *
 * const input = isType<Input>({
 *   valueA: 123; // Code completion in here.
 * });
 * ```
 */
export const isType = <T>(type: T): T => type;

/**
 * Typesafe variant of `Object.entries`. Also returns the types for the keys.
 */
export const entries = <T extends Record<string | number | symbol, unknown>>(o: T): [keyof T, T[keyof T]][] =>
  Object.entries(o) as [keyof T, T[keyof T]][];

export const keys = <T extends Record<string, unknown>>(o: T): (keyof T)[] => Object.keys(o);

export const defined = <T>(v: T | null | undefined): v is T => v != null;
export const notFalse = <T>(v: T | false): v is T => v !== false;

export const isNever = (_absurd: never) => {};
