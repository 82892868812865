import { ValueOf } from '../utils/type-utils';

export const RoleValues = {
  USER: 'user',
  ASSISTANT: 'assistant',
} as const;

export type Role = ValueOf<typeof RoleValues>;

export interface Message {
  role: Role;
  content: string;
}
