import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUserOrNull } from '../../../redux/redux-auth/auth-selectors';
import ClientPath from '../../../routes/ClientPath';
import VerifyAccountForm from './components/verify-account-form/VerifyAccountForm';
import './VerifyAccountPage.scss';

const VerifyAccountPage = () => {
  const currentUser = useSelector(getCurrentUserOrNull);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as any;

  useEffect(() => {
    if (currentUser) {
      navigate(ClientPath.home, { replace: true });
    } else {
      if (state && state.p) {
        let newState: any = { ...state };
        delete newState.p;
        // TO CHECK
        navigate(
          {
            pathname: location.pathname,
            search: location.search,
            hash: location.hash,
          },
          { state: newState, replace: true }
        );
      }
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      // this.props.history.replace(ClientPath.auth.linkBankId); // ? uncomment this when we revert to bank eid being required
      navigate(ClientPath.account.index, { replace: true }); // ? delete this when we revert to bank eid being required
    }
  }, [currentUser]);

  const [prefilledEmail] = useState(new URLSearchParams(window.location.search).get('email'));

  const successCallback = () => {};

  const email = decodeURIComponent(prefilledEmail ? prefilledEmail : '');

  return (
    <>
      <div className="verify-account-page">
        <div className="container">
          <div className="columns is-centered verify-account-form-container">
            <div className="column is-narrow box section">
              <div className="form-headline">
                <h1 className="title">Verify Your Account</h1>

                <p className="has-text-centered">You will be able to add your Bank eID after Account Verification.</p>

                <div className="column is-centered">
                  <VerifyAccountForm p={state ? state.p : null} successCallback={successCallback} email={email} />

                  <Link
                    className="button is-text resend-code"
                    to={`${ClientPath.auth.resendVerificationCode}?email=${encodeURIComponent(email)}`}
                  >
                    Resend Verification Code
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccountPage;
