import { Fragment, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RsNavigationLink } from '../classes/RsNavigationLink';

type Props = {
  link: RsNavigationLink;
  exact?: boolean;
  hideOnDisallow?: boolean;
  className?: string;
  key?: string;
};

export default function RsNavLink(props: Props) {
  const [customIsActive, setCustomIsActive] = useState<boolean>(false);
  const location = useLocation();
  const { hideOnDisallow, link } = props;

  useEffect(() => {
    setCustomIsActive(
      props.link.options.customActiveCondition !== undefined
        ? props.link.options.customActiveCondition
        : Boolean(location.pathname.indexOf(props.link.path.replace('?', '')) >= 0)
    );
  }, [location.pathname, props.link.options.customActiveCondition]);

  return (
    <Fragment>
      {props.link.path && (hideOnDisallow || props.link.allow) && (
        <NavLink
          className={({ isActive }) =>
            (isActive || customIsActive) && link.allow
              ? `navbar-item ${props.className || ''} ${
                  (isActive || customIsActive) && !props.link.noActive ? 'is-active' : ''
                }`
              : `navbar-item ${props.className || ''}`
          }
          style={{ opacity: link.allow ? 1 : 0.5 }}
          to={{
            pathname: link.allow ? props.link.path : location.pathname,
            search: props.link.search || '',
          }}
          state={props.link.state || {}}
          onClick={() => {
            if (link.allow) {
              if (props.link.callback) {
                props.link.callback();
              }
            }
          }}
        >
          {props.link.fa ? (
            <div className="icon-container is-flex is-justify-content-center is-align-items-center is-flex-direction-row">
              <i className={`fas fa-${props.link.fa}`}></i>
            </div>
          ) : (
            ''
          )}
          {props.link.label}
        </NavLink>
      )}

      {props.link.path === '' && (
        <a
          className={`navbar-item ${props.className || ''} ${
            customIsActive && !props.link.noActive ? 'is-active' : ''
          }`}
          onClick={() => {
            if (props.link.callback) {
              props.link.callback();
            }
          }}
        >
          {props.link.label}
        </a>
      )}
    </Fragment>
  );
}
