import RsList from '../../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../../components/rs-elements/rs-list/RsListItem';
import { getStaticMedia } from '../../../../../constants/Aws';

export default function AdministrationServiceDocuments() {
  const items: RsListItem[] = [
    new RsListItem('Centralized access to events, results & documents'),
    new RsListItem('Overview of outstanding testing events'),
    new RsListItem('Assign, sign and file compliance certificates digitally'),
    new RsListItem('Find latest values, documents and compliance certificates for each testing event in one place'),
    new RsListItem('The platform pre-fills & calculates available figures automatically'),
  ];
  return (
    <div className="section">
      <h4 className="has-text-centered headline-underlined">PERIODICAL DOCUMENTS & COMPLIANCE CERTIFICATES</h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
          <div className="column is-6">
            <div className="img-container">
              <img src={getStaticMedia('/images/static-pages/administration_2.png')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
