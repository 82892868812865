import { AmortisationType } from '../loan/AmortisationType';
import { InterestRateType } from '../loan/InterestRateType';
import { SubventionType } from '../loan/SubventionType';
import { SLInterestRateType } from '../structured-loan/InterestRateType';
import { StructuredTranchesType } from '../structured-loan/structured-agreement-type';
import { precisePercentageToFraction } from './decimal-places';
import { parseFloatFinite } from './formatters';
import { optApp } from './optApp';

export function getSLSimulationAmortisation(
  tranch: StructuredTranchesType,
  amortisation_schedule?: Record<number, number>
): Partial<AmortisationType> | undefined {
  switch (tranch.amortisation_type) {
    case 'bullet':
      return undefined;
    case 'per-annum':
    case 'per-period': {
      return {
        type: tranch.amortisation_type === 'per-annum' ? 'period' : 'constant-period',
        rate: optApp(parseFloatFinite(tranch.amortisation_percentage), precisePercentageToFraction),
        period: tranch.amortisation_frequency,
        'first-date': tranch.first_amortisation_date,
        base: tranch.amortisation_base,
      };
    }
    case 'custom': {
      return {
        type: 'custom',
        schedule: amortisation_schedule ?? {},
      };
    }
  }
  return undefined;
}

export function loanToSLLifecycleInterestRate(
  interest_rate?: SLInterestRateType,
  interest_rate_schedule?: Record<number, number | undefined>
): Partial<InterestRateType> {
  switch (interest_rate?.type) {
    case 'fixed': {
      return {
        type: 'fixed',
        rate: precisePercentageToFraction(interest_rate.rate),
      };
    }
    case 'floating': {
      return {
        type: 'floating',
        'base-rate-name': interest_rate['base-rate-name'] || undefined,
        'base-rate-floored': interest_rate['base-rate-floored'] || false,
        'base-rate-floor': optApp(interest_rate['base-rate-floor'], precisePercentageToFraction) || undefined,
        'base-rate-ceiling': optApp(interest_rate['base-rate-ceiling'], precisePercentageToFraction) || undefined,
        margin: precisePercentageToFraction(interest_rate.margin),
        'quotation-days': interest_rate['quotation-days'],
      };
    }
    case 'custom': {
      return {
        type: 'custom',
        schedule: interest_rate_schedule ? percentageToFractionForSchedule(interest_rate_schedule) : {},
      };
    }
  }
  return {};
}

export function loanToSLLifecycleSubvention(
  threshold: number | undefined,
  coverage?: Record<number, number | undefined>
): Partial<SubventionType> | undefined {
  if (!threshold) return undefined;
  return {
    threshold: precisePercentageToFraction(threshold),
    coverage: coverage ? percentageToFractionForSchedule(coverage) : {},
  };
}

function percentageToFractionForSchedule(schedule: Record<number, number | undefined>): Record<number, number> {
  const result: Record<number, number> = {};
  for (const [key, value] of Object.entries(schedule)) {
    const fraction = optApp(value, precisePercentageToFraction);
    if (fraction && fraction > 0) {
      result[+key] = fraction;
    }
  }
  return result;
}
