import ColumnServicesComponent, {
  IColumnService,
} from '../../../../components/column-services/ColumnServicesComponent';
import './AlternativeDepositsServices.scss';

export default function AlternativeDepositsServices() {
  const services: IColumnService[] = [
    {
      icon: '/icons/commercial-loan.png',
      html: (
        <p className="has-text-centered">
          We use a 100% asset backed EU super core
          <span className="has-text-weight-bold"> commercial real estate loan</span>
        </p>
      ),
    },
    {
      icon: '/icons/fractionalize.png',
      iconClasses: 'fractionalize-icon',
      html: (
        <p className="has-text-centered">
          <span className="has-text-weight-bold">Fractionalize</span> the loan and offer it in a digital form as
          <span className="has-text-weight-bold"> loan tokens</span> on our marketplace
        </p>
      ),
    },
    {
      icon: '/icons/monthly-interest.png',
      html: (
        <p className="has-text-centered">
          Pay a <span className="has-text-weight-bold">monthly interest</span> to current token holders until the
          <span className="has-text-weight-bold"> payback of the principal</span>
        </p>
      ),
    },
    {
      icon: '/icons/static-pages/deposit-alternative.svg',
      html: (
        <p className="has-text-centered">
          List the Loan Tokens on <span className="has-text-weight-bold">established exchanges</span> to provide
          <span className="has-text-weight-bold"> daily liquidity</span>
        </p>
      ),
    },
  ];

  return (
    <div className="alternative-deposits-services">
      <div className="container">
        <ColumnServicesComponent services={services} numberedSections={false} />
      </div>
    </div>
  );
}
