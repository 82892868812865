import { getStaticMedia } from '../../../../../constants/Aws';
import {
  HomepageServiceBlock,
  HomepageServiceSquareComponent,
} from '../../../../home/components/home-page-services/HomepageServiceBlock';

export default function TransfersServiceHeader() {
  return (
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6">
            <div className="container-center">
              <HomepageServiceBlock
                service={
                  new HomepageServiceSquareComponent(
                    'Transfers',
                    getStaticMedia('/icons/static-pages/administration.svg'),
                    '',
                    '',
                    true
                  )
                }
              />
            </div>
          </div>
          <div className="column is-6">
            <h4 className="has-text-centered headline-underlined">LOAN TRANSFERS & SYNDICATION</h4>
            <p className="has-text-weight-bold has-text-centered">
              Improved liquidity & shorter holding periods by allowing to transfer your loan directly on the platform.
            </p>
            <p className="has-text-weight-bold has-text-centered">Opening a new way to syndicate loans.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
