import { defineApi } from '../common/api-definition';
import { SLBookingModel } from '../loan/BookingModelType';
import { StructuredLoanState } from './structured-loan-state';

export type StartBookingModelView = {
  loanId: string;
  facilityId: string;
  version: number;
  state: StructuredLoanState;

  // True if subvention has been configured for any tranche within the facility.
  hasSubvention: boolean;

  // True if the accounts should include the agent fee account.
  hasAgentFeeAccount: boolean;

  bookingModel?: SLBookingModel;
};

export type StartBookingModelViewRequest = {
  loanId: string;
  facilityId: string;
};

export const startBookingModelViewApi = defineApi<{
  body: StartBookingModelViewRequest;
  response: StartBookingModelView;
}>({
  url: 'structured-loans/start-booking-model-view',
  method: 'post',
});
