import './AboutUsPage.scss';
import AboutUsPageHeader from './components/about-us-page-header/AboutUsPageHeader';
import TeamMembersList from './components/team-members-list/TeamMembersList';
import team from './static-data/team.json';

export default function AboutUsPage() {
  return (
    <div className="about-us-page static-page">
      <AboutUsPageHeader />

      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <p>
              Realstocks was founded in October 2018 in Helsinki, Finland and has a subsidiary in Zurich, Switzerland.
              In 2024, Deon Digital AG acquired the Realstocks platform. Please find more information about Deon Digital
              at{' '}
              <a className="[font-size:_inherit]" href="https://deondigital.com">
                deondigital.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <h4 className="has-text-centered headline-underlined">Team</h4>
      </div>
      {<TeamMembersList members={team} />}
    </div>
  );
}
