import { InferType, string } from 'yup';
import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown } from '../validation/atoms';
import { ExternalLoanMissingInRealstocks } from './external-loans-missing-in-realstocks';
import { RealstocksLoanMissingExternally } from './realstocks-loans-missing-externally';

/**
 * The names of the fields of a loan in the external system, in human-readable form.
 */
export type ExternalNamesMap = { externalLoanId: string; externalName: string; currency: string };

export type GetLoansForReconciliationResult = {
  externalLoansMissingInRealstocks: ExternalLoanMissingInRealstocks[];
  realstocksLoansMissingExternally: RealstocksLoanMissingExternally[];
  externalNamesMap: ExternalNamesMap;
};

const ignoreRecordSchema = ObjectStripUnknown({
  externalLoanId: string().required(),
});
const reconsiderRecordSchema = ObjectStripUnknown({
  externalLoanId: string().required(),
});

export const loanReconciliationApi = {
  getLoansForReconciliation: defineApi<{ response: GetLoansForReconciliationResult }>({
    url: 'loan-reconciliation/get-loans',
    method: 'get',
  }),
  ignoreRecord: defineApi<{ body: InferType<typeof ignoreRecordSchema> }>({
    url: 'loan-reconciliation/ignore',
    method: 'post',
  }),
  ignoreRecordSchema,
  reconsiderRecord: defineApi<{ body: InferType<typeof reconsiderRecordSchema> }>({
    url: 'loan-reconciliation/reconsider',
    method: 'post',
  }),
  reconsiderRecordSchema,
};
