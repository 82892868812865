import {
  OutboundNotificationAction,
  OutboundNotificationEvent,
  OutboundNotificationState,
} from '../outbound-notification/outbound-notifications.type';
import { defined } from '../utils/type-utils';

const eventStateMap: Record<OutboundNotificationEvent['type'], OutboundNotificationState> = {
  create: OutboundNotificationState.new,
  release: OutboundNotificationState.released,
  delete: OutboundNotificationState.deleted,
  hold: OutboundNotificationState.held,
  send: OutboundNotificationState.sending,
  sending: OutboundNotificationState.sending,
  'send-confirmation': OutboundNotificationState.sent,
  'send-error': OutboundNotificationState.error,
};

const stateToAction: Partial<Record<OutboundNotificationState, OutboundNotificationAction>> = {
  [OutboundNotificationState.held]: 'hold',
  [OutboundNotificationState.released]: 'release',
  [OutboundNotificationState.deleted]: 'delete',
};

const allowedTransitions: Record<OutboundNotificationState, readonly OutboundNotificationState[]> = {
  [OutboundNotificationState.new]: [
    OutboundNotificationState.released,
    OutboundNotificationState.deleted,
    OutboundNotificationState.held,
  ],
  [OutboundNotificationState.held]: [OutboundNotificationState.released, OutboundNotificationState.deleted],
  [OutboundNotificationState.released]: [
    OutboundNotificationState.held,
    OutboundNotificationState.sending,
    OutboundNotificationState.deleted,
  ],
  [OutboundNotificationState.sending]: [OutboundNotificationState.error, OutboundNotificationState.sent],
  [OutboundNotificationState.error]: [OutboundNotificationState.released, OutboundNotificationState.deleted],
  [OutboundNotificationState.sent]: [OutboundNotificationState.deleted],
  [OutboundNotificationState.deleted]: [],
};

const allowedActionsForState = Object.fromEntries(
  Object.values(OutboundNotificationState).map(state => [
    state,
    Object.values(allowedTransitions[state])
      .map(state => (state in stateToAction ? stateToAction[state] : undefined))
      .filter(defined),
  ])
) as unknown as Record<OutboundNotificationState, readonly OutboundNotificationAction[]>;

export const OutboundNotificationModel = {
  /**
   * Map event to state
   */
  eventStateMap,

  allowedActionsForState,

  /**
   * Check transition from one state to another and validate
   *
   * @param from
   * @param to
   */
  isAllowedTransition: (from: OutboundNotificationState, to: OutboundNotificationState): boolean =>
    allowedTransitions[from].includes(to),

  isAllowedAction: (action: OutboundNotificationAction, currentState: OutboundNotificationState): boolean =>
    allowedActionsForState[currentState].includes(action),
} as const;
