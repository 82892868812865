import { CSSProperties } from 'react';
import Skeleton from 'react-loading-skeleton';
import './RsLoading.scss';

type PropsT = {
  width?: string;
  height?: string;
  count?: number;
  center?: boolean;
  style?: CSSProperties;
};

export default function RsLoading({ style, width, height, count, center }: PropsT) {
  const css: CSSProperties = style || {};
  const containerStyle: CSSProperties = { width: '100%' };

  if (center) {
    containerStyle.display = 'flex';
    containerStyle.justifyContent = 'center';
    containerStyle.alignItems = 'center';
  }

  return (
    <div style={containerStyle} data-testid="loading">
      <Skeleton count={count || 1} height={height || '100%'} width={width || '100%'} style={css} />
    </div>
  );
}

export function RsLoadingMultipleRows() {
  return (
    <div className="columns is-multiline" data-testid="loading-multiple">
      <div className="column is-12">
        <RsLoading />
      </div>
      <div className="column is-12">
        <RsLoading />
      </div>
      <div className="column is-12">
        <RsLoading />
      </div>
      <div className="column is-12">
        <RsLoading />
      </div>
    </div>
  );
}
