import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomepageServiceBlock.scss';

export class HomepageServiceSquareComponent {
  constructor(
    public title: string,
    public icon: string,
    public description: ReactNode,
    public link: string,
    public active: boolean = false
  ) {}
}

export function HomepageServiceBlock({ service, id }: { service: HomepageServiceSquareComponent; id?: string }) {
  const navigate = useNavigate();
  return (
    <div
      className={`service-block-container  ${service.active ? 'active' : ''}`}
      onClick={() => (service.link ? navigate(service.link) : '')}
    >
      <div className="service-block-box" id={id}>
        <div className={`service-block`}>
          <p className="has-text-centered has-text-weight-bold">{service.title}</p>
          <div className="icon-placeholder">
            <img src={service.icon} />
          </div>
          {service.description && service.description}
        </div>
      </div>
    </div>
  );
}
