import { defineApi } from '../common/api-definition';
import { StructuredLoanState } from './structured-loan-state';

export type AgreementStructureView = {
  loanId: string;
  version: number;
  state: StructuredLoanState;
  facilities: {
    id: string;
    name: string;
    properties: {
      id: string;
      name: string;
    }[];
    sequences: {
      id: string;
      name: string;

      // This weird format is used for backwards compatibility with
      // SLStructureUpdateFacility. The empty construction object is set
      // if and only if the sequence is for a construction loan.
      construction?: {};
    }[];
  }[];

  // Ids of the facilities that have already been commited to the loan
  // structure. The facilities that are being prepared as part of an amendment
  // are not included in this set.
  existingFacilities: { [facilityId: string]: true };
};

export type AgreementStructureViewRequest = {
  loanId: string;
};

export const agreementStructureViewApi = defineApi<{
  body: AgreementStructureViewRequest;
  response: AgreementStructureView;
}>({
  url: 'structured-loans/agreement-structure-view',
  method: 'post',
});
