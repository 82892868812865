import { format } from 'date-fns';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { NotificationsActions } from '../../../redux/redux-notifications/notifications-actions';
import ClientPath from '../../../routes/ClientPath';
import { AppState } from '../../../store';
import { TNotification } from '../../../types/notifications';

type Props = {
  notification: TNotification;
  clearNotification: Function;
};

const LifecycleNotificationItem = ({ notification, clearNotification }: Props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClear = async () => {
    try {
      setLoading(true);
      await clearNotification(notification.id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const metadata = notification.metadata ? JSON.parse(notification.metadata) : {};

  let action: any = null;
  const hasAction = notification.hasAction;

  if (hasAction) {
    if (
      metadata['event/type'] === 'loan-to-value-covenant-checked' &&
      ['hard-breach', 'soft-breach'].indexOf(metadata['check-status']) >= 0
    ) {
      action = () => {
        navigate(ClientPath.modules.administration.loanAdministration.payments);
      };
    }
  }

  return (
    <div className="notification-item-wrapper">
      <p className="notification-item-title">{notification.title}</p>

      <p className="notification-item-message">{notification.message}</p>

      <div className="notification-item-footer">
        <p className="created-date">{format(new Date(Number(notification.createdAt)), 'yyyy-MM-dd HH:mm')}</p>
        {hasAction && Boolean(action) && (
          <button type="button" className="button is-success" disabled={loading} onClick={action}>
            Create Prepayment
          </button>
        )}

        {notification.status === 'unread' && (
          <button type="button" className="button is-primary" disabled={loading} onClick={handleClear}>
            Dismiss
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (_state: AppState) => {
  return {};
};

const mapDispatchToProps = {
  clearNotification: NotificationsActions.clearNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(LifecycleNotificationItem);
