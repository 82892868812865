import { RoutesAccessRightsMap } from '@realstocks/types';
import { RsApiEndpoints } from '../../constants/RsApiEndpoints';
import { AccessRightsActions } from '../../redux/redux-access-rights/access-rights-actions';
import store from '../../store';
import { RsApi } from './RsApi';

/**
 * Get the company details and populate redux
 */

export const getAndPopulateRoutesAccessRights = async () => {
  try {
    const map: RoutesAccessRightsMap = await RsApi.get({
      path: RsApiEndpoints.getRoutesAccessRights,
    });

    store.dispatch(AccessRightsActions.setRoutesMap(map));
    return map;
  } catch (err) {
    store.dispatch(AccessRightsActions.setRoutesMap({}));

    throw err;
  } finally {
    store.dispatch(AccessRightsActions.setFetched(true));
  }
};
