import { RsCognitoUser } from '@realstocks/types';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getStaticMedia } from '../../constants/Aws';
import { ImageConstants } from '../../constants/images';
import { getCurrentUserOrNull } from '../../redux/redux-auth/auth-selectors';
import { getCompanyInformationOrNull, getCompanyKycStatus } from '../../redux/redux-company/company-selectors';
import ClientPath from '../../routes/ClientPath';
import { AppState } from '../../store';
import { CompanyInformationType, CompanyKycStatusType, CompanyTypes, COMPANY_KYC_STATUSES } from '../../types/Company';
import './FooterComponent.scss';

type Props = {
  currentUser: RsCognitoUser | null;
  kycStatus: CompanyKycStatusType | null;
  companyInformation: CompanyInformationType | null;
  className?: string;
};

function FooterComponent(props: Props) {
  const { className } = props;
  const [termsVisible, setTermsVisible] = useState(false);
  const [linkToTermsOfService, setLinkToTermsOfService] = useState(ClientPath.static.tos);
  const location = useLocation();

  useEffect(() => {
    if ((!props.currentUser || !props.kycStatus || props.kycStatus !== COMPANY_KYC_STATUSES.APPROVED) && termsVisible) {
      setTermsVisible(false);
    } else if (
      props.currentUser &&
      props.kycStatus &&
      props.kycStatus === COMPANY_KYC_STATUSES.APPROVED &&
      !termsVisible
    ) {
      setTermsVisible(true);
    }
  }, [props.currentUser, props.kycStatus]);

  useEffect(() => {
    const newlinkToTermsOfService =
      props.companyInformation?.type === CompanyTypes.borrower
        ? ClientPath.static.borrowerTos
        : props.companyInformation?.type === CompanyTypes.lender
        ? ClientPath.static.lenderTos
        : ClientPath.static.tos;
    setLinkToTermsOfService(newlinkToTermsOfService);
  }, [props.companyInformation]);

  return (
    <Fragment>
      <div className="clear-both" />

      {location.pathname.indexOf('/app/') < 0 && (
        <div className={`footer ${className || ''}`}>
          <footer>
            <div className="container">
              <div className="columns is-multiline">
                <div className="column is-2-desktop is-4">
                  <div className="footer-co-container">
                    <img
                      src={getStaticMedia(`/${ImageConstants.header.logo}`)}
                      alt="Realstocks logo"
                      width="130px"
                      height="21px"
                    />
                    <div className="address">
                      <p>Deon Digital AG</p>
                      <p>Herostrasse 9</p>
                      <p>8048 Zurich</p>
                      <p>Switzerland</p>
                    </div>
                  </div>
                </div>
                <div className="column is-10-desktop is-8">
                  <div className="columns is-multiline is-centered is-mobile">
                    <div className="column is-12-mobile is-6-tablet is-3-desktop">
                      <div className="footer-column-container">
                        <div className="heading">Features</div>
                        <div>
                          <Link to={ClientPath.home}>Home</Link>
                        </div>
                        <div>
                          <Link to={ClientPath.static.realstocksServices.base}>Services</Link>
                        </div>
                      </div>
                    </div>
                    <div className="column is-12-mobile is-6-tablet is-3-desktop">
                      <div className="footer-column-container">
                        <div className="heading">Company</div>
                        <div>
                          <Link to={ClientPath.static.aboutUs}>About Us</Link>
                        </div>
                        <div>
                          <Link to={ClientPath.static.contact}>Contact</Link>
                        </div>
                      </div>
                    </div>
                    <div className="column is-12-mobile is-6-tablet is-3-desktop">
                      <div className="footer-column-container">
                        <div className="heading">Legal</div>
                        <div>
                          <Link
                            to={{
                              pathname: ClientPath.static.privacyPolicy,
                              search: 'newTab=false',
                            }}
                          >
                            Privacy Policy
                          </Link>
                        </div>
                        {termsVisible && (
                          <div>
                            <Link
                              to={{
                                pathname: linkToTermsOfService,
                                search: 'newTab=false',
                              }}
                            >
                              Terms of Service
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="column is-12-mobile is-6-tablet is-3-desktop">
                      <div className="footer-column-container">
                        <div className="heading">Connect</div>
                        <div>
                          <a href={'https://www.linkedin.com/company/realstocks'} target="_blank" rel="noreferrer">
                            LinkedIn
                          </a>
                        </div>
                        <div>
                          <a href={'https://twitter.com/real_stocks'} target="_blank" rel="noreferrer">
                            Twitter
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="separator "></div>
              <div className="copyright">
                Copyright © 2024 – {new Date().getFullYear()}. Deon Digital AG. All rights reserved.
              </div>
            </div>
          </footer>
        </div>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    currentUser: getCurrentUserOrNull(state),
    kycStatus: getCompanyKycStatus(state),
    companyInformation: getCompanyInformationOrNull(state),
  };
};

export default connect(mapStateToProps)(FooterComponent);
