import { orderBy } from 'lodash';
import {
  ConsequenceDefType,
  LoanMetricDraftType,
  LoanMetricType,
  SourceFormulaType,
  SourceOperandType,
} from '../covenants/LoanMetricType';
import { amountInNumber, formatAmount, formatFractionAsPercentage, percentageFormat } from './formatters';
import { matchString } from './strings';

type MetricFetcher = (metricId: string) => LoanMetricType | LoanMetricDraftType | undefined;

export function prettyFormula(formula: SourceFormulaType, getMetric: MetricFetcher) {
  const prettyOperand = (operand: SourceOperandType) => {
    switch (operand.type) {
      case 'constant':
        return operand.value;
      case 'metric':
        return getMetric(operand.value)?.name ?? operand.value;
    }
  };
  const prettyOperator = matchString(formula.operator, {
    add: () => '+',
    div: () => '/',
    mul: () => '*',
    sub: () => '-',
  });
  return `${prettyOperand(formula.lhs)} ${prettyOperator} ${prettyOperand(formula.rhs)}`;
}

export const formatCovenantString = (label?: string): string | undefined => {
  if (!label) return;
  const arr = label.split(/[_,-]/);
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
};

export const formatSLMetricValue = (
  value: boolean | number | string | undefined,
  metricUnit: LoanMetricType['unit'] | undefined,
  currency: string | undefined,
  isRawMetric: boolean,
  withPercentageSign = false
): string => {
  if (value === undefined) {
    return 'N/A';
  }

  if (metricUnit === 'boolean') {
    if (value === '0') return 'False';
    return value ? 'True' : 'False';
  }

  if (metricUnit === 'amount') {
    const amount = formatAmount(value.toString());
    return currency ? `${currency} ${amount}` : amount;
  }

  if (metricUnit === 'number') {
    return amountInNumber(value).toString();
  }

  if (metricUnit === 'percentage') {
    const percentageValue = isRawMetric
      ? percentageFormat(amountInNumber(value), { withPercentageSign })
      : formatFractionAsPercentage(amountInNumber(value), { withPercentageSign });
    return percentageValue;
  }

  return value.toString();
};

export const getSLLabelBeforeInputMetric = (
  metricUnit: LoanMetricType['unit'],
  currency: string
): string | undefined => {
  if (metricUnit === 'amount') {
    return currency;
  } else if (metricUnit === 'percentage') {
    return `% `;
  }
  return undefined;
};

function isNumeric(n: any): n is string | number {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * TODO: handle boolean consequences (no operators are provided so far)
 */
function interpConsequence(c: ConsequenceDefType, currentValue: string | number | boolean | undefined): boolean {
  const numberValue = Number(currentValue);
  const operand = Number(c.operand);
  if (!currentValue || !isNumeric(numberValue)) return false;
  return matchString(c.operator, {
    eq: () => numberValue === operand,
    gt: () => numberValue > operand,
    gte: () => numberValue >= operand,
    lt: () => numberValue < operand,
    lte: () => numberValue <= operand,
    neq: () => numberValue !== operand,
  });
}

export const getIndexOfMostSignificantBreach = (
  cs: ConsequenceDefType[],
  currentValue: string | number | undefined
) => {
  const sortedByConsequence = orderBy(
    cs.map((c, i) => [c, i] as const),
    c => matchString(c[0].consequence, { soft_breach: () => 1, hard_breach: () => 0 })
  );
  return sortedByConsequence.find(c => interpConsequence(c[0], currentValue))?.[1];
};
