import { Fragment } from 'react';
import Modal, { OnAfterOpenCallback } from 'react-modal';
import { Breakpoints } from '../../constants/Generic';
import LottieComponent from '../lottie/LottieComponent';
import './RsModalComponent.scss';

type Props = {
  children: any;
  className?: string;
  overlayClassName?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
  loading?: boolean;
  onAfterOpen?: OnAfterOpenCallback;
  onRequestClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

/**
 * Realstocks modal component
 *
 * Make sure to add the header, content and footer components as children if you want
 * to have the same styling for all modals
 */
function RsModalComponent(props: ReactModal.Props & Props) {
  return (
    <Fragment>
      {props.isOpen && (
        <Modal
          {...props}
          style={{
            overlay: {
              zIndex: 30,
              padding: '2rem',
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(51, 58, 63, .9)',
              minHeight: '100vh',
            },
            content: {
              height: props.height || 'auto',
              overflow: 'none',
              width: props.width || (window.innerWidth > Breakpoints.tablet ? '80%' : '90%'),
              position: 'relative',
              top: 0,
              left: 0,
              right: 0,
              maxWidth: props.maxWidth || '1024px',
              padding: '0',
              bottom: 0,
              border: 'none',
            },
          }}
        >
          <div className={`${props.className ? props.className : ''} rs-modal-container`}>
            <div className="modal-card"> {props.loading ? <LottieComponent /> : props.children}</div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
}

export default RsModalComponent;
