import { findLastKey } from 'lodash';
import { useEffect, useState } from 'react';
import RsCloseBtn from '../../../../components/rs-elements/rs-close-btn/RsCloseBtn';
import { Breakpoints } from '../../../../constants/Generic';
import { TeamMembersRow } from '../../classes/TeamMembersRow';
import TeamMember, { TeamMemberType } from '../team-member/TeamMember';
import './TeamMembersList.scss';

type Props = {
  members: TeamMemberType[];
};

type TeamMembersRows = {
  [rowNumber: string]: TeamMembersRow;
};

export default function TeamMembersList(props: Props) {
  const [membersRows, setMembersRows] = useState<TeamMembersRows>({});
  const [windowWidth, setWindowWidth] = useState<number | null>(null);

  useEffect(() => {
    if (windowWidth !== null) {
      const memberRows: TeamMembersRows = {};
      const membersPerRow = windowWidth < Breakpoints.desktop ? 2 : 3;

      props.members.forEach((member: TeamMemberType, idx: number) => {
        if (idx === 0) {
          if (!memberRows[idx]) memberRows[idx] = new TeamMembersRow();
          memberRows[idx].addMember(member);
        } else if (idx % membersPerRow === 0) {
          memberRows[idx / membersPerRow] = new TeamMembersRow().addMember(member);
        } else {
          const lastKey = findLastKey(memberRows);

          if (lastKey !== undefined) {
            memberRows[lastKey].addMember(member);
          }
        }
      });

      setMembersRows(memberRows);
    }
  }, [windowWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [window.innerWidth]);

  const handleResizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const toggleMember = (rowNumber: string, member?: TeamMemberType) => {
    const expanded = member ? !member.expanded : false;
    const adjustedMembersRows: TeamMembersRows = { ...membersRows };

    adjustedMembersRows[rowNumber].removeDescription();

    adjustedMembersRows[rowNumber].expanded = expanded;

    adjustedMembersRows[rowNumber].members.forEach((iteratedMember: TeamMemberType) => {
      if (member && member.name === iteratedMember.name) {
        iteratedMember.expanded = expanded;
        if (expanded) {
          adjustedMembersRows[rowNumber].setDescription(member.description);
        }
      } else {
        iteratedMember.expanded = false;
      }
    });

    setMembersRows(adjustedMembersRows);
  };

  return (
    <div className="team-members-list">
      {Object.keys(membersRows).map((teamMembersRowIndex: string) => {
        const teamMembersRow: TeamMembersRow = membersRows[teamMembersRowIndex];

        return (
          <div className="team-members-list-row" key={`team_member_row_${teamMembersRowIndex}_key`}>
            <div className="container">
              <div className="columns is-multiline is-mobile">
                {teamMembersRow.members.map((member: TeamMemberType) => (
                  <TeamMember
                    toggleMember={toggleMember}
                    row={teamMembersRowIndex}
                    key={`team_member_${member.name.replace(/ /g, '')}_key`}
                    member={member}
                  />
                ))}
              </div>
            </div>

            <div
              className={`team-member-description-block ${
                teamMembersRow.expanded ? 'row-description-visible' : 'row-description-hidden'
              }`}
            >
              <div className="container team-member-description-container">
                <div className="close-container">
                  <RsCloseBtn
                    className="collapse-row-description"
                    onClick={() => {
                      toggleMember(teamMembersRowIndex);
                    }}
                  />
                </div>
                <p
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: teamMembersRow.description || '',
                  }}
                ></p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
