import { matchString } from '../utils/strings';

export const loanMetricUnits = ['number', 'boolean', 'percentage', 'amount'] as const;
export type LoanMetricUnitType = (typeof loanMetricUnits)[number];

export const prettyLoanMetricUnit = (x: LoanMetricUnitType) =>
  matchString(x, {
    number: () => 'Number',
    boolean: () => 'Boolean',
    percentage: () => 'Percentage',
    amount: () => 'Amount',
  });

export const loanMetricClassTypes = ['income_statement', 'balance_sheet', 'valuation', 'operational', 'mixed'] as const;
export type LoanMetricClassType = (typeof loanMetricClassTypes)[number];

export const prettyLoanMetricClass = (x: LoanMetricClassType) =>
  matchString(x, {
    income_statement: () => 'Income Statement',
    balance_sheet: () => 'Balance Sheet',
    valuation: () => 'Valuation',
    operational: () => 'Operation',
    mixed: () => 'Mixed',
  });
type SourceValueType = string;

export const sourceOperators = ['div', 'mul', 'sub', 'add'] as const;
export type SourceOperatorType = (typeof sourceOperators)[number];

export function interpSourceOperator(op: SourceOperatorType): (lhs: number, rhs: number) => number {
  switch (op) {
    case 'add':
      return (lhs, rhs) => lhs + rhs;
    case 'div':
      return (lhs, rhs) => lhs / rhs;
    case 'mul':
      return (lhs, rhs) => lhs * rhs;
    case 'sub':
      return (lhs, rhs) => lhs - rhs;
  }
}

export const prettySourceOperator = (op: SourceOperatorType) =>
  matchString(op, {
    add: () => '+',
    sub: () => '-',
    mul: () => '*',
    div: () => '/',
  });

export const prettySourceOperatorDetailed = (op: SourceOperatorType) =>
  matchString(op, {
    add: () => 'plus (+)',
    sub: () => 'minus (-)',
    mul: () => 'multiplied by (*)',
    div: () => 'divided by (/)',
  });

export type SourceOperandMetricDraftType = { type: 'metric'; value?: string };

export type SourceOperandType = { type: 'constant'; value: SourceValueType } | { type: 'metric'; value: string };
export type SourceOperandDraftType = { type: 'constant'; value?: SourceValueType } | SourceOperandMetricDraftType;

export const sourceOperand = (type: 'constant' | 'metric', value: SourceValueType) => ({ type, value });

export type SourceFormulaType = {
  lhs: SourceOperandType;
  operator: SourceOperatorType;
  rhs: SourceOperandType;
};
export type SourceFormulaDraftType = {
  lhs?: SourceOperandDraftType;
  operator?: SourceOperatorType;
  rhs?: SourceOperandDraftType;
};

export type SourceType =
  | {
      type: 'raw'; // a raw value
    }
  | {
      type: 'formula'; // derived through a formula
      formula: SourceFormulaType;
    }
  | {
      type: 'calculated'; // from the loan calculation engine
    };

export type SourceDraftType =
  | {
      type: 'raw'; // a raw value
    }
  | {
      type: 'formula'; // derived through a formula
      formula?: SourceFormulaDraftType;
    }
  | {
      type: 'calculated'; // from the loan calculation engine
    };

export const loanMetricSourceTypes = ['raw', 'formula', 'calculated'] as const;

export const prettyMetricSourceType = (x: SourceType['type']) =>
  matchString(x, {
    raw: () => 'Raw',
    formula: () => 'Formula',
    calculated: () => 'Calculated',
  });

export const consequenceOperatorTypes = ['lt', 'lte', 'gt', 'gte', 'eq', 'neq'] as const;
export type ConsequenceOperatorType = (typeof consequenceOperatorTypes)[number];

export const consequenceTypes = ['soft_breach', 'hard_breach'] as const;
export type ConsequenceType = (typeof consequenceTypes)[number];

export const prettyConsequenceType = (op: ConsequenceType) =>
  matchString(op, {
    soft_breach: () => 'Soft Breach',
    hard_breach: () => 'Hard Breach',
  });

export const prettyConsequenceOperator = (op: ConsequenceOperatorType) =>
  matchString(op, {
    lt: () => 'less (<)',
    lte: () => 'less or equal (≤)',
    gt: () => 'greater (>)',
    gte: () => 'greater or equal (≥)',
    eq: () => 'equal (=)',
    neq: () => 'not equal (≠)',
  });

export type ConsequenceDefType = {
  operator: ConsequenceOperatorType;
  operand: SourceValueType;
  consequence: ConsequenceType;
};
export type ConsequenceDefDraftType = {
  operator?: ConsequenceOperatorType;
  operand?: SourceValueType;
  consequence?: ConsequenceType;
};

export const defaultConsequenceDef = (): ConsequenceDefType => ({
  operand: '',
  operator: 'lt',
  consequence: 'soft_breach',
});

export enum MetricPeriodEnum {
  SPAN_OF_TIME = 'sot',
  POINT_IN_TIME = 'pit',
}

export type LoanMetricPeriodType = { type: 'sot' } | { type: 'pit' };

export const loanMetricPeriodTypes = ['sot', 'pit'] as const;

export const prettyMetricPeriodType = (x: LoanMetricPeriodType['type']) =>
  matchString(x, {
    sot: () => 'Span of Time',
    pit: () => 'Point in Time',
  });

export const prettyMetricReportAgainst = (x?: ReportedAgainstType) => {
  if (!x) return 'N/A';
  return matchString(x, {
    legal_entity: () => 'Legal entity',
    property: () => 'Property',
  });
};

export const qualifications = ['audited_unaudited', 'none'] as const;
export type QualificationsType = (typeof qualifications)[number];

export const prettyQualifications = (x: QualificationsType) =>
  matchString(x, {
    none: () => 'N/A',
    audited_unaudited: () => 'Audited/Unaudited',
  });

export const reportedAgainstTypes = ['legal_entity', 'property'] as const;
export type ReportedAgainstType = (typeof reportedAgainstTypes)[number];

export const prettyReportedAgainst = (x: ReportedAgainstType) =>
  matchString(x, {
    legal_entity: () => 'Legal entity',
    property: () => 'Property',
  });

export const proofDocuments = ['none', 'financial_statement', 'valuation_report', 'certificate'] as const;
export type ProofDocumentType = (typeof proofDocuments)[number];

export const prettyProofDocument = (x: ProofDocumentType) =>
  matchString(x, {
    none: () => 'N/A',
    financial_statement: () => 'Financial Statement',
    valuation_report: () => 'Valuation Report',
    certificate: () => 'Certificate(s)',
  });

export const ProofDocumentLabels: Record<ProofDocumentType, string> = {
  none: 'None',
  financial_statement: 'Financial Statement',
  valuation_report: 'Valuation Report',
  certificate: 'Certificate(s)',
};

export type LoanMetricType = {
  id: string;
  name: string;
  description: string; // Plain text description

  // The definition of the metric as it appears in the contract. If a rich text
  // format is supported, we can reuse the editor used for the facility
  // agreement negotiation.
  definition: string;

  unit: LoanMetricUnitType;

  // Setup for how the values are sourced (raw vs. derived + formula vs.
  // provided by lifecycling)
  source: SourceType;

  // PIT vs. SOT + interpolation method
  period: LoanMetricPeriodType;

  // From speaking with Jorge I have understood that the Qualifications dropdown
  // has really only the choices empty or Audited/Unaudited. If an information
  // undertaking has no qualification but a metric has a qualification of
  // Audited/Unaudited, then this metric cannot be used with the information
  // undertaking. I don't know if it an error to have an information undertaking
  // with qualification 'audited' that uses a metric with no qualifications.
  qualifications: QualificationsType;

  class: LoanMetricClassType;

  proofDocument: ProofDocumentType;

  consequences: ConsequenceDefType[];
  ccBinding: string;

  /**
   * `undefined` means `other` or `N/A`.
   */
  reportedAgainst?: ReportedAgainstType;
};

// Draft version of the domain LoanMetric type
export type LoanMetricDraftType = {
  id: string;
  name: string;
  description?: string;
  definition?: string;
  unit?: LoanMetricUnitType;
  source?: SourceDraftType;
  period?: LoanMetricPeriodType;
  qualifications?: QualificationsType;
  class?: LoanMetricClassType;
  proofDocument?: ProofDocumentType;
  ccBinding?: string;
  reportedAgainst?: ReportedAgainstType;
  consequences?: ConsequenceDefDraftType[];
};

export type RawMetricType = Omit<LoanMetricType, 'source'> & { source: Extract<SourceType, { type: 'raw' }> };
export type DerivedMetricType = Omit<LoanMetricType, 'source'> & {
  source: Extract<SourceType, { type: 'formula'; formula: SourceFormulaType }>;
};

export const isRawMetric = (metric: LoanMetricType | LoanMetricDraftType): metric is RawMetricType => {
  return metric?.source?.type === 'raw';
};

export const isDerivedMetric = (metric: LoanMetricType): metric is DerivedMetricType => {
  return metric?.source?.type === 'formula';
};
