import { Formik, FormikHelpers } from 'formik';
import { Fragment, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import RsInput from '../../components/rs-form/rs-input/RsInput';
import RsSelect from '../../components/rs-form/rs-select/RsSelect';
import RsTextarea from '../../components/rs-form/rs-textarea/RsTextarea';
import RsForm from '../../components/rs-form/RsForm';
import { ProcessEnv } from '../../constants/ProcessEnv';
import { RsApiEndpoints } from '../../constants/RsApiEndpoints';
import { useSetLoading } from '../../effects/useSetLoading';
import { RsApi } from '../../services/api/RsApi';

type ContactFormFieldsType = {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  company: string;
  reason: string;
  comment: string;
};

function ContactPage() {
  const setLoading = useSetLoading();

  const email = 'info@realstocks.io';
  const captchaSiteKey = ProcessEnv.captcha.siteKey;
  const [contactForm, _setContactForm] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    reason: '',
    comment: '',
  });
  const [formValidationSchema, _setFormValidationSchema] = useState(
    Yup.object({
      name: Yup.string().required('First name is required.'),
      lastname: Yup.string().required('Last name is required.'),
      email: Yup.string().email('Invalid Email Address.').required('Email is required.'),
      phone: Yup.string(),
      company: Yup.string(),
      reason: Yup.string().required('Contact Reason is required.'),
      comment: Yup.string().required('Comment is required.'),
    })
  );

  let recaptchaValue: string = '';
  const onSubmit = async (values: ContactFormFieldsType, actions: FormikHelpers<ContactFormFieldsType>) => {
    try {
      setLoading(true);
      const contactFormFields: ContactFormFieldsType & { recaptcha?: string } = { ...values };
      if (recaptchaValue === '') throw Error('Recaptcha not solved');
      contactFormFields.recaptcha = recaptchaValue;

      await RsApi.axiosPost({
        path: `${ProcessEnv.aws.api.base}${RsApiEndpoints.contactForm}`,
        config: {
          body: contactFormFields,
        },
      });

      actions.resetForm();
      toast.success('Form was submitted successfully, thank you for contacting us!');
    } catch (error) {
      const er = error as any;
      if (er?.toLowerCase && er.toLowerCase().indexOf('recaptcha') > -1) {
        toast.error('Please solve the reCAPTCHA challenge.');
      } else {
        toast.error('There has been an error submitting the form, please refresh the page and try again');
      }
    } finally {
      setLoading(false);
    }
  };

  const onRecaptchaChange = (value: any) => {
    recaptchaValue = value;
  };

  return (
    <Fragment>
      <div className="input-page static-page">
        <div className="form-headline">
          <h1 className="title">Contact Us</h1>
          <p className="subtitle">
            Leave your details below and we are happy to contact you shortly. <br />
            Feel free to also contact us via email: <a href={`mailto:${email}`}>{email}</a>
          </p>
        </div>
        <Formik
          enableReinitialize={true}
          validationSchema={formValidationSchema}
          initialValues={contactForm}
          onSubmit={onSubmit}
        >
          {({ status }) => (
            <RsForm status={status} submitLabel="Send">
              <div className="columns is-centered is-multiline" id="contact-form">
                <div className="column is-12">
                  <RsInput type="text" label="First Name" icon="far fa-user" name="name" />
                </div>
                <div className="column is-12">
                  <RsInput type="text" label="Last Name" icon="far fa-user" name="lastname" />
                </div>
                <div className="column is-full">
                  <RsInput type="email" label="Email" icon="far fa-user" name="email" />
                </div>
                <div className="column is-full">
                  <RsInput type="text" label="Phone (optional)" icon="far fa-user" name="phone" />
                </div>
                <div className="column is-full">
                  <RsInput type="text" label="Company Name (optional)" icon="far fa-user" name="company" />
                </div>
                <div className="column is-full">
                  <RsSelect
                    data={[
                      { label: 'General', value: 'GENERAL' },
                      { label: 'Feedback', value: 'FEEDBACK' },
                      { label: 'Request Demo', value: 'REQUEST DEMO' },
                    ]}
                    label="Reason For Contact"
                    name="reason"
                  />
                </div>
                <div className="column is-full">
                  <RsTextarea type="text" label="Comment" name="comment" />
                </div>
                <div className="column is-6">
                  <ReCAPTCHA sitekey={`${captchaSiteKey}`} onChange={onRecaptchaChange} />
                </div>
              </div>
            </RsForm>
          )}
        </Formik>
      </div>
    </Fragment>
  );
}

export default ContactPage;
