import React, { Fragment } from 'react';
import onlyUnauthenticated from '../../../components/auth-hoc/only-unauthenticated';
import ForgotPasswordForm from './components/forgot-password-form/ForgotPasswordForm';
import ResetPasswordForm from './components/reset-password-form/ResetPasswordForm';
import './ResetPasswordPage.scss';

type Props = {};
type State = {
  autofilledEmail: string | null;
  forgotPassword: false | string;
};

class ResetPasswordPage extends React.Component<Props, State> {
  state: State = {
    autofilledEmail: new URLSearchParams(window.location.search).get('email'),
    forgotPassword: false,
  };

  /**
   * After the user forgot the password and verification code is sent, then
   * run this function that has its inserted email as a parameter
   */
  forgotPasswordCallback = (email: string) => {
    this.setState({ forgotPassword: email });
  };

  reset = () => {
    this.setState({ forgotPassword: false });
  };

  render() {
    const email = decodeURIComponent(this.state.autofilledEmail ? this.state.autofilledEmail : '');

    return (
      <Fragment>
        <div className="input-page">
          <div className="form-headline">
            <h1 className="title">Forgot Password</h1>
            <p className="subtitle">Please enter your email address</p>
          </div>
          <div className="columns is-centered">
            <div className="column is-12">
              {!this.state.forgotPassword && (
                <ForgotPasswordForm email={email} successCallback={this.forgotPasswordCallback} />
              )}
              {this.state.forgotPassword && <ResetPasswordForm email={this.state.forgotPassword} />}
            </div>
          </div>

          {this.state.forgotPassword && (
            <p className="button is-text" onClick={this.reset}>
              Forgot Password (get code)
            </p>
          )}
        </div>
      </Fragment>
    );
  }
}

export default onlyUnauthenticated(ResetPasswordPage);
