import { CompanyConfigurations } from '@realstocks/types';
import { produce } from 'immer';
import { CompanyModel } from '../../types/Company';
import { COMPANY_ACTIONS } from './company-actions';
/**
 * Error Reducer takes care of the global error messages inside the application.
 *
 * @param {*} state
 * @param {*} action
 */
export type ReduxCompanyType = { instance: CompanyModel | null; fetched: boolean };

export const CompanyReducer = (
  state: ReduxCompanyType = { instance: null, fetched: false },
  action: { type: string; payload: unknown }
) => {
  switch (action.type) {
    case COMPANY_ACTIONS.setFetched:
      return {
        ...state,
        fetched: true,
      };
    case COMPANY_ACTIONS.setCompany:
      return {
        ...state,
        instance: action.payload,
        fetched: true,
      };
    case COMPANY_ACTIONS.reset:
      return { instance: null, fetched: false };
    case COMPANY_ACTIONS.setCompanyConfigurations:
      return produce(state, (state) => {
        if (state.instance == null) {
          return;
        }
        state.instance.configurations = action.payload as CompanyConfigurations;
      });
    default:
      return state;
  }
};
