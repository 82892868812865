import { createSelector } from 'reselect';
import { ApplicationState, AppState } from '../../store';
/**
 * Get 'app' state.
 *
 * @param {Object} state
 */
const getApp = (state: AppState) => state.application;

export type LoadingStateType = boolean | string;

/**
 * Return true/false if the website content is loading or not.
 *
 * @param {*} state
 */
export const getLoading = createSelector(getApp, (appState: ApplicationState): LoadingStateType => appState.loading);

export const getInvisibleOverlay = createSelector(getApp, (appState: ApplicationState) => appState.invisibleOverlay);

/**
 * Return app initialization flag
 *
 * @param {*} state
 */
export const getAppInitialized = createSelector(getApp, (appState: ApplicationState) => appState.initialized);

/**
 * Return the app socket state.
 *
 * @param {*} state
 */
export const selectSocket = createSelector(getApp, (chatState: ApplicationState) => chatState.socketInstance);

export const getSyncTimestamp = createSelector(getApp, (appState: ApplicationState) => appState.syncTimestamp);
