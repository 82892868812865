import { format } from 'date-fns';
import { useState } from 'react';
import { connect } from 'react-redux';
import { NotificationsActions } from '../../../redux/redux-notifications/notifications-actions';
import { AppState } from '../../../store';
import { TNotification } from '../../../types/notifications';

type Props = {
  notification: TNotification;
  clearNotification: Function;
};

const GenericNotificationItem = ({ notification, clearNotification }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClear = async () => {
    try {
      setLoading(true);
      await clearNotification(notification.id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="notification-item-wrapper">
      <p className="notification-item-title">{notification.title}</p>

      <p className="notification-item-message">{notification.message}</p>

      <div className="notification-item-footer">
        <p className="created-date">{format(new Date(Number(notification.createdAt)), 'yyyy-MM-dd HH:mm')}</p>
        {notification.status === 'unread' && (
          <button type="button" className="button is-primary" disabled={loading} onClick={handleClear}>
            Dismiss
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (_state: AppState) => {
  return {};
};

const mapDispatchToProps = {
  clearNotification: NotificationsActions.clearNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericNotificationItem);
