export type AwsErrorResponse = {
  code: string;
  name: string;
  message: string;
};

export type ServerErrorResponse = {
  type?: string; // temporary until we standardize all lambda calls
  statusCode?: string; // this will be mandatory in the definite version
  data: {
    message: string;
  };
};

export class RsError extends Error {
  type: string;
  statusCode?: string;

  constructor(message: string) {
    super(message);
    this.type = 'client-error';
    this.message = message;
  }

  setStatus(status: string) {
    this.statusCode = status;

    return this;
  }
}
