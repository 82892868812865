import { RsCognitoUser } from '@realstocks/types';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCurrentUserOrNull } from '../../redux/redux-auth/auth-selectors';
import { AppState } from '../../store';

type Props = {
  currentUser: RsCognitoUser | null;
};

const UserSessionComponent = (props: Props) => {
  const [fullName, setFullName] = useState<string | null>(null);
  const [lastLoggedIn, setLastLoggedIn] = useState<string | null>(null);

  useEffect(() => {
    if (props.currentUser) {
      if (!fullName) {
        setFullName(
          props.currentUser.attributes['custom:firstName'] + ' ' + props.currentUser.attributes['custom:surname']
        );
      }

      const lastSession = props.currentUser.attributes['custom:lastSession'];
      if (!lastLoggedIn && lastSession) {
        setLastLoggedIn(format(new Date(parseInt(lastSession)), 'yyyy-MM-dd HH:mm'));
      }
    }
  }, [props.currentUser]);

  return (
    <div className="p-2">
      {lastLoggedIn && (
        <p className="rs-no-margin is-size-6 has-text-centered has-text-centered-mobile">
          Last Login: <time>{lastLoggedIn}</time>
        </p>
      )}
    </div>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state: AppState) => {
  return {
    currentUser: getCurrentUserOrNull(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSessionComponent);
