import { ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';
import './RsInfo.scss';

type Props = {
  id: string;
  type: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
  children: ReactNode;
  effect?: 'float' | 'solid';
  clickPath?: string;
};

export default function RsInfo(props: Props) {
  return (
    <span className={`rs-info ${props.type}`}>
      {props.clickPath ? (
        <a
          data-tip
          data-for={props.id}
          href={props.clickPath || ''}
          target={props.clickPath ? '_blank' : ''}
          rel="noreferrer"
        >
          <i className="fas fa-info-circle"></i>
        </a>
      ) : (
        <a data-tip data-for={props.id}>
          <i className="fas fa-info-circle"></i>
        </a>
      )}
      <span className={`rs-info ${props.type}`}>
        <ReactTooltip
          className="rs-info-tooltip"
          id={props.id}
          place="left"
          type={props.type}
          effect={props.effect ? props.effect : 'solid'}
        >
          {props.children}
        </ReactTooltip>
      </span>
    </span>
  );
}
