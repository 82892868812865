import RegisterNowComponent from '../../../../components/register-now/RegisterNowComponent';
import TransfersServiceBilateral from './components/TransfersServiceBilateral';
import TransfersServiceHeader from './components/TransfersServiceHeader';
import TransfersServiceSyndicated from './components/TransfersServiceSyndicated';
import TransfersServiceTransferability from './components/TransfersServiceTransferability';
import './RealstocksServiceTransfers.scss';

export function RealstocksServiceTransfers() {
  return (
    <div className="realstocks-service-transfers">
      <TransfersServiceHeader />
      <TransfersServiceTransferability />
      <TransfersServiceBilateral />
      <TransfersServiceSyndicated />
      <RegisterNowComponent />
    </div>
  );
}
