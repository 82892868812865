import RsImage from '../../../../components/rs-elements/rs-image/RsImage';
import { getStaticMedia } from '../../../../constants/Aws';
import './RealstocksHowItWorksComponent.scss';

function RealstocksHowItWorksComponent() {
  return (
    <div className="realstocks-how-it-works-component">
      <section className="rs-section pls-container-white">
        <div className="pls-container-padding">
          <div className="columns is-multiline is-centered is-vcentered">
            <div className="column is-full">
              <h4 className="has-text-centered headline-underlined">
                Combining high liquidity of deposits with the security and returns of fully <br /> asset backed super
                core commercial real estate loans.
              </h4>
            </div>

            <div className="column is-full">
              <RsImage
                style={{ maxWidth: '1000px' }}
                alt="Realstocks Solution"
                src={getStaticMedia('/pages/home/realstocks-solution.svg')}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RealstocksHowItWorksComponent;
