import { filter, findIndex } from 'lodash';
import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserSessionComponent from '../../../components/user-session/UserSessionComponent';
import { Breakpoints } from '../../../constants/Generic';
import { RsNavigationLink } from '../classes/RsNavigationLink';
import './RsNavLinkDropdown.scss';

export function useOutsideAlerter(ref: any, callback: (show: boolean) => void) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function RsNavLinkDropdown(props: { label: string | React.ReactNode | null; links: RsNavigationLink[] }) {
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const [dropdownLinkActive, setDropdownLinkActive] = React.useState<boolean>(false);
  const [links, setLinks] = React.useState<RsNavigationLink[]>([]);
  const [windowWidth, setWindowWidth] = React.useState<number | null>(null);
  const dropdownRef = React.useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useOutsideAlerter(dropdownRef, setShowDropdown);

  React.useEffect(() => {
    if (props.links && props.links.length) {
      setLinks(filter(props.links, { allow: true }));
    }
  }, [props.links]);

  React.useEffect(() => {
    if (links && links.length) {
      setDropdownLinkActive(
        Boolean(
          findIndex(links, (link) => link.path !== '' && location.pathname.indexOf(link.path.replace('?', '')) >= 0) >=
            0
        )
      );
    }
  }, [location.pathname, links]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [window.innerWidth]);

  const handleResizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <div
      ref={dropdownRef}
      className={`rs-nav-link-dropdown-component has-dropdown  ${
        showDropdown
          ? 'rs-expanded'
          : windowWidth !== null && windowWidth < Breakpoints.desktop
          ? 'rs-expanded'
          : 'rs-collapsed'
      }`}
    >
      {windowWidth !== null && windowWidth <= Breakpoints.tablet && <hr className="navbar-divider" />}

      <a
        className={`is-hidden-touch navbar-item dropdown-toggler ${
          dropdownLinkActive ? 'is-dropdown-link-active' : ''
        }`}
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
      >
        {props.label}
      </a>

      <div className={`navbar-dropdown is-boxed`}>
        {links.map((link: RsNavigationLink) => {
          return (
            <React.Fragment key={Math.random()}>
              {link.options.divider && <hr className="navbar-divider" />}
              <Link
                className={`navbar-item ${link.options.classes || ''} ${
                  link.options.customActiveCondition === true ||
                  (link.path !== '' && location.pathname.indexOf(link.path.replace('?', '')) >= 0)
                    ? 'is-active'
                    : ''
                }`}
                to={'#'}
                onClick={() => {
                  setTimeout(() => {
                    if (link.callback) {
                      link.callback();
                    } else {
                      navigate(link.path);
                    }
                  }, 0);

                  setShowDropdown(false);
                }}
              >
                {link.label}
              </Link>
            </React.Fragment>
          );
        })}
        <hr className="navbar-divider" />

        <UserSessionComponent />
      </div>
    </div>
  );
}

export default RsNavLinkDropdown;
