import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { matchStringExhaustive } from '../utils/strings';
import { ValueOf } from '../utils/type-utils';

export enum NotificationType {
  /**
   * A rate fixing notice is sent out when the base rate of a floating bond
   * is set for an upcoming payment, to inform borrowers (and potentially
   * lenders) what base rate their next payment will be based on.
   */
  RATE_FIXING_NOTICE = 'rate-fixing-notice',

  /**
   * A payment notice is sent out when a loan party needs to pay something in
   * relation to the loan. It can be sent using e-invoice or e-mail.
   */
  PAYMENT_NOTICE = 'payment-notice',

  /**
   * A covenant report is a document sent from a borrower to agents or
   * lenders at dates specified in the loan agreement to inform them of
   * the status of the loan. It includes data from the collection
   * events/information undertakings performed previously.
   */
  COVENANT_REPORT = 'covenant-report',
}

export interface PaymentNoticeIdentification {
  type: NotificationType.PAYMENT_NOTICE;
  loanId: string;
  bookingId: string;
}

export interface RateFixingNoticeIdentification {
  type: NotificationType.RATE_FIXING_NOTICE;
  loanId: string;
  bookingId: string;
}

export interface CovenantReportIdentification {
  type: NotificationType.COVENANT_REPORT;
  reportingEventId: string;
  // The reporting event id is not enough, as it uses the id from the configuration. So we also include the date.
  date: string;
}

export type OutboundNotificationIdentification =
  | CovenantReportIdentification
  | RateFixingNoticeIdentification
  | PaymentNoticeIdentification;

type SharedOutboundNotificationType = {
  pk: string;
  sk: string;
  senderCompanyId: string;
  identification: OutboundNotificationIdentification;
  /** Object ID is a serialization of the outbound notification identification, to be able to index them */
  objectId: string;
  loanId: string;
  trigger: OutboundNotificationTrigger;
  triggerTimestamp: string;
  transmissionType: OutboundNotificationTransmission;
  notificationBody?: OutboundNotificationBody;
  files: RsUploadedFileType[];
  version: number;
  createdAt: string;
  sequence_number: number;
  event: OutboundNotificationEvent;
  state: OutboundNotificationState;
};

export type BookingObjectInformationType = {
  amount: number;
  date: string;
};

export type BookingOutboundNotification = SharedOutboundNotificationType & {
  objectType: 'booking';
  documentNumber: number;
  objectInformation: BookingObjectInformationType;
  notificationType: NotificationType.PAYMENT_NOTICE | NotificationType.RATE_FIXING_NOTICE;
};

export type CovenantReportObjectInformation = {
  date: string;
};

export type CovenantReportOutboundNotification = SharedOutboundNotificationType & {
  objectType: 'covenant-report';
  objectInformation: CovenantReportObjectInformation;
  notificationType: NotificationType.COVENANT_REPORT;
};

export type OutboundNotificationType = BookingOutboundNotification | CovenantReportOutboundNotification;

type MkOutboundNotificationDTO<T extends OutboundNotificationType> = Pick<
  T,
  | 'trigger'
  | 'triggerTimestamp'
  | 'objectInformation'
  | 'notificationType'
  | 'transmissionType'
  | 'notificationBody'
  | 'files'
  | 'version'
  | 'createdAt'
  | 'event'
  | 'sequence_number'
  | 'state'
> & {
  id: string;
  loan: {
    id: string;
    loan_name: string;
  };
  senderCompany: {
    id: string;
    company_name: string;
  };
};

export type OutboundNotificationDTO =
  | MkOutboundNotificationDTO<BookingOutboundNotification>
  | MkOutboundNotificationDTO<CovenantReportOutboundNotification>;

export enum OutboundNotificationState {
  'new' = 'new',
  'sending' = 'sending',
  'released' = 'released',
  'sent' = 'sent',
  'error' = 'error',
  'deleted' = 'deleted',
  'held' = 'held',
}

export type OutboundNotificationStateQueryable = Exclude<OutboundNotificationState, 'deleted'>;

export type OutboundNotificationEvent =
  | { type: 'create' }
  | { type: 'release'; userId: string }
  | { type: 'send'; userId: string }
  | { type: 'sending' }
  | { type: 'delete'; userId: string }
  | { type: 'hold'; userId: string }
  | { type: 'send-confirmation'; filesSent: RsUploadedFileType[] }
  | { type: 'send-error'; message: string; filesSent: RsUploadedFileType[] };

export type EmailOutboundNotificationBody = {
  type: 'e-email';
  from_email: string;
  to_email: string;
  reply_to_email?: string;
  subject: string;
  cc: string[];
  body: string;
};

export type OutboundNotificationBody = EmailOutboundNotificationBody;

export const PaymentNoticeTransmission = ['emails', 'e-invoice', 'none'] as const;
export type PaymentNoticeTransmissionType = (typeof PaymentNoticeTransmission)[number];
export const EmailsOrNoneTransmission = ['emails', 'none'] as const;
export type EmailsOrNoneTransmissionType = (typeof EmailsOrNoneTransmission)[number];
export enum OutboundNotificationTransmission {
  'EMAIL' = 'e-email',
  'E-INVOICE' = 'e-invoice',
}

export const outboundNotificationTriggers = {
  'booking-with-variable-interest-rate-created': 'booking-with-variable-interest-rate-created',
  'value-date-within-20-days': 'value-date-within-20-days',
  'booking-amount-changed': 'booking-amount-changed',
  manual: 'manual',
} as const;

export type OutboundNotificationTrigger = ValueOf<typeof outboundNotificationTriggers>;

export const prettyOutboundNotificationTrigger = (x: OutboundNotificationTrigger) =>
  matchStringExhaustive(x, {
    'booking-amount-changed': () => 'Booking Amount Changed',
    'value-date-within-20-days': () => 'Value Date Within 20 Days',
    'booking-with-variable-interest-rate-created': () => 'Booking With Variable Interest Rate Created',
    manual: () => 'Manual',
  });

export const prettyOutboundNotificationType = (x: OutboundNotificationType['notificationType']) =>
  matchStringExhaustive(x, {
    [NotificationType.PAYMENT_NOTICE]: () => 'Payment Notice',
    [NotificationType.RATE_FIXING_NOTICE]: () => 'Rate Fixing Notice',
    [NotificationType.COVENANT_REPORT]: () => 'Covenant Report',
  });

export const prettyOutboundNotificationState = (x: OutboundNotificationState) =>
  matchStringExhaustive(x, {
    new: () => 'New',
    released: () => 'Released',
    sending: () => 'Sending',
    sent: () => 'Sent',
    error: () => 'Error',
    deleted: () => 'Deleted',
    held: () => 'Held',
  });

export const outboundNotificationActions = {
  delete: 'delete',
  hold: 'hold',
  release: 'release',
} as const;

export type OutboundNotificationAction = (typeof outboundNotificationActions)[keyof typeof outboundNotificationActions];

type MkOutboundNotificationCreatePayload<T extends OutboundNotificationType> = Omit<
  T,
  'pk' | 'sk' | 'version' | 'state' | 'event' | 'createdAt' | 'triggerTimestamp' | 'sequence_number' | 'objectId'
>;

export type OutboundNotificationCreatePayload =
  | MkOutboundNotificationCreatePayload<BookingOutboundNotification>
  | MkOutboundNotificationCreatePayload<CovenantReportOutboundNotification>;

type MkOutboundNotificationUpdatePayload<T extends OutboundNotificationType> = Pick<T, 'pk' | 'version' | 'event'>;

export type OutboundNotificationUpdatePayload =
  | MkOutboundNotificationUpdatePayload<BookingOutboundNotification>
  | MkOutboundNotificationUpdatePayload<CovenantReportOutboundNotification>;
