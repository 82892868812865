import Auth from '@aws-amplify/auth';
import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import RsInput from '../../../../../components/rs-form/rs-input/RsInput';
import RsForm from '../../../../../components/rs-form/RsForm';
import { createErrorStatus } from '../../../../../components/rs-form/utils/create-error-status';

type ResendVerificationFieldsType = {
  email: string;
};

type Props = {
  email: string | null;
  successCallback: Function;
};

export default function ResendVerificationForm(props: Props) {
  const [formSchema] = useState({
    email: Yup.string().email('Invalid email address.').required('Email is required.'),
  });

  const onSubmit = async (
    values: ResendVerificationFieldsType,
    actions: FormikHelpers<ResendVerificationFieldsType>
  ) => {
    try {
      await Auth.resendSignUp(values.email);
      props.successCallback(values.email);
    } catch (error) {
      actions.setStatus(createErrorStatus(error));
    }
  };

  return (
    <Formik validationSchema={Yup.object(formSchema)} initialValues={{ email: props.email || '' }} onSubmit={onSubmit}>
      {({ status }) => (
        <RsForm status={status} className="resend-verification-code-form" submitLabel="Resend Verification Code">
          <RsInput type="email" label="Email" icon="far fa-envelope" name="email" />
        </RsForm>
      )}
    </Formik>
  );
}
