import { numberFormatter } from '@realstocks/types';
import './FormatMoney.scss';

type Props = {
  inputValue: string | number | null | undefined;
  currency?: string | null;
  totalCurrency?: boolean;
  alternateValue?: string;
  bold?: boolean;
};

const FormatMoney = (props: Props) => {
  const { inputValue, currency, totalCurrency, alternateValue, bold } = props;

  const currencyToAppend = currency ? (
    <span className={`${totalCurrency ? 'total-currency' : 'currency'}`}>{currency}</span>
  ) : (
    <></>
  );

  const getOutputValue = () => {
    let intermediateValue = 0;
    if (!inputValue && inputValue !== 0) {
      return alternateValue || '';
    } else if (typeof inputValue === 'string') {
      intermediateValue = parseFloat(inputValue);
    } else {
      intermediateValue = inputValue;
    }
    return numberFormatter.float.format(intermediateValue);
  };

  return (
    <span className={`formatmoney-wrapper ${bold ? 'has-text-weight-bold' : ''}`}>
      {(inputValue || inputValue === 0) && currencyToAppend}
      {getOutputValue()}
    </span>
  );
};

export default FormatMoney;
