export const EidBankCodes = [
  {
    label: 'Sweden',
    code: 'seBankID',
  },
  {
    label: 'Finland',
    code: 'fiTupas',
  },
  {
    label: 'Denmark',
    code: 'dkNemID',
  },
  {
    label: 'Norway',
    code: 'noBankID',
  },
];

export const EidBankCodesObj = {
  finland: 'seBankID',
  sweden: 'fiTupas',
  denmark: 'dkNemID',
  norway: 'noBankID',
};

export type BankEidCodeType = 'seBankID' | 'fiTupas' | 'dkNemID' | 'noBankID';
