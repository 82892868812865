import { produce } from 'immer';
import { BuiltinMetricId, builtinMetricIds } from '../covenants/BuiltinMetricType';
import {
  InformationUndertakingQualificationType,
  InformationUndertakingScheduleDraftType,
  InformationUndertakingSpecDraftType,
  QualificationLabels,
} from '../covenants/InformationUndertakingSpecType';
import { IUReportedAgainstDraftType } from '../covenants/IUReportedAgainstType';
import { LoanMetricDraftType, SourceOperandMetricDraftType } from '../covenants/LoanMetricType';
import {
  SLReportingEventCollectionConfigDraft,
  SLReportingEventConfigDraft,
} from '../covenants/ReportingEventConfigType';
import { groupByTypesafe } from './grouping';
import { optApp } from './optApp';

interface Context {
  uuid: () => string;
}

/**
 * Intermediate datatype to capture all the things that an information undertaking needs
 */
interface IUParams {
  reportingEvent: SLReportingEventConfigDraft;
  collection: SLReportingEventCollectionConfigDraft;
  metric: LoanMetricDraftType;
  qualification: undefined | InformationUndertakingQualificationType;
}

/**
 * Derive information undertakings from the loan reporting events and metrics.
 *
 * Remark that this is not yet safe to call during amends, and should not be used there, since
 * the lifecycling expects exactly the same information undertakings to be returned.
 */
export const deriveInformationUndertakings = (
  reportingEvents: SLReportingEventConfigDraft[],
  metrics: LoanMetricDraftType[],
  context: Context
): InformationUndertakingSpecDraftType[] => {
  const allIuParams = reportingEvents
    .flatMap(re =>
      (re.collections ?? []).map(
        col => [re, col] as [SLReportingEventConfigDraft, SLReportingEventCollectionConfigDraft]
      )
    )
    .flatMap(
      ([reportingEvent, collection]) =>
        optApp(
          metrics.find(m => collection.metricId === m.id),
          metric => metricToInformationUndertakingParams(reportingEvent, collection, metric, metrics)
        ) ?? []
    );

  const groupedParams = Object.values(
    groupByTypesafe(
      allIuParams.map(p => ({ key: iuParamsGroupingKey(p), value: p })),
      'key'
    )
  ).map(row => row.flatMap(kvp => kvp.value));

  return groupedParams.map(iuParams =>
    paramsToInformationUndertaking(
      iuParams[0],
      iuParams.map(p => p.metric),
      context
    )
  );
};

/**
 * Transitively get the raw metrics of a metric.
 *
 * Ignores incomplete metrics - we can't derive information undertakings from these.
 * Ignores metrics calculated by the LCE - they don't need to be collected.
 */
const metricToInformationUndertakingParams = (
  reportingEvent: SLReportingEventConfigDraft,
  collection: SLReportingEventCollectionConfigDraft,
  metric: LoanMetricDraftType | undefined,
  allMetrics: LoanMetricDraftType[]
): IUParams[] => {
  if (metric?.source == null) {
    // draft
    return [];
  }

  if (metric.source.type === 'raw') {
    return [
      {
        reportingEvent,
        collection,
        metric,
        qualification: metric.qualifications === 'none' ? undefined : collection.qualification,
      },
    ];
  }

  if (metric.source.type === 'calculated') {
    // LCE calculated
    return [];
  }

  const formulaSides = [metric.source.formula?.lhs, metric.source.formula?.rhs];

  const formulaMetrics = formulaSides
    .filter(
      (side): side is SourceOperandMetricDraftType =>
        // ignore constants and draft formulas
        side?.type === 'metric'
    )
    .map(side => side.value)
    .filter(
      (metricId): metricId is string =>
        // ignore drafts and calculated metrics
        metricId != null && !builtinMetricIds.includes(metricId as BuiltinMetricId)
    );

  return formulaMetrics.flatMap(metricId =>
    metricToInformationUndertakingParams(
      reportingEvent,
      collection,
      allMetrics.find(m => m.id === metricId),
      allMetrics
    )
  );
};

/**
 * Generate a grouping key with all the unique values for an information undertaking
 */
const iuParamsGroupingKey = (iuParams: IUParams): string =>
  [
    iuParams.qualification ?? 'no-qualification',
    iuParams.metric.reportedAgainst ?? 'no-reported-against',
    iuParams.collection.recurrence ?? 'no-recurrence',
    iuParams.reportingEvent.deadline ?? 'no-deadline',
    scheduleGroupingKey(iuParams.reportingEvent.schedule),
  ].join('%%');

const scheduleGroupingKey = (schedule: InformationUndertakingScheduleDraftType): string => {
  switch (schedule.type) {
    case 'recurring':
      return [
        schedule.type,
        schedule.alignment ?? 'no-alignment',
        schedule.startDate ?? 'no-start-date',
        schedule.startDateAt ?? 'no-start-date-at',
        schedule.terminationDate ?? 'no-termination-date',
        schedule.terminationDateAt ?? 'no-termination-date-at',
      ].join('%%');
    case 'non-recurring':
      return [schedule.type, schedule.checkDate ?? 'no-check-date'].join('%%');
  }
};

const paramsToInformationUndertaking = (
  params: IUParams,
  usedMetrics: LoanMetricDraftType[],
  context: Context
): InformationUndertakingSpecDraftType => {
  const uniqueMetrics = usedMetrics.filter((m1, i, arr) => arr.findIndex(m2 => m2.id === m1.id) === i);
  return {
    id: context.uuid(),
    name:
      (params.qualification ? `${QualificationLabels[params.qualification]} - ` : '') +
      uniqueMetrics.map(m => m.name).join(', '),
    schedule: produce(params.reportingEvent.schedule, s => {
      s.period = params.collection.recurrence;
    }),
    deadline: params.reportingEvent.deadline,
    qualification: params.qualification,
    collections: uniqueMetrics.map(m => ({ id: m.id })),
    reportedAgainst: paramsToIUReportedAgainst(params),
  };
};

const paramsToIUReportedAgainst = (params: IUParams): IUReportedAgainstDraftType | undefined => {
  const v = params.collection.reportedAgainst;
  if (v == null) {
    return undefined;
  }

  if (v.type === 'fund') {
    return {
      type: 'legal_entity',
      fund: true,
    };
  }

  if (params.metric.reportedAgainst == null) {
    return undefined;
  }

  switch (v.type) {
    case 'all-facilities':
    case 'aggregated-facilities':
      return {
        type: params.metric.reportedAgainst,
        choice: { type: 'all-facilities' },
      };

    case 'selected-facilities':
      return {
        type: params.metric.reportedAgainst,
        choice: { type: 'selected-facilities', ids: v.ids },
      };
  }
};
