/**
 * There are some things in the platform which may only be changed by parties we trust.
 *
 * There is a copy of this in infrastructure, for the email lambda there.
 *
 * Examples:
 * * Only trusted emails may have platform admin capabilities.
 * * Only send out notifications to trusted emails in non-production environments.
 */
export const isTrustedEmail = (email: unknown): boolean =>
  typeof email === 'string' && (email.endsWith('@realstocks.io') || email.endsWith('@deondigital.com'));
