import { getStaticMedia } from '../../../../constants/Aws';
import './HowItWorksHeader.scss';

export default function HowItWorksHeader() {
  return (
    <div
      className="how-it-works-header"
      style={{
        backgroundImage: `url(${getStaticMedia('/pages/how-it-works/how-it-works-header-img.png')}`,
      }}
    >
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds-desktop is-full left-side-container-column">
            <div className="left-side-container">
              <h1>
                Fully automated digital <br />
                loan management platform
              </h1>
              <p>
                Our technology allows borrowers and lenders to negotiate,
                <br /> sign and manage their loans completely digitally.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
