import { mixed, object, string } from 'yup';
import { NOCType } from '../company/NOCType';
import { PlatformInteractionsValidation } from './PlatformInteractionsValidation';

export const NOCValidation = {
  id: string().default(null).nullable(),
  name: string().required('Name is required'),
  externalId: string(),
  registrationNumber: string().required('Registration Number is required'),
  companyType: mixed<NOCType['companyInformation']['type']>()
    .oneOf(['lender', 'borrower', 'government', 'agent'])
    .required('Type is required'),
  ownerId: string().required('Owner is required'),
  platformInteractions: object(PlatformInteractionsValidation()).default(undefined),
};
