import { format } from 'date-fns';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { NotificationsActions } from '../../../redux/redux-notifications/notifications-actions';
import ClientPath from '../../../routes/ClientPath';
import { TNotification } from '../../../types/notifications';

type Props = {
  notification: TNotification;
};

const CertificateNotificationItem = ({ notification }: Props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClear = async () => {
    try {
      setLoading(true);
      dispatch(NotificationsActions.clearNotification(notification.id));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const metadata = notification.metadata ? JSON.parse(notification.metadata) : {};

  const goToEvent = () => {
    navigate(ClientPath.modules.administration.loanAdministration.makeCovenantDetailsLink(metadata.id, metadata.date));
  };

  return (
    <div className="notification-item-wrapper">
      <p className="notification-item-title">
        {notification.title}
        <span className="has-text-weight-bold" onClick={goToEvent}>
          EVENT
        </span>
      </p>

      <p className="notification-item-message">{notification.message}</p>

      <div className="notification-item-footer">
        <p className="created-date">{format(new Date(Number(notification.createdAt)), 'yyyy-MM-dd HH:mm')}</p>

        {notification.status === 'unread' && (
          <button type="button" className="button is-primary" disabled={loading} onClick={handleClear}>
            Dismiss
          </button>
        )}
      </div>
    </div>
  );
};

export default CertificateNotificationItem;
