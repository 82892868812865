import RsList from '../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../components/rs-elements/rs-list/RsListItem';
import './ForBorrowersForLenders.scss';

export default function ForBorrowersForLenders() {
  const forBorrowers: RsListItem[] = [
    new RsListItem('Time and cost savings for loan creation (fully repeatable process)'),
    new RsListItem(
      'Automated workflows – loan request, negotiation, loan agreement creation, signing & all lifecycle events'
    ),
  ];

  const forLenders: RsListItem[] = [
    new RsListItem(
      'Automated workflows – indicative offer, negotiation, loan agreement creation, signing & all lifecycle events'
    ),
    new RsListItem('Loan portfolio (incl. existing loans) administration on platform '),
  ];

  return (
    <div className="for-borrowers-for-lenders">
      <div className="container">
        <div className="columns ">
          <div className="column is-12-mobile is-6-tablet">
            <h4 className="has-text-centered headline-underlined">For Borrowers</h4>
            <div className="list-container">
              <RsList items={forBorrowers} />
            </div>
          </div>
          <div className="column is-12-mobile is-6-tablet">
            <h4 className="has-text-centered headline-underlined">For Lenders</h4>
            <div className="list-container">
              <RsList items={forLenders} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
