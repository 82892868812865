import { NotificationsState } from '../../store';
import { NOTIFICATIONS_ACTIONS } from './notifications-actions';

const initialState = {
  outstandingNotifications: [],
  newNotificationsWithActions: [],
  newNotificationsWithoutActions: [],
  allNotifications: [],
  modal: null,
};

export const NotificationsReducer = (state: NotificationsState = initialState, action: any) => {
  switch (action.type) {
    case NOTIFICATIONS_ACTIONS.setAllNotifications:
      return {
        ...state,
        allNotifications: action.payload,
      };
    case NOTIFICATIONS_ACTIONS.setNewNotificationsWithActions:
      return {
        ...state,
        newNotificationsWithActions: action.payload,
      };
    case NOTIFICATIONS_ACTIONS.setNewNotificationsWithoutActions:
      return {
        ...state,
        newNotificationsWithoutActions: action.payload,
      };
    case NOTIFICATIONS_ACTIONS.clearNotification:
      const currentNotification = [...state.newNotificationsWithActions, ...state.newNotificationsWithoutActions].find(
        (x) => x.id === action.payload
      );

      if (!currentNotification) {
        return { ...state };
      }

      currentNotification.status = 'read';

      const newWithActions = state.newNotificationsWithActions.filter((x) => x.id !== currentNotification.id);
      const newWithoutActions = state.newNotificationsWithoutActions.filter((x) => x.id !== currentNotification.id);
      const newAll = [currentNotification, ...state.allNotifications];

      return {
        ...state,
        allNotifications: newAll,
        newNotificationsWithoutActions: newWithoutActions,
        newNotificationsWithActions: newWithActions,
      };
    case NOTIFICATIONS_ACTIONS.openNotificationsModal:
      return {
        ...state,
        modal: action.payload,
      };
    case NOTIFICATIONS_ACTIONS.closeNotificationsModal:
      return {
        ...state,
        modal: null,
      };
    default:
      return state;
  }
};
