import * as React from 'react';
import { getStaticMedia } from '../../constants/Aws';
import './ColumnServicesComponent.scss';

export interface IColumnService {
  html: React.ReactNode;
  icon: string;
  iconClasses?: string;
}

export interface IColumnServicesComponentProps {
  services: IColumnService[];
  responsiveness?: string;
  numberedSections?: boolean;
}

export default function ColumnServicesComponent(props: IColumnServicesComponentProps) {
  const responsivenessClasses = props.responsiveness || 'is-one-quarter-desktop is-half-tablet is-full';
  return (
    <div className="columns is-multiline is-centered column-services-component">
      {props.services.length &&
        props.services.map((service: IColumnService, idx: number) => (
          <div key={`column-service-${idx}-${service.icon}`} className={`column ${responsivenessClasses}`}>
            <div className="column-service-container">
              <div className="service-icon-container">
                <img className={`section-icon ${service.iconClasses || ''}`} src={getStaticMedia(service.icon)} />
              </div>

              {props.numberedSections && <span className="section-number">{++idx}</span>}
              {service.html}
            </div>
          </div>
        ))}
    </div>
  );
}
