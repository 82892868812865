import { ModuleAccessActionsI } from './ModuleAccessActionsI';

export type AccessModulesLabelKey = keyof ModuleAccessActionsI;
export type AccessObjectTypesKey =
  | keyof ModuleAccessActionsI['company-admin']
  | keyof ModuleAccessActionsI['marketplace']
  | keyof ModuleAccessActionsI['administration']
  | keyof ModuleAccessActionsI['negotiation'];

export const AccessModulesLabels: Record<AccessModulesLabelKey | string, string> = {
  'platform-admin': 'Platform Admin',
  'company-admin': 'Company Administration',
  marketplace: 'Marketplace',
  negotiation: 'Negotiation',
  administration: 'Administration',
};

export const AccessObjectTypesLabels: Record<AccessObjectTypesKey | string, string> = {
  multiple: 'Various',
  'loan-negotiation': 'Loan Negotiation',
  bid: 'Bid',
  loan: 'Loan',
  'loan-application': 'Loan Application',
  company: 'Company',
};
