import { defineApi } from '../common/api-definition';
import { EventViewRequest } from './EventViewRequest';
import { InitiateUtilisationDetails } from './InitiateUtilisationDetails';
import { Utilisation } from './Utilisation';

export type InitiateUtilisationViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      sequenceId: string;
    }
  | EventViewRequest;

export type InitiateUtilisationView = NextInitiateUtilisationView | PastInitiateUtilisationView;

export type NextInitiateUtilisationView = IInitiateUtilisationView & {
  type: 'next';
};

export type PastInitiateUtilisationView = IInitiateUtilisationView & {
  type: 'past';
  utilisation: Utilisation;
};

type IInitiateUtilisationView = {
  loanId: string;
  sequenceId: string;
  version: number;
  loanLevel: string;
  details: InitiateUtilisationDetails;
};

export const initiateUtilisationViewApi = defineApi<{
  body: InitiateUtilisationViewRequest;
  response: InitiateUtilisationView;
}>({
  url: 'structured-loans/initiate-utilisation-view',
  method: 'post',
});
