import { RsCognitoUser } from '@realstocks/types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getStaticMedia } from '../../../../constants/Aws';
import { getCurrentUserOrNull } from '../../../../redux/redux-auth/auth-selectors';
import ClientPath from '../../../../routes/ClientPath';
import { AppState } from '../../../../store';
import './HomePageHeader.scss';

interface HomePageHeaderProps {
  currentUser: RsCognitoUser | null;
}

function HomePageHeader(props: HomePageHeaderProps) {
  return (
    <div
      className="homepage-banner-component"
      style={{
        backgroundImage: `url(${getStaticMedia('/pages/home/homepage-header.png')}`,
      }}
    >
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds-desktop is-full left-side-container-column">
            <div className="left-side-container">
              <h1>
                Commercial Real Estate Loan <br />
                Management Services
              </h1>
              <p>
                Realstocks provides professional services covering the digital contract negotiation & signing, <br />
                lifecycle management and digital transfers of commercial real estate (CRE) loans.
              </p>

              <div className="banner-actions">
                <Link
                  to={
                    props.currentUser
                      ? ClientPath.modules.administration.loanAdministration.upcomingEvents
                      : ClientPath.auth.signup
                  }
                >
                  <button className="button is-primary">Register Now</button>
                </Link>
                <Link to={ClientPath.static.contact}>
                  <button className="button is-primary is-inverted">Get in Touch</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state: AppState) => {
  return {
    currentUser: getCurrentUserOrNull(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageHeader);
