import { getStaticMedia } from '../../../../constants/Aws';
import ClientPath from '../../../../routes/ClientPath';
import { HomepageServiceBlock, HomepageServiceSquareComponent } from '../home-page-services/HomepageServiceBlock';
import './DepositAlternativeHome.scss';

export default function DepositAlternativeHome() {
  return (
    <div className="deposit-alternative-home">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6">
            <div className="deposit-alternative-service-container">
              <HomepageServiceBlock
                service={
                  new HomepageServiceSquareComponent(
                    'Deposit Alternative',
                    getStaticMedia('/icons/static-pages/deposit-alternative.svg'),
                    (
                      <p className="has-text-centered">
                        Publicly tradeable
                        <br />
                        CRE loans
                      </p>
                    ),
                    ClientPath.static.realstocksServices.depositAlternative
                  )
                }
              />
            </div>
          </div>
          <div className="column is-6">
            <h4 className="has-text-centered headline-underlined product-vision-headline">Product Vision</h4>
            <div className="product-vision-container">
              <p className="has-text-weight-bold has-text-centered">
                This product requires an FSA license, an exchange offering digital asset trading and an efficient money
                token solution.
              </p>
              <p className="has-text-weight-bold has-text-centered">
                Realstocks combines super core fully asset backed commercial real estate loans with blockchain
                technology.
              </p>
              <p className="has-text-weight-bold has-text-centered">
                Allowing positive returns with high liquidity for investors
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
