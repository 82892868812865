export const mapObjectValues = <
  TKey extends string | number | symbol = string,
  TVal extends unknown = unknown,
  TReturn = unknown
>(
  obj: { [Key in TKey]: TVal },
  mapper: (val: TVal) => TReturn
): { [Key in TKey]: TReturn } => {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, mapper(v as any)])) as any;
};
