import { defineApi } from '../common/api-definition';
import { OutputDto } from '../common/OutputType';
import { GetReportDto } from './get-report.dto';

const getReport = defineApi<{
  body: GetReportDto;
  params: { report: GetReportDto['report'] };
  response: OutputDto<string>;
}>({ url: 'reports/:report', method: 'post' });

export type GetReportApiDefinition = typeof getReport;

export const reportsApi = {
  getReport,
} as const;
