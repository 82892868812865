import React from 'react';
import './RsCloseBtn.scss';

type Props = {
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
};

export default function RsCloseBtn(props: Props) {
  return (
    <button className={`rs-close-btn ${props.className}`} onClick={props.onClick}>
      <i className="fas fa-times fa-2x"></i>
    </button>
  );
}
