import * as Yup from 'yup';
import { NumberStripEmpty, ObjectStripUnknown, RequiredArray, TextStripEmpty } from './atoms';
import { literal } from './base';

const loanFeesValidation = ObjectStripUnknown({
  agent: ObjectStripUnknown({
    drawdown_fee_amount: NumberStripEmpty.min(0, 'Drawdown Fee Amount must be a positive number'),
    commitment_fee_points: NumberStripEmpty.min(0, 'Commitment Fee Points must be a positive number').max(
      100,
      "Commitment Fee Points can't be greater than 100"
    ),
    arrangement_fee_points: NumberStripEmpty.min(0, 'Arrangement Fee Points must be a positive number').max(
      100,
      "Arrangement Fee Points can't be greater than 100"
    ),
    fee_points: NumberStripEmpty.min(0, 'Fee Points must be a positive number').max(
      100,
      "Fee Points can't be greater than 100"
    ),
  }),
  lender: ObjectStripUnknown({
    drawdown_fee_amount: NumberStripEmpty.min(0, 'Drawdown Fee Amount must be a positive number').notRequired(),
    commitment_fee_points: NumberStripEmpty.min(0, 'Commitment Fee Points must be a positive number').max(
      100,
      "Commitment Fee Points can't be greater than 100"
    ),
    arrangement_fee_points: NumberStripEmpty.min(0, 'Arrangement Fee Points must be a positive number').max(
      100,
      "Arrangement Fee Points can't be greater than 100"
    ),
  }),
  annual_prepayment_fees: RequiredArray(
    ObjectStripUnknown({
      year: NumberStripEmpty.min(0),
      fee: NumberStripEmpty.min(0, 'Annual prepayment fee must be a positive number'),
    }).required()
  ),
});

export const SLUpdateLoanFeesValidation = ObjectStripUnknown({
  type: literal('loan-fees-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  fees: loanFeesValidation.required(),
});
