import * as Yup from 'yup';
import { Report } from '../reports/report.type';
import { getMimeTypeFromExtention } from '../utils/mime-type';

const reportMimeTypes = [getMimeTypeFromExtention('csv')!, getMimeTypeFromExtention('xlsx')!];

export const reportsValidationSchema = Yup.object().shape({
  format: Yup.string().oneOf(reportMimeTypes),
  report: Yup.string().required().oneOf(Object.values(Report)),
  ipd: Yup.string().when('report', {
    is: report => [Report.STATE_TREASURY_IPD].indexOf(report) !== -1,
    then: Yup.string().required(),
  }),
  month: Yup.string().when('report', { is: Report.STATE_TREASURY_MONTHLY, then: Yup.string().required() }),
  quarter: Yup.string().when('report', { is: Report.QUARTERLY_LENDER, then: Yup.string().required() }),
});
