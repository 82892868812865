import { getStaticMedia } from '../../../../../constants/Aws';
import {
  HomepageServiceBlock,
  HomepageServiceSquareComponent,
} from '../../../../home/components/home-page-services/HomepageServiceBlock';

export default function AdministrationServiceHeader() {
  return (
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6">
            <div className="container-center">
              <HomepageServiceBlock
                service={
                  new HomepageServiceSquareComponent(
                    'Administration',
                    getStaticMedia('/icons/static-pages/administration.svg'),
                    '',
                    '',
                    true
                  )
                }
              />
            </div>
          </div>
          <div className="column is-6">
            <h4 className="has-text-centered headline-underlined">AUTOMATED LOAN LIFECYCLE EVENTS</h4>
            <p className="has-text-weight-bold has-text-centered">
              Monitor and manage your loan portfolio and all its events (payments, covenants, voting, etc.) in one
              place.
            </p>
            <p className="has-text-weight-bold has-text-centered">
              Have access to all documents and loan data on the platform and via APIs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
