import { mapValues } from 'lodash';
import { lazy, object } from 'yup';
import { PlatformInteractionsType } from '../common/PlatformInteractionsType';
import { NonEmptyString, PercentageValidation } from './base';
import { CovenantTestingDate, getValidation } from './options';
import { PlatformInteractionsValidation } from './PlatformInteractionsValidation';

export type ValidationOptions = {
  nullable: boolean;
  preserveRequired: boolean;
};

export const LoanAttributesValidation = (
  validationOptions: ValidationOptions = { preserveRequired: true, nullable: false }
) => {
  const { nullable, preserveRequired } = validationOptions;
  return {
    covenant_gpc_requirements: NonEmptyString.nullable(nullable),
    covenant_gpc_testing_cycle: getValidation(CovenantTestingDate, nullable),
    covenant_gpc_rate_offset: PercentageValidation.nullable(nullable),
  };
};

export const LoanPlatformInteractionsValidation = (nullable: boolean) =>
  lazy<Record<string, PlatformInteractionsType>>(obj =>
    object<any>(mapValues(obj, () => object<any>(PlatformInteractionsValidation(nullable)))).nullable(nullable)
  );

export const LoanStartConfigurationValidation = (
  validationOptions: ValidationOptions = { preserveRequired: true, nullable: false }
) => {
  const { nullable } = validationOptions;
  return {
    platform_interactions: LoanPlatformInteractionsValidation(nullable),
  };
};
