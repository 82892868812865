import { createSelector } from 'reselect';
import { AppState } from '../../store';
import { ReduxRoutesAccessRights } from './access-rights-reducer';

const getAccessRights = (state: AppState) => state.accessRights;

export const getRoutesAccessRights = createSelector(
  getAccessRights,
  (accessRightsState: ReduxRoutesAccessRights) => accessRightsState.map
);
