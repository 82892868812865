import { TeamMemberType } from '../components/team-member/TeamMember';

export class TeamMembersRow {
  public members: TeamMemberType[] = [];
  public description: string | null = null;
  public expanded: boolean = false;

  constructor() {
    return this;
  }

  setDescription(description: string) {
    this.description = description;

    return this;
  }

  removeDescription() {
    // this.description = null;

    return this;
  }

  addMember(member: TeamMemberType) {
    this.members.push(member);

    return this;
  }
}
