/**
 * A phone has the following saved format `(<countrycode>) <phone_number>`
 *
 * From separate `<countrycode>` and `<phone_number>` provided as parameters,
 * create the above format
 *
 * @param phone
 */
export function formatPhone(countrycode: string, phone: string = '') {
  return `${countrycode} ${phone}`;
}

/**
 * A phone has the following saved format `(<countrycode>) <phone_number>`
 *
 * return just the `<countrycode>` from the above format
 *
 * @param formattedPhoneNumber
 */
export function getCountryCode(formattedPhoneNumber: string = '') {
  return formattedPhoneNumber.split(' ')[0];
}

/**
 * A phone has the following saved format `(<countrycode>) <phone_number>`
 *
 * return just the `<phone_number>` from the above format
 *
 * @param formattedPhoneNumber
 */
export function getPhoneNr(formattedPhoneNumber: string = '') {
  return formattedPhoneNumber.split(' ').slice(1).join(' ');
}
