import { Component, Fragment } from 'react';
import RegisterNowComponent from '../../components/register-now/RegisterNowComponent';
import BottomLineComponent from './components/bottom-line/BottomLineComponent';
import DepositAlternativeHome from './components/deposit-alternative-home/DepositAlternativeHome';
import ForBorrowersForLenders from './components/for-borrowers-for-lenders/ForBorrowersForLenders';
import HomePageServices from './components/home-page-services/HomePageServices';
import HomePageHeader from './components/homepage-header/HomePageHeader';
import HomepageWhatWeDoComponent from './components/homepage-what-we-do/HomepageWhatWeDoComponent';
import JourneyOfCreLoan from './components/journey-of-cre-loan/JourneyOfCreLoan';
import './HomePage.scss';

type State = {};

type Props = {};

class HomePage extends Component<Props, State> {
  render() {
    return (
      <div className="page-home">
        <HomePageHeader />
        <div className="up-shadow" />
        <HomePageServices />
        <HomepageWhatWeDoComponent />
        <JourneyOfCreLoan />
        {/* <BottomLineComponent /> */}
        <ForBorrowersForLenders />
        <DepositAlternativeHome />
        {/* <HomeLatestUpdates /> */}
        <BottomLineComponent />

        <RegisterNowComponent
          content={
            <Fragment>
              Participate in super core Commercial Real Estate Loans using the latest
              <br />
              technologies and reliable partners.
            </Fragment>
          }
        />
      </div>
    );
  }
}

export default HomePage;
