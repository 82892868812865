import { Dispatch } from 'react';
import { RsApiEndpoints } from '../../constants/RsApiEndpoints';
import { RsApi } from '../../services/api/RsApi';
import { ReduxAction } from '../redux';

export const NOTIFICATIONS_ACTIONS = {
  getAllNotifications: 'GET_ALL_NOTIFICATIONS',
  setAllNotifications: 'SET_ALL_NOTIFICATIONS',
  clearNotification: 'CLEAR_NOTIFICATION',
  openNotificationsModal: 'OPEN_NOTIFICATIONS_MODAL',
  closeNotificationsModal: 'CLOSE_NOTIFICATIONS_MODAL',
  setNewNotificationsWithActions: 'SET_NEW_NOTIFICATIONS_WITH_ACTIONS',
  setNewNotificationsWithoutActions: 'SET_NEW_NOTIFICATIONS_WITHOUT_ACTIONS',
};

const sortNewNotifications = (notifications: any) => {
  const newNotificationsWithActions = [];
  const newNotificationsWithoutActions = [];

  for (let n of notifications) {
    if (n.hasAction) {
      newNotificationsWithActions.push(n);
    } else {
      newNotificationsWithoutActions.push(n);
    }
  }

  return {
    newNotificationsWithActions,
    newNotificationsWithoutActions,
  };
};

export const NotificationsActions = {
  getAllNotifications: () => {
    return async (dispatch: Dispatch<ReduxAction>) => {
      const [allNotifications, outstandingNotifications] = await Promise.all([
        RsApi.get({
          path: `${RsApiEndpoints.notifications.getAllNotifications}`,
        }),
        RsApi.get({
          path: `${RsApiEndpoints.notifications.getOutstandingNotifications}`,
        }),
      ]);

      dispatch(NotificationsActions.setAllNotifications(allNotifications.filter((n: any) => n.status !== 'unread')));

      const { newNotificationsWithActions, newNotificationsWithoutActions } =
        sortNewNotifications(outstandingNotifications);
      dispatch(NotificationsActions.setNewNotificationsWithActions(newNotificationsWithActions));
      dispatch(NotificationsActions.setNewNotificationsWithoutActions(newNotificationsWithoutActions));
    };
  },
  clearNotification: (id: string) => {
    return async (dispatch: Dispatch<ReduxAction>) => {
      await RsApi.post({
        path: `${RsApiEndpoints.notifications.clearNotification}`,
        config: {
          body: {
            id,
          },
        },
      });

      dispatch(NotificationsActions.setClearNotification(id));
    };
  },
  setAllNotifications: (payload: any) => {
    return {
      type: NOTIFICATIONS_ACTIONS.setAllNotifications,
      payload: payload,
    };
  },
  setNewNotificationsWithActions: (payload: any) => {
    return {
      type: NOTIFICATIONS_ACTIONS.setNewNotificationsWithActions,
      payload: payload,
    };
  },
  setNewNotificationsWithoutActions: (payload: any) => {
    return {
      type: NOTIFICATIONS_ACTIONS.setNewNotificationsWithoutActions,
      payload: payload,
    };
  },
  setClearNotification: (id: any) => {
    return {
      type: NOTIFICATIONS_ACTIONS.clearNotification,
      payload: id,
    };
  },
  openNotificationsModal: (payload: any) => {
    return {
      type: NOTIFICATIONS_ACTIONS.openNotificationsModal,
      payload: payload,
    };
  },
  closeNotificationsModal: () => {
    return {
      type: NOTIFICATIONS_ACTIONS.closeNotificationsModal,
    };
  },
};
