import { FieldAttributes } from 'formik';
import { isString } from 'lodash';
import { AwsErrorResponse, ServerErrorResponse } from '../../../constants/ServerError';

type Props = {
  response: AwsErrorResponse | ServerErrorResponse;
} & FieldAttributes<any>;

export default function RsFormError(props: Props) {
  const { response } = props;
  const msg = response ? (isString(response) ? response : response?.message) : null;

  return (
    <div className="form-error error-msg text-align-center">
      {msg && (
        <span className={`message ${isString(msg) ? 'show' : ''}`}>{isString(msg) ? msg : 'Something went wrong'}</span>
      )}
    </div>
  );
}
