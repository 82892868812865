import { InferType, object, string } from 'yup';
import { defineApi } from '../common/api-definition';
import { StructuredAgreementType } from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';

export type AgreementOverviewView = {
  version: number;
  state: StructuredLoanState;
  loan: StructuredAgreementType;

  // The total commitment is undefined, if no commitments have been configured.
  totalCommitment?: number;

  // The total commitments for each facility.
  facilityTotalCommitments: { [facilityId: string]: number | undefined };

  // The names of the responsible users
  responsibleUserNames?: string[];
};

export const AgreementOverviewViewRequestValidation = object({
  loanId: string().required(),
});

export type AgreementOverviewViewRequest = InferType<typeof AgreementOverviewViewRequestValidation>;

export const agreementOverviewViewApi = defineApi<{
  body: AgreementOverviewViewRequest;
  response: AgreementOverviewView;
}>({
  url: 'structured-loans/agreement-overview-view',
  method: 'post',
});
