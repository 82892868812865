import { ValueOf } from '../utils/type-utils';

export const FeedbackReasonTypeValues = {
  CORRECT_ANSWER: 'correct_answer',
  EASY_TO_UNDERSTAND: 'easy_to_understand',
  HELPFUL: 'helpful',
  WRONG_ANSWER: 'wrong_answer',
  UNRELATED_ANSWER: 'unrelated_answer',
  DIFFICULT_TO_UNDERSTAND: 'difficult_to_understand',
  NOT_HELPFUL: 'not_helpful',
  OFFENSIVE: 'offensive',
} as const;

export type FeedbackReasonType = ValueOf<typeof FeedbackReasonTypeValues>;
