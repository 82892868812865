import Auth from '@aws-amplify/auth';
import { RoutesAccessRightsMap, RsCognitoUser } from '@realstocks/types';
import { Formik, FormikHelpers } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import RsInput from '../../../components/rs-form/rs-input/RsInput';
import RsForm from '../../../components/rs-form/RsForm';
import { createErrorStatus } from '../../../components/rs-form/utils/create-error-status';
import { AuthenticationTypes, AWS_CHALLENGES } from '../../../constants/Aws';
import { ProcessEnv } from '../../../constants/ProcessEnv';
import { RsApiEndpoints } from '../../../constants/RsApiEndpoints';
import { RsError } from '../../../constants/ServerError';
import { getRoutesAccessRights } from '../../../redux/redux-access-rights/access-rights-selector';
import { setLoading } from '../../../redux/redux-app/app-actions';
import { setCurrentUser } from '../../../redux/redux-auth/auth-actions';
import { getCurrentUserOrNull } from '../../../redux/redux-auth/auth-selectors';
import {
  getCompanyExists,
  getCompanyInformationOrNull,
  getCompanyOrNull,
  isCompanyCompleted,
  isCompanyFetched,
} from '../../../redux/redux-company/company-selectors';
import ClientPath from '../../../routes/ClientPath';
import { RsApi } from '../../../services/api/RsApi';
import { rsSignIn } from '../../../services/utils/rs-sign-in';
import { AppState } from '../../../store';
import { CompanyInformationType, CompanyModel } from '../../../types/Company';
import LoginFooter from '../components/login-footer/LoginFooter';
import LoginHeader from '../components/login-header/LoginHeader';
import './LoginPage.scss';
import { successfulLoginRedirect } from './successful-login-redirect';

type LoginCredentials = {
  email: string;
  password: string;
  newPassword?: string;
};

type Props = ReduxDispatchProps &
  ReduxStateProps & {
    initialized: boolean;
  };

function LoginPage(props: Props) {
  const navigate = useNavigate();
  const [successfulLogin, setSuccessfulLogin] = useState<boolean>(false);
  const [prefilledEmail] = useState(new URLSearchParams(window.location.search).get('email'));
  const [oldPassword, setOldPassword] = useState<string | null>(null);
  const [credentials, setCredentials] = useState<LoginCredentials>({ email: '', password: '' });
  const [newPasswordSet, setNewPasswordSet] = useState<boolean>(false);
  const [formValidationSchema, setFormValidationSchema] = useState<Yup.ObjectSchemaDefinition<any>>({
    email: Yup.string().email('Invalid email address.').required('Email is required.'),
  });
  const { initialized } = props;
  const routesAccessRightsMap: RoutesAccessRightsMap = useSelector(getRoutesAccessRights);

  useEffect(() => {
    if (props.currentUser && initialized) {
      navigate(ClientPath.account.profile);
    }
  }, [initialized]);

  useEffect(() => {
    if (prefilledEmail) {
      setCredentials({ ...credentials, email: decodeURIComponent(prefilledEmail) });
    }
  }, [prefilledEmail]);

  useEffect(() => {
    if (newPasswordSet) {
      setFormValidationSchema({
        ...formValidationSchema,
        newPassword: Yup.string().required('New Password is required.'),
      });

      setCredentials({ ...credentials, newPassword: '' });
    }
  }, [newPasswordSet]);

  useEffect(() => {
    if (props.currentUser && !successfulLogin) {
      props.setLoading('Logging you in');
    }

    if (successfulLogin && props.currentUser) {
      props.setLoading('Logging you in');
      handleSuccessfulLogin(routesAccessRightsMap);
    }
  }, [successfulLogin, props.currentUser, props.isCompanyFetched, props.hasCompanyCompleted, props.companyExists]);

  async function handleSuccessfulLogin(routesAccessRightsMap: RoutesAccessRightsMap) {
    await successfulLoginRedirect({
      ...props,
      navigate,
      companyId: props.currentUser?.attributes['custom:company'],
      routesAccessRightsMap,
    });
  }

  const onSubmit = async (values: LoginCredentials, actions: FormikHelpers<LoginCredentials>) => {
    try {
      actions.setErrors({});
      actions.setStatus('');
      Auth.configure({ authenticationFlowType: AuthenticationTypes.regular });

      props.setLoading('Logging you in');
      setCredentials({ email: values.email, password: values.password });

      let user: RsCognitoUser;

      try {
        user = await rsSignIn({
          username: values.email,
          password: oldPassword ? oldPassword : values.password,
        });
      } catch (err) {
        const error: any = err;
        if (error.code === AWS_CHALLENGES.unAuthorizedException) {
          try {
            const response = await RsApi.post({
              path: RsApiEndpoints.user.resetTemporaryPassword,
              config: {
                body: {
                  email: values.email,
                  userPoolId: ProcessEnv.aws.userPool.id,
                },
              },
            });

            throw new RsError(response.message);
          } catch (resetPasswordErr) {
            const resetPasswordError: any = resetPasswordErr;
            if (resetPasswordError.data && resetPasswordError.data.message === 'Invalid username or password.') {
              throw error;
            } else throw resetPasswordError;
          }
        } else {
          throw error;
        }
      }

      if (
        user.challengeName &&
        [AWS_CHALLENGES.newPasswordRequired, AWS_CHALLENGES.forceChangePassword].indexOf(user.challengeName) >= 0
      ) {
        if (!oldPassword) {
          setOldPassword(values.password);
        }

        setCredentials({ email: values.email, password: '' });

        if (newPasswordSet && values.newPassword) {
          await Auth.completeNewPassword(
            user,
            values.newPassword,
            user.challengeParam ? user.challengeParam.requiredAttributes : null
          );
          user = await rsSignIn({ username: values.email, password: values.newPassword });
        } else {
          actions.setErrors({});
          props.setLoading(false);

          return setNewPasswordSet(true);
        }
      }

      // ? Uncomment lines below when we revert back to eid being required

      // if (user.attributes['custom:bankid']) {
      // props.setLoading('Loading...');
      // await signOut();
      // history.replace(
      //   credentials.email !== ''
      //     ? `${ClientPath.auth.loginWithBankid}?email=${encodeURIComponent(credentials.email)}`
      //     : ClientPath.auth.loginWithBankid
      // );
      // toast.error(`For security reasons please login with your Bank eID`);
      // props.setLoading(false);
      // return false;
      // }

      setSuccessfulLogin(true);

      // if (!user.attributes['custom:bankid']) {
      // navigate(ClientPath.auth.linkBankId);
      // }
      // } catch (error) {
      //   history.replace(ClientPath.home);
      // }
    } catch (error) {
      actions.setStatus(createErrorStatus(error));
    } finally {
      props.setLoading(false);
    }
  };

  return (
    <div className="container login-page">
      <LoginHeader />

      <div className="columns is-centered is-multiline">
        <div className="column is-7">
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object(formValidationSchema)}
            initialValues={credentials}
            onSubmit={onSubmit}
          >
            {({ status }) => (
              <RsForm status={status} className="login-form" submitLabel="Log In">
                <div className="columns is-multiline is-centered">
                  <Fragment>
                    <div className="column is-12">
                      <RsInput type="email" label="Email" icon="far fa-envelope" name="email" />
                    </div>

                    <div className="column is-12">
                      <RsInput
                        type="password"
                        label={newPasswordSet ? 'Temporary Password' : 'Password'}
                        name="password"
                        icon="fas fa-lock"
                      />
                    </div>

                    {newPasswordSet && (
                      <div className="column is-12">
                        <RsInput type="password" icon="fas fa-lock" label="New Password" name="newPassword" />
                      </div>
                    )}
                  </Fragment>
                </div>
              </RsForm>
            )}
          </Formik>

          <div className="column is-12">
            <p className="or-text has-text-centered is-uppercase">or</p>

            <div className="has-text-centered">
              <button
                className="button is-inverted is-primary alternative-login-btn"
                onClick={() => {
                  navigate(
                    credentials.email !== ''
                      ? `${ClientPath.auth.loginWithBankid}?email=${encodeURIComponent(credentials.email)}`
                      : ClientPath.auth.loginWithBankid
                  );
                }}
              >
                <i className={`fas fa-lock`}></i>Login with Bank eID
              </button>
            </div>
          </div>

          <LoginFooter email={credentials.email} />
        </div>
      </div>
    </div>
  );
}

type ReduxDispatchProps = {
  setCurrentUser: Function;
  setLoading: (loading: string | boolean) => void;
};

const mapDispatchToProps: ReduxDispatchProps = {
  setLoading: setLoading,
  setCurrentUser: setCurrentUser,
};

type ReduxStateProps = {
  company: CompanyModel | null;
  currentUser: RsCognitoUser | null;
  isCompanyFetched: boolean | null;
  hasCompanyCompleted: boolean | null;
  companyExists: boolean | null;
  companyInformation: CompanyInformationType | null;
};

const mapStateToProps = (state: AppState): ReduxStateProps => {
  return {
    company: getCompanyOrNull(state),
    hasCompanyCompleted: isCompanyCompleted(state),
    currentUser: getCurrentUserOrNull(state),
    isCompanyFetched: isCompanyFetched(state),
    companyExists: getCompanyExists(state),
    companyInformation: getCompanyInformationOrNull(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
