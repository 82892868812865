import KeyBenefitsAlternatives from '../../../../home/components/key-benefits/KeyBenefitsAlternatives';

export default function DepositAlternativeServiceBenefits() {
  return (
    <div className="section is-grey">
      <div className="container">
        <KeyBenefitsAlternatives />
      </div>
    </div>
  );
}
