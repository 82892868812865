import { getYear } from 'date-fns';

// sizes are imported from
// https://material-ui.com/customization/breakpoints/
export const Breakpoints = {
  mobile: 768,
  tablet: 769,
  desktop: 1024,
  widescreen: 1216,
  fullhd: 1408,
};

/**
 * RsDatePicker input type years restrictions
 */
export const AGE_SELECT_MIN_YEAR = 1930;
export const AGE_SELECT_MAX_YEAR = getYear(new Date()) - 18;
export const DATEPICKER_MIN_YEAR = 1800;

export const DEBOUNCE_TIME = 10000;
