import * as datefns from 'date-fns';

export const utc = (year: number, month: number, day: number) => new Date(Date.UTC(year, month, day, 0, 0, 0));

export const parseIsoDate = (date: string) => {
  const r = datefns.parseISO(date + 'Z');
  if (!datefns.isValid(r)) {
    throw new Error(`parseDate: invalid date ${date}`);
  }
  return r;
};

// the date-fns equivalents returns dates in local timezone :/ So had to reimplement
export const startOfDay = (date: Date) => utc(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
export const endOfDay = (date: Date) => utc(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1);
