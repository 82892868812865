import { RsApiEndpoints } from '../../constants/RsApiEndpoints';
import store from '../../store';
import { CompanyModel } from '../../types/Company';
import { setCompanyFetched, setCompanyModel } from './../../redux/redux-company/company-actions';
import { RsApi } from './RsApi';

/**
 * Get the company details and populate redux
 */
export const getAndPopulateCompanyDetails = async () => {
  try {
    const company: CompanyModel & any = await RsApi.get({
      path: RsApiEndpoints.company.getDetails,
    });

    store.dispatch(setCompanyModel(company as any));

    return company;
  } catch (err) {
    store.dispatch(setCompanyModel(null));

    throw err;
  } finally {
    store.dispatch(setCompanyFetched(true));
  }
};
