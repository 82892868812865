import { TosRoutes } from './TosRoutes';
import { TosSecondaryNavigation } from './TosSecondaryNavigation';

export default function TermsAndConditions() {
  return (
    <div className="terms-of-service-pages">
      <TosSecondaryNavigation />

      <TosRoutes />
    </div>
  );
}
