import { RsApiEndpoints } from '../../constants/RsApiEndpoints';
import { RsApi } from './RsApi';

export const updatePresenceStatusPromise = (status: string) => {
  return RsApi.post({
    path: RsApiEndpoints.chat.updatePresenceStatus,
    config: {
      body: {
        status: status,
      },
    },
  });
};
