import './BottomLineComponent.scss';

export default function BottomLineComponent() {
  return (
    <div className="bottom-line-component">
      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-one-third is-half-tablet is-full-mobile bottom-line-column-content">
            <h4 className="has-text-weight-bold headline-underlined">TEAM</h4>
            <p>
              With a combined over 100 years in the financial industry, real estate, consulting and full-stack
              development, our team brings together the necessary experience and network to offer this unique solution.
            </p>
            <p>
              In addition, our company and advisory board consists of a group of senior professionals with relevant
              expertise and industry overarching networks.
            </p>
          </div>
          <div className="column is-one-third is-half-tablet is-full-mobile bottom-line-column-content">
            <h4 className="headline-underlined">SAFETY</h4>
            <p>We perform due diligence (KYC/AML) for all borrowers and lenders using our platform services.</p>
            <p>
              All platform users need to login to the platform using 2-way authentication (bank-id in the Nordics) and
              all changes made are logged in a tamperproof and accessible audit trail.
            </p>
            <p>
              Our platform utilises best-practise information security methods and control frameworks. The cloud
              infrastructure, client information and loan data is hosted in Europe.
            </p>
          </div>
          <div className="column is-one-third is-half-tablet is-full-mobile bottom-line-column-content">
            <h4 className="has-text-weight-bold headline-underlined">TECHNOLOGY</h4>
            <p>
              By pre-filling available customer information, we simplify and automate our fully digital onboarding
              process for institutional clients.
            </p>
            <p>
              We translate commercial real estate loan agreements into digital contracts. This allows our clients to
              setup and negotiate complex loan transactions in a fraction of the time.
            </p>

            <p>
              The automation of loan lifecycle events supports further time saving and risk reduction, enabling our
              clients to monitor and manage their whole loan portfolio in one place.
            </p>
            <p>
              Our digital transfer functionality will allow transfers of loans at any time before maturity of the loans.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
