import { AmortisationTypeKey, AmortisationTypeValue } from '../structured-loan/AmortisationType';
import { GenericOption } from './options';

export const AmortisationTypeOptions: GenericOption<AmortisationTypeKey, AmortisationTypeValue>[] = [
  {
    value: 'bullet',
    label: 'Bullet',
  },
  {
    value: 'per-annum',
    label: 'Constant Rate per Annum',
  },
  {
    value: 'custom',
    label: 'Custom Schedule',
  },
  {
    value: 'custom-dynamic',
    label: 'Custom Dynamic',
  },
  {
    value: 'per-period',
    label: 'Constant Rate per Interest Period',
  },
];
