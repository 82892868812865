import * as Yup from 'yup';
import { RsLinkType } from '../common/RsLinkType';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { SLDocumentCategoryType } from '../structured-loan/structured-agreement-type';
import { TextStripEmpty } from './atoms';
import { literal, RsFileValidation } from './base';
import { RsLinkValidation } from './RsLinkValidation';

export const LoanDocumentsValidation = Yup.array<SLDocumentCategoryType>().of(
  Yup.object<SLDocumentCategoryType>({
    id: Yup.string(),
    name: Yup.string().required('The category name is required'),
    description: Yup.string().optional(),
    links: Yup.array<RsLinkType>().of<RsLinkType>(RsLinkValidation),
    documents: Yup.array<RsUploadedFileType>().of<RsUploadedFileType>(RsFileValidation),
  })
);
export const SLUpdateLoanDocumentsValidation = Yup.object({
  type: literal('loan-documents-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  documentCategories: LoanDocumentsValidation.required(),
});
