import { FieldIssuesType } from './RsForm';

export default function RsFieldIssues(props: { fieldIssues: FieldIssuesType }) {
  const { fieldIssues } = props;
  const msgClassName = fieldIssues.level === 'warning' ? 'warning rs-no-margin' : 'rs-no-margin';
  return (
    <div className="error-msg">
      {fieldIssues.messages.map((msg, index) => (
        <div key={index} className="field-issues-msg">
          <div className="message show">
            <p className={msgClassName}>{msg}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
