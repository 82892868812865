import CookieConsent from 'react-cookie-consent';
import { ProcessEnv } from '../../constants/ProcessEnv';

export default function RsCookieConsent() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I Agree"
      cookieName="realstocks-functional-cookies"
      style={{
        background: '#eee',
        color: '#343a40',
      }}
      buttonStyle={{
        whiteSpace: 'normal',
        textAlign: 'center',
        fontSize: '14px',
        padding: '0.6rem 1.1rem',
        letterSpacing: '0.1rem',
        background: '#5a709a',
        color: 'white',
        fontWeight: '600',
        height: 'auto',
        borderColor: 'transparent',
      }}
      expires={356}
    >
      We use necessary cookies to make our site work. For more information about the use of cookies or our use of data,{' '}
      <a
        href={`${ProcessEnv.aws.s3.buckets.media}/pages/privacy-policy/Realstocks-Cookie-Policy.pdf`}
        target="_blank"
        rel="noreferrer"
      >
        see our Cookie Policy
      </a>
      .
    </CookieConsent>
  );
}
