import * as Yup from 'yup';
import { ObjectStripUnknown, TextStripEmpty } from './atoms';

export const RsUploadedFileMetadataValidation = ObjectStripUnknown({
  acl: TextStripEmpty.required(),
  isCover: Yup.bool(),
});

export const RsUploadedFileValidation = ObjectStripUnknown({
  id: TextStripEmpty.required(),
  fieldName: TextStripEmpty.required(),
  name: TextStripEmpty.required(),
  type: TextStripEmpty.required(),
  bucket: TextStripEmpty.required(),
  url: TextStripEmpty,
  key: TextStripEmpty,
  status: Yup.mixed().oneOf(['uploading', 'error', 'success', 'ready']),
  metadata: RsUploadedFileMetadataValidation,
  lastModified: Yup.number(),
});
