import { RoutesAccessRightsMap } from '@realstocks/types';
import { ACCESS_RIGHTS_ACTIONS } from './access-rights-actions';
/**
 * Error Reducer takes care of the global error messages inside the application.
 *
 * @param {*} state
 * @param {*} action
 */
export type ReduxRoutesAccessRights = { map: RoutesAccessRightsMap; fetched: boolean };

export const AccessRightsReducer = (
  state: ReduxRoutesAccessRights = { map: {}, fetched: false },
  action: { type: string; payload: RoutesAccessRightsMap | null }
) => {
  switch (action.type) {
    case ACCESS_RIGHTS_ACTIONS.setFetched:
      return {
        ...state,
        fetched: true,
      };
    case ACCESS_RIGHTS_ACTIONS.setRoutesMap:
      return {
        ...state,
        map: action.payload,
        fetched: true,
      };
    case ACCESS_RIGHTS_ACTIONS.reset:
      return { map: {}, fetched: false };
    default:
      return state;
  }
};
