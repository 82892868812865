import { CompanyEInvoiceConfiguration } from '../company/company-e-invoice-configuration';
import { CompanyAddressType } from '../company/CompanyAddressType';
import {
  EmailsOrNoneTransmissionType,
  PaymentNoticeTransmissionType,
} from '../outbound-notification/outbound-notifications.type';

/**
 * Companies need emails or e-invoices sent from the system.
 * This holds that information for each different kind of notification.
 */
export type PlatformInteractionsType = {
  'receive-interest-fixing-notification': EmailsOrNoneTransmissionType;
  'email-for-interest-fixing-notification': string;
  'receive-payment-instruction': PaymentNoticeTransmissionType;
  'email-for-payment-instruction': string;
  'receive-incoming-payment-notification': EmailsOrNoneTransmissionType;
  'email-for-payment-notification': string;
  'receive-covenant-reports': EmailsOrNoneTransmissionType;
  'email-for-covenant-reports': string;
  'company-communication-address': CompanyAddressType;
  'company-e-invoice-configuration': CompanyEInvoiceConfiguration;
};

export const platformInteractionsInitialValues: PlatformInteractionsType = {
  'receive-interest-fixing-notification': 'none',
  'email-for-interest-fixing-notification': '',
  'receive-payment-instruction': 'none',
  'email-for-payment-instruction': '',
  'receive-incoming-payment-notification': 'none',
  'email-for-payment-notification': '',
  'receive-covenant-reports': 'none',
  'email-for-covenant-reports': '',
  'company-communication-address': {
    country: '',
    zipCode: '',
    city: '',
    street: '',
    contactPerson: '',
    contactPhone: '',
  },
  'company-e-invoice-configuration': {
    'e-invoice-scheme-id': '',
    'e-invoice-id': '',
    'vat-number': '',
    telephone: '',
  },
};

export type PlatformInteractionsFormType = PlatformInteractionsType & {
  same: {
    'receive-interest-fixing-notification': boolean;
    'email-for-interest-fixing-notification': boolean;
    'receive-payment-instruction': boolean;
    'email-for-payment-instruction': boolean;
    'receive-incoming-payment-notification': boolean;
    'email-for-payment-notification': boolean;
    'receive-covenant-reports': boolean;
    'email-for-covenant-reports': boolean;
  };
};

export const platformInteractionsInitialFormValues: PlatformInteractionsFormType = {
  ...platformInteractionsInitialValues,
  same: {
    'receive-interest-fixing-notification': false,
    'email-for-interest-fixing-notification': false,
    'receive-payment-instruction': false,
    'email-for-payment-instruction': false,
    'receive-incoming-payment-notification': false,
    'email-for-payment-notification': false,
    'receive-covenant-reports': false,
    'email-for-covenant-reports': false,
  },
};

/**
 * A loan may overwrite the company-configured platform interactions, this type
 * represents that overwrite.
 */
export type SLPlatformInteractionsType = {
  overwrite: boolean;
  'company-communication-address': CompanyAddressType;
  'company-e-invoice-configuration': CompanyEInvoiceConfiguration;
  'receive-interest-fixing-notification': EmailsOrNoneTransmissionType;
  'email-for-interest-fixing-notification': string;
  'receive-payment-instruction': PaymentNoticeTransmissionType;
  'email-for-payment-instruction': string;
  'receive-covenant-reports': EmailsOrNoneTransmissionType;
  'email-for-covenant-reports': string;
};
