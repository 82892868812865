import { NumberStripEmpty, ObjectStripUnknown, TextStripEmpty } from './atoms';
import { literal, sumTypeStripEmpty } from './base';

const MaturityCustomValidation = ObjectStripUnknown({
  type: literal('custom'),
  date: TextStripEmpty,
});

const MaturityUtilisationValidation = ObjectStripUnknown({
  type: literal('utilisation-years'),
  years: NumberStripEmpty.integer().min(0, 'Years must be a positive number'),
});

const MaturityAgreementValidation = ObjectStripUnknown({
  type: literal('agreement-years'),
  years: NumberStripEmpty.integer().min(0, 'Years must be a positive number'),
});

export const SLMaturityValidation = sumTypeStripEmpty({
  custom: MaturityCustomValidation,
  'utilisation-years': MaturityUtilisationValidation,
  'agreement-years': MaturityAgreementValidation,
});
