import AlternativeDepositsServices from '../../../../home/components/home-page-services/AlternativeDepositsServices';

export default function DepositAlternativeServiceDetails() {
  return (
    <div className="section is-grey">
      <h4 className="has-text-centered headline-underlined">ADDING DAILY LIQUIDITY TO A 100% ASSET BACKED CRE LOAN</h4>
      <AlternativeDepositsServices />
    </div>
  );
}
