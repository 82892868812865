import * as queryString from 'querystring';
import { useLocation } from 'react-router-dom';
import './CloseBtn.scss';

/**
 * Close button based on the location state.
 * E.g. either close window if it's a new tab or redirect back
 */
export default function CloseBtn(props: { callback?: Function }) {
  const location = useLocation();

  const closeCallback = () => {
    if (!props.callback) {
      if (queryString.decode(location.search.replace('?', '')).newTab === 'true') {
        window.close();
      } else {
        //TO DO
        // if (history.action === 'POP') {
        //   navigate(ClientPath.home);
        // } else {
        //   navigate(-1);
        // }
      }
    } else {
      props.callback();
    }
  };

  return (
    <button
      className="close-btn button is-inverted is-primary"
      onClick={() => {
        if (closeCallback) closeCallback();
      }}
    >
      <i className="fas fa-times"></i>
      Close
    </button>
  );
}
