import { AccessRightPair, RoutesAccessRightsMap } from '@realstocks/types';
import { useSelector } from 'react-redux';
import { getRoutesAccessRights } from '../../redux/redux-access-rights/access-rights-selector';
import { getCompanyInformation } from '../../redux/redux-company/company-selectors';
import { AppState } from '../../store';
import { CompanyInformationType, CompanyTypes } from '../../types/Company';

export enum PERMISSIONS {
  AGENT = 'AGENT',
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  BORROWER = 'BORROWER',
  LENDER = 'LENDER',
  ALL = 'ALL',
}

type PermissionChecker = (companyInformation: CompanyInformationType) => boolean;
type ValidatorMap = Record<PERMISSIONS, PermissionChecker>;

const validateCheckers = (companyInformation: CompanyInformationType, checkers: PermissionChecker[]): boolean => {
  return checkers.some((checker) => checker(companyInformation));
};

function allowPlatformAdmin(companyInformation: CompanyInformationType): boolean {
  return companyInformation.type === CompanyTypes.platform_admin;
}

function allowAgent(companyInformation: CompanyInformationType): boolean {
  return companyInformation.type === CompanyTypes.agent;
}

function allowLender(companyInformation: CompanyInformationType): boolean {
  return companyInformation.type === CompanyTypes.lender;
}

function allowBorrower(companyInformation: CompanyInformationType): boolean {
  return companyInformation.type === CompanyTypes.borrower;
}

function allowAll(companyInformation: CompanyInformationType): boolean {
  return (
    allowPlatformAdmin(companyInformation) ||
    allowAgent(companyInformation) ||
    allowLender(companyInformation) ||
    allowBorrower(companyInformation)
  );
}

const Validators: ValidatorMap = {
  [PERMISSIONS.PLATFORM_ADMIN]: allowPlatformAdmin,
  [PERMISSIONS.AGENT]: allowAgent,
  [PERMISSIONS.BORROWER]: allowBorrower,
  [PERMISSIONS.LENDER]: allowLender,
  [PERMISSIONS.ALL]: allowAll,
};

const checkPermissions = (companyInformation: CompanyInformationType, allowed: PERMISSIONS[] = []): boolean => {
  const checkers = allowed.map((permission) => Validators[permission]);
  return validateCheckers(companyInformation, checkers);
};

export function usePermissions(allowed: PERMISSIONS[]) {
  const companyInformation = useSelector((state: AppState) => getCompanyInformation(state));

  if (companyInformation && companyInformation.id) {
    return checkPermissions(companyInformation, allowed);
  }

  return false;
}

export function useAccessRightsRoutes(): RoutesAccessRightsMap {
  const accessRightsMap = useSelector(getRoutesAccessRights);
  return accessRightsMap;
}

// check if route is allowed based on access right pair
export function isRouteAllowed(map: RoutesAccessRightsMap, pair: AccessRightPair): boolean {
  const { module, objectType, action } = pair;
  const obj = map as any;
  const val: boolean | undefined = obj[module]?.[objectType]?.[action];
  return val === true;
}

/**
 * Quick check whether the user should be able to see a button.
 *
 * This isn't perfect, as it doesn't take the resource into account (can
 * the user do this action _on this specific thing_) - those kinds of
 * access rights should be included in the resource sent from the backend.
 */
export function useHasPermission(pair: AccessRightPair): boolean {
  const accessRightsMap = useAccessRightsRoutes();
  return isRouteAllowed(accessRightsMap, pair);
}
