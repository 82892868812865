import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, RequiredArray } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';
import { StructuredLoanCommandType } from './CommandType';
import { StructuredLoanEvent } from './EventType';

export type RunCommandsRequest = {
  loanId: string;
  commands: StructuredLoanCommandType[];
};

export const runCommandsRequestValidation = ObjectStripUnknown({
  loanId: NonEmptyString.required(),
  // The command objects will be fully validated by the LCE.
  commands: RequiredArray(Yup.object()),
});

export const runCommandsApi = defineApi<{
  body: RunCommandsRequest;
  response: StructuredLoanEvent[];
}>({
  url: 'lifecycle/run-commands',
  method: 'post',
});
