import { connect } from 'react-redux';
import { getNewNotificationsWithActions } from '../../redux/redux-notifications/notifications-selectors';
import { AppState } from '../../store';
import { TNotification } from '../../types/notifications';
import { EmptyState } from './NotificationItemTypes';
import { renderNotificationItem } from './utils';

type Props = {
  notifications: TNotification[];
  closeDropdown: Function;
};

const NewNotificationsWithActions = ({ notifications, closeDropdown }: Props) => {
  return (
    <div className="notifications-content">
      {(!notifications || notifications.length === 0) && <EmptyState type="outstanding" />}
      {notifications.map((notification) => renderNotificationItem(notification, closeDropdown))}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    notifications: getNewNotificationsWithActions(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewNotificationsWithActions);
