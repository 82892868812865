import { defineApi } from '../common/api-definition';
import { NextPastType } from '../common/NextPastType';
import { BookingPayloadType } from './CommandType';
import { TransactionNextCommandType } from './NextCommandType';
import { TransactionType } from './TransactionType';

export type TransactionPageDataType = NextPastType<TransactionNextCommandType, TransactionType>;

export type TransactionView = {
  loanId: string;
  sequenceId: string;
  pageData: TransactionPageDataType;
  bookingDataMap: { [bookingId: string]: BookingPayloadType[] };
  companyNames?: { [companyId: string]: string };

  currency: string;
  loanName: string;
  loanLevel: string;
  commitmentAmount: number;
  utilisationAmount: number;

  // True for bookings for which the custom amount can be set.
  allowCustomAmount: { [bookingId: string]: boolean };
};

export type TransactionViewRequest = {
  loanId: string;
  sequenceId?: string;
  transactionId?: string;
  eventId?: string;
};

export const transactionViewApi = defineApi<{
  body: { loanId: string; sequenceId?: string; transactionId?: string; eventId?: string };
  response: TransactionView;
}>({
  url: 'structured-loans/get-transaction-view',
  method: 'post',
});
