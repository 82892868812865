import CloseBtn from '../../components/close-btn/CloseBtn';
import RsList from '../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../components/rs-elements/rs-list/RsListItem';
import { ProcessEnv } from '../../constants/ProcessEnv';
import './ProfessionalInvestorStatus.scss';

export default function ProfessionalInvestorStatus() {
  const purposeOfThisAct: RsListItem[] = [
    new RsListItem(
      `an entity which is authorised or regulated to operate in the financial markets and an entity corresponding thereto under foreign supervision by the authorities: `
    ).setItems([
      new RsListItem(`an investment firm; `),
      new RsListItem(`a credit institution referred to in the Act on Credit Institutions; `),
      new RsListItem(
        `a management company and a depositary referred to in the Act on Common Funds as well as a manager of an alternative investment fund, a depositary and a special depositary referred to in the Act on Alternative Investment Fund Managers;`
      ),
      new RsListItem(`a stock exchange referred to in the Act on Trading in Financial Instruments;`),
      new RsListItem(
        `a central securities depositary, a foreign central securities depositary, and a central counterparty referred to in Act on the Book-Entry System and Settlement Activities; `
      ),
      new RsListItem(`an insurance company referred to in the Act on Insurance Companies; `),
      new RsListItem(
        `a pension insurance company referred to in the Act on Pension Insurance Companies, a pension foundation referred to in the Act on Pension Foundations and a pension fund referred to in the Act on Pension Funds; `
      ),
      new RsListItem(
        `local firms referred to in Article 4, paragraph 1, point 4 of the EU Capital Requirements Regulation;`
      ),
      new RsListItem(`an undertaking which buys and sells commodities and commodity derivatives on own account;`),
      new RsListItem(`an institutional investor other than one referred to in subparagraphs a-i;`),
    ]),
    new RsListItem(
      `a large undertaking which, according to its annual accounts drawn up of the last-ended full financial period, meets at least two of the following criteria: `
    ).setItems([
      new RsListItem(`the balance sheet total exceeds EUR 20,000,000; `),
      new RsListItem(`the turnover exceeds EUR 40,000,000; `),
      new RsListItem(`the own funds exceed EUR 2,000,000; `),
    ]),
    new RsListItem(
      `the State of Finland, the Finnish Treasury, the Province of Åland, a foreign State and state as well as a foreign public body that manages public debt; `
    ),
    new RsListItem(
      `the European Central Bank, the Bank of Finland and a corresponding foreign central bank as well as the IMF, European Investment Bank, the World Bank and another corresponding international community or organisation; `
    ),
    new RsListItem(`an institutional investor whose main activity is to invest in financial instruments; `),
    new RsListItem(
      `another client which the investment firm treats as professional on the basis of subsection 2.`
    ).setBold(),
  ];

  const criterias: RsListItem[] = [
    new RsListItem(
      `the client has carried out transactions in significant size on the relevant market at an average frequency of 10 per quarter over the previous four quarters; `
    ),
    new RsListItem(`the size of the client's investment portfolio exceeds EUR 500,000; `),
    new RsListItem(
      `the client works or has worked in the financial sector for at least one year in a professional position, which requires knowledge of the transactions and services envisaged. `
    ),
  ];

  return (
    <div className="container professional-investor-status">
      <div className="close-btn-container">
        <CloseBtn />
      </div>

      <h2 className="has-text-centered">Finnish - Act on Investment Services </h2>
      <h2 className="has-text-centered">Section 23 (1069/2017)</h2>
      <div className="columns is-centered">
        <a
          href={`${ProcessEnv.aws.s3.buckets.media}/pages/professional-investor-status/Professional-Investor-Status.pdf`}
          download="Realstocks - Act of Investment Services"
        >
          <button className="button is-primary download-btn">
            <span>
              <i className="fas fa-file-pdf"></i>
            </span>
            Download
          </button>
        </a>
      </div>

      <p>For the purposes of this Act, a professional client means:</p>
      <RsList checkmarked={false} items={purposeOfThisAct} />

      <p>
        <b>
          <u>An investment firm may treat a client</u>
        </b>{' '}
        other than one referred to in subsection 1, paragraphs 1-5{' '}
        <b>
          <u>as professional</u>
        </b>{' '}
        at the own request of the client if it assesses that the client is capable of making independent investment
        decisions and understands the risks involved therein and if the client satisfies,{' '}
        <b>
          <u>as a minimum, two of the following criteria</u>
        </b>
        :{' '}
      </p>

      <RsList checkmarked={false} items={criterias} />

      <p>
        In this Act <em>a retail client</em> means a client other than a professional client referred to in subsection
        1.
      </p>

      <p>
        <b>
          For the purposes of this Act, a client means a natural or legal person to whom an investment firm provides
          investment and ancillary services.
        </b>
      </p>

      <h3>Contact info:</h3>
      <p>
        <b>Realstocks Oy</b>
        <br />
        Business ID: Y-2942255-7
        <br /> Address: Mikonkatu 9, 00100 Helsinki, Finland
        <br />
        Email: <a href="mailto:support@realstocks.io">support@realstocks.io</a> <br />
        www: <a href="https://www.realstocks.io">www.realstocks.io</a>
      </p>

      <div className="columns is-centered">
        <CloseBtn />
      </div>
    </div>
  );
}
