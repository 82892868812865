import { matchStringExhaustive } from '../utils/strings';

export type PrepaymentReasonType =
  | {
      type: 'covenantBreach' | 'voluntaryPrepayment' | 'termination';
    }
  | {
      type: 'other';
      text: string;
    };

export const prettyPrepaymentReasonType = (type: PrepaymentReasonType['type']) =>
  matchStringExhaustive(type, {
    covenantBreach: () => 'Covenant Breach',
    voluntaryPrepayment: () => 'Voluntary Prepayment',
    termination: () => 'Termination',
    other: () => 'Other',
  });
