export enum BaseRatesQuerySortingKey {
  IndexName = 'indexName',
  SubmittedAt = 'submittedAt',
  QuotationDate = 'quotationDate',
  CompanyName = 'companyName',
  RateLevel = 'rateLevel',
  Currency = 'currency',
  PeriodLength = 'periodLength',
  SubmittedBy = 'submittedBy',
}
