import { entries } from '@realstocks/types';
import { LocalStorageKeys } from 'constants/LocalStorageKeys';
import { Cache } from 'swr';

export const clearUserData = ({ swrCache }: { swrCache: Cache }) => {
  entries(LocalStorageKeys).forEach(([key, value]) => {
    if (!['cognitoSession', 'inactiveTime'].includes(key)) {
      localStorage.removeItem(value);
    }
  });
  (swrCache as Map<string, unknown>).clear();
};
