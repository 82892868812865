import { ApplicationState } from '../../store';
import { APP_ACTIONS_KEYS } from './app-actions';

/**
 * Error Reducer takes care of the global error messages inside the application.
 *
 * @param {*} state
 * @param {*} action
 */
export const AppReducer = (
  state: ApplicationState = {
    initialized: false,
    loading: true,
    invisibleOverlay: false,
    socketInstance: null,
    syncTimestamp: 0,
  },
  action: any
) => {
  switch (action.type) {
    case APP_ACTIONS_KEYS.setInitialized:
      return {
        ...state,
        initialized: action.payload,
        loading: false,
      };
    case APP_ACTIONS_KEYS.setLoading:
      return {
        ...state,
        invisibleOverlay: action.payload === false ? false : true,
        loading: action.payload,
      };
    case APP_ACTIONS_KEYS.setSocketInstance:
      return {
        ...state,
        socketInstance: action.payload,
      };
    case APP_ACTIONS_KEYS.closeSocketInstance:
      if (state.socketInstance) {
        state.socketInstance.close();
      }
      return {
        ...state,
        socketInstance: null,
      };
    case APP_ACTIONS_KEYS.setSyncTimestamp:
      return {
        ...state,
        syncTimestamp: action.payload,
      };
    default:
      return state;
  }
};
