import { connect } from 'react-redux';
import { getAllNotifications } from '../../redux/redux-notifications/notifications-selectors';
import { AppState } from '../../store';
import { TNotification } from '../../types/notifications';
import { EmptyState } from './NotificationItemTypes';
import { renderNotificationItem } from './utils';

type Props = {
  notifications: TNotification[];
};

const AllNotifications = ({ notifications }: Props) => {
  return (
    <div className="notifications-content">
      {(!notifications || notifications.length === 0) && <EmptyState type="all" />}
      {notifications.map((notification) => renderNotificationItem(notification, () => {}))}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    notifications: getAllNotifications(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AllNotifications);
