import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import ClientPath from '../../../routes/ClientPath';
import { RsApplicationLinks } from '../../../routes/RsApplicationLinks';
import RsNavLink from './RsNavLink';

function LoggedOutMenuComponent() {
  const staticSiteLinks = [RsApplicationLinks().aboutUs(true), RsApplicationLinks().contact(true)];

  const realstocksServicesLinks = RsApplicationLinks().realstocksServices({
    marketplace: true,
    negotiation: true,
    administration: true,
    transfers: true,
    depositAlternative: true,
  });

  return (
    <Fragment>
      <div className="navbar-start ">
        <RsNavLink link={realstocksServicesLinks} />

        {staticSiteLinks.map((link) =>
          link.allow ? <RsNavLink key={`link_${link.path}`} link={link} exact={true}></RsNavLink> : ''
        )}
      </div>

      <div className="navbar-end">
        <NavLink
          className={({ isActive }) => (isActive ? 'login control navbar-item is-active' : 'login control navbar-item')}
          to={ClientPath.auth.login}
        >
          Log In
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? 'navbar-item register-btn-container control active'
              : 'navbar-item register-btn-container control'
          }
          to={ClientPath.auth.signup}
        >
          <button type="button" className="button is-primary is-inverted">
            Register Now
          </button>
        </NavLink>
      </div>
    </Fragment>
  );
}

export default LoggedOutMenuComponent;
