import { RsCognitoUser } from '@realstocks/types';
import { Dispatch } from 'redux';
import { UserCompanyType } from '../../types/UserTypes';
import { ReduxAction } from '../redux';

export const AuthActions = {
  setLoggedIn: (isLoggedIn: boolean | null): ReduxAction => {
    return { type: AUTH_ACTIONS.setLoggedIn, payload: isLoggedIn };
  },
  setCurrentUser: (currentUser: RsCognitoUser | null): ReduxAction => {
    return { type: AUTH_ACTIONS.setCurrentUser, payload: currentUser ? currentUser : null };
  },
  setUserCompaniesList: (companies: UserCompanyType[]): ReduxAction => {
    return { type: AUTH_ACTIONS.setUserCompaniesList, payload: companies };
  },
};

/**
 * Set current user instance into redux for easy accesibility
 *
 * @param {boolean} opened: boolean
 */
export function setCurrentUser(currentUser: RsCognitoUser | null) {
  return (dispatch: Dispatch<ReduxAction>) => {
    dispatch(AuthActions.setCurrentUser(currentUser));
  };
}

/**
 * Auth related actions
 */
export const AUTH_ACTIONS = {
  setLoggedIn: 'SET_USER_LOGGED_IN',
  setCurrentUser: 'SET_CURRENT_USER',
  setUserCompaniesList: 'SET_USER_COMPANIES_LIST',
};
