import { Link } from 'react-router-dom';
import ClientPath from '../../../../routes/ClientPath';

type Props = {
  email: string;
};

export default function LoginFooter(props: Props) {
  return (
    <div className="help-links is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
      <hr />
      <Link
        className="button is-text"
        to={`${ClientPath.auth.verifyAccount}/?email=${encodeURIComponent(props.email)}`}
      >
        Confirm / Verify Account
      </Link>
      <Link
        id="forgot-password"
        className="button is-text"
        to={`${ClientPath.auth.forgotPassword}/?email=${encodeURIComponent(props.email)}`}
      >
        Forgot Password
      </Link>
    </div>
  );
}
