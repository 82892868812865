import { boolean, mixed, object, string } from 'yup';
import { RepresentativeVerificationTypes } from '../company/RepresentativeVerificationTypes';
import { AssetUnderManagementMap, InvestorTypeMap } from '../fa/NegotiationType';
import { IbanValidation, OptionalString, RequiredString, RsDateFieldValidator } from './base';

export const AumValidation = string().when('type', {
  is: 'lender',
  then: RequiredString('AUM is required').oneOf(Object.keys(AssetUnderManagementMap)),
  otherwise: OptionalString.nullable(true),
});
export const InvestorTypeValidation = string().when('type', {
  is: 'lender',
  then: RequiredString('Investor Type is required').oneOf(Object.keys(InvestorTypeMap)),
  otherwise: OptionalString.nullable(true),
});

const nullableString = () => string().nullable(true).default(null);

export const CompanyAddressValidation = (nullable: boolean) => {
  if (nullable) {
    return {
      country: nullableString(),
      city: nullableString(),
      zipCode: nullableString(),
      street: nullableString(),
    };
  }

  return {
    country: string().typeError('Country is required').required('Country is required').default(null),
    city: string().typeError('City is required').required('City is required').default(null),
    zipCode: string().typeError('Zip code is required').required('Zip code is required').default(null),
    street: string().typeError('Street is required').required('Street is required').default(null),
  };
};

export const CompanyInformationValidation = {
  companyName: RequiredString('Company Name is required'),
  registrationNumber: RequiredString('Registration Number is required'),
  iban: IbanValidation,
  bic: OptionalString,
  legalForm: RequiredString('Legal Form is required'),
  industry: RequiredString('Industry is required'),
  sourceOfFunds: RequiredString('Source of Funds is required'),
  otherSourceOfFunds: string().when('sourceOfFunds', {
    is: 'other',
    then: string().required('Other source of funds is required.'),
    otherwise: OptionalString,
  }),
  incorporatedDate: RequiredString('Incorporated date is required'),
  investorType: InvestorTypeValidation,
  aum: AumValidation,
  website: string().test(
    'match',
    'Website should be a valid domain: i.e. "www.example.com" or "https://example.com" or "https://www.example.com"',
    (website: string) => {
      const expression =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      return website ? new RegExp(expression).test(website) : true;
    }
  ),
  email: RequiredString('Email is required').email(),
  address: object(CompanyAddressValidation(false)),
  contactPerson: RequiredString('Contact Person is required'),
  phone: RequiredString('Phone Number is required'),
  listed: boolean().required(
    'Company publicly listed on a regulated stock exchange in EU? Please answer with Yes / No.'
  ),
  professionalInvestorStatus: string()
    .oneOf(['1'], 'Realstocks services are currently only available for professional investors.')
    .required('Please confirm that you are a professional investor'),
  euStockExchange: string().when('listed', {
    is: true,
    then: string().min(1).required('EU Stock Exchange details are mandatory if company is publicly listed'),
    otherwise: OptionalString,
  }),
  type: RequiredString('Company type is mandatory').oneOf(['lender', 'borrower', 'agent']),
};

export const CompanyRepresentativeValidation = {
  name: string().required('Name is required.'),
  nationality: string().required('Nationality is required.'),
  role: string().required('Position is required.'),
  isPep: boolean().required('Politically Exposed Person ? Please answer with Yes / No.'),
  surname: string().required('Surname is required.'),
  dateOfBirth: RsDateFieldValidator().required('Date of Birth is required.'),
  verificationType: string()
    .oneOf([RepresentativeVerificationTypes.bankid, RepresentativeVerificationTypes.passport])
    .required('Verification type is required.'),
};

export const CompanyLMMConfigValidation = {
  autoSettlePaymentsOnValueDate: boolean().default(false).required('Auto-settle payments on value date is required'),
};

export const CompanyValidation = {
  companyInformation: object(CompanyInformationValidation),
  lmmConfig: object(CompanyLMMConfigValidation).nullable(true),
  prefilledData: mixed().nullable(true),
};
