import RsList from '../../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../../components/rs-elements/rs-list/RsListItem';
import { getStaticMedia } from '../../../../../constants/Aws';

export default function TransfersServiceBilateral() {
  const items: RsListItem[] = [
    new RsListItem(
      'Transfers only possible between lenders that match the acceptance criteria set by the borrower in advance'
    ),
    new RsListItem('All transfers need consent from the borrower'),
    new RsListItem(
      'When a transfer occurs, the incoming lender becomes a Finance Party and a beneficiary of the security'
    ),
    new RsListItem('All open transfers (buy/sell) are shown on the Open Market page'),
  ];
  return (
    <div className="section text-bottom">
      <h4 className="has-text-centered headline-underlined">
        BILATERAL TRANSFERS WITH OTHER ONBOARDED & ACCEPTED LENDERS
      </h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
          <div className="column is-6">
            <div className="img-container">
              <img src={getStaticMedia('/images/static-pages/transfers_2.png')} />
            </div>
          </div>
        </div>
      </div>

      <h4 className="has-text-centered section-footer">
        FULL TRANSFER HISTORY & LOGS FOR REPORTING AVAILABLE ON PLATFORM & VIA API
      </h4>
    </div>
  );
}
