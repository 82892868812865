import { RoutesAccessRightsMap } from '@realstocks/types';

export const AccessRightsActions = {
  setRoutesMap: (map: RoutesAccessRightsMap) => {
    return { type: ACCESS_RIGHTS_ACTIONS.setRoutesMap, payload: map };
  },
  setFetched: (isFetched: boolean) => {
    return { type: ACCESS_RIGHTS_ACTIONS.setFetched, payload: isFetched };
  },
  reset: () => {
    return { type: ACCESS_RIGHTS_ACTIONS.reset };
  },
};

/**
 * App related actions
 */
export const ACCESS_RIGHTS_ACTIONS = {
  setRoutesMap: 'SET_ROUTES_MAP',
  setFetched: 'SET_ROUTES_FETCHED',
  reset: 'RESET',
};
