/** Types for specifying information undertakings */

import { InterestPeriodType } from '../loan/InterestPeriodType';
import { matchString } from '../utils/strings';
import { IUReportedAgainstDraftType, IUReportedAgainstType } from './IUReportedAgainstType';

export type InformationUndertakingSpecType = {
  id: string;
  name: string;
  schedule: InformationUndertakingScheduleType;
  deadline?: number;
  qualification?: InformationUndertakingQualificationType;
  collections: InformationCollectionSpecType[];
  reportedAgainst: IUReportedAgainstType;
};

export type InformationUndertakingSpecDraftType = {
  id: string;
  name: string;
  deadline?: number;
  schedule: InformationUndertakingScheduleDraftType;
  qualification?: InformationUndertakingQualificationType;
  collections?: InformationCollectionDraftType[];
  reportedAgainst?: IUReportedAgainstDraftType;
};

export type InformationCollectionSpecType = {
  id: string; // ID of loan metric for which to collect
};

export type InformationCollectionDraftType = {
  id?: string;
};

export const informationUndertakingQualifications = ['audited', 'unaudited'] as const;

export type InformationUndertakingQualificationType = (typeof informationUndertakingQualifications)[number];

export const QualificationLabels = {
  audited: 'Audited',
  unaudited: 'Unaudited',
};

export type ScheduleAlignmentType = (typeof scheduleAlignments)[number];
export const scheduleAlignments = ['start-date', 'calendar'] as const;

export const prettyScheduleAlignment = (x: ScheduleAlignmentType) =>
  matchString(x, {
    calendar: () => 'Calendar',
    'start-date': () => 'Reporting Date',
  });

export enum ScheduleFirstDateAt {
  LOAN_UTILISATION = 'loan-utilisation',
  PROJECT_COMPLETION = 'project-completion',
  CUSTOM_DATE = 'custom-date',
}

export enum ScheduleTerminationDateAt {
  LOAN_TERMINATION = 'loan-termination',
  PROJECT_COMPLETION = 'project-completion',
  CUSTOM_DATE = 'custom-date',
}

export const defaultReportingEventSchedule: Readonly<InformationUndertakingScheduleDraftType & { type: 'recurring' }> =
  {
    type: 'recurring',
    alignment: 'calendar',
    period: 'quarterly',
    startDateAt: ScheduleFirstDateAt.LOAN_UTILISATION,
    terminationDateAt: ScheduleTerminationDateAt.LOAN_TERMINATION,
  };

export type InformationUndertakingScheduleType =
  | {
      type: 'recurring';
      startDate?: string; // default: utilisation
      period: InterestPeriodType;
      alignment: ScheduleAlignmentType;
      terminationDate?: string; // default: loan termination
    }
  | {
      type: 'non-recurring';
      checkDate: string;
      period: InterestPeriodType;
    };

export type InformationUndertakingScheduleDraftType =
  | {
      type: 'recurring';
      startDate?: string;
      startDateAt?: ScheduleFirstDateAt;
      period?: InterestPeriodType;
      alignment?: ScheduleAlignmentType;
      terminationDate?: string;
      terminationDateAt?: ScheduleTerminationDateAt;
    }
  | {
      type: 'non-recurring';
      checkDate?: string;
      period?: InterestPeriodType;
    };
