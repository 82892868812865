import Auth from '@aws-amplify/auth';
import { batchActions } from 'redux-batched-actions';
import store from '../../store';
import { AuthActions } from './../../redux/redux-auth/auth-actions';
import { CompanyActions } from './../../redux/redux-company/company-actions';

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    throw error;
  }

  loggedOutDispatch();
}

async function loggedOutDispatch() {
  store.dispatch(batchActions([AuthActions.setCurrentUser(null), CompanyActions.reset()]));
}
