import { RsCognitoUser } from '@realstocks/types';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ProcessEnv } from '../../constants/ProcessEnv';
import { WebsocketEvents } from '../../constants/WebsocketConstants';
import { setSocketInstance } from '../../redux/redux-app/app-actions';
import { getCurrentUserOrNull } from '../../redux/redux-auth/auth-selectors';
import { getConversations, pushNewMessage, setUserPresence } from '../../redux/redux-chat/chat-actions';
import { getCompanyKycStatus } from '../../redux/redux-company/company-selectors';
import { facilityAgreementChanged } from '../../redux/redux-loans/loan-actions';
import { NotificationsActions } from '../../redux/redux-notifications/notifications-actions';
import { AppState } from '../../store';
import { CompanyKycStatusType, COMPANY_KYC_STATUSES } from '../../types/Company';

type Props = {
  currentUser: RsCognitoUser | null;
  companyKycStatus: CompanyKycStatusType | null;
  setSocketInstance: Function;
  pushNewMessage: Function;
  setUserPresence: Function;
  getConversations: Function;
  facilityAgreementChanged: typeof facilityAgreementChanged;
  getAllNotifications: Function;
};

type ISocketToRedux = {
  event: string;
  page?: string;
  callback: Function;
};

const SocketManager: React.FC<Props> = (props) => {
  const location = useLocation();

  React.useEffect(() => {
    if (props.currentUser && props.companyKycStatus && props.companyKycStatus === COMPANY_KYC_STATUSES.APPROVED) {
      initSocket();
    } else {
      closeSocket();
    }
  }, [props.currentUser, props.companyKycStatus]);

  const updateNotifications = () => {
    props.getAllNotifications();
  };

  const socketToReduxDataArray: Array<ISocketToRedux> = [
    { event: WebsocketEvents.userPresenceStatus, callback: props.setUserPresence },
    { event: WebsocketEvents.receiveChatMessage, callback: props.pushNewMessage },
    { event: WebsocketEvents.facilityAgreementChanged, callback: props.facilityAgreementChanged },
  ];

  const socket_url = ProcessEnv.aws.socket;
  let socket = useRef<WebSocket | null>(null);

  const initSocket = () => {
    const currentUserJwt = props.currentUser
      ? props.currentUser.getSignInUserSession()?.getIdToken().getJwtToken()
      : '';
    closeSocket(); // for prev socket
    socket.current = new WebSocket(`${socket_url}?jwtToken=${currentUserJwt}`);

    socket.current.onmessage = (event: MessageEvent) => {
      if (event.data) {
        try {
          let eventData = JSON.parse(event.data);

          if (eventData.event === WebsocketEvents.updateGlobalNotifications) {
            if (eventData.data.eventType !== 'CHAT') {
              updateNotifications();
            }
          }

          for (let socketToReduxObject of socketToReduxDataArray) {
            if (
              eventData.event === socketToReduxObject.event &&
              (!socketToReduxObject.page || location.pathname.includes(socketToReduxObject.page))
            ) {
              socketToReduxObject.callback(eventData.data);
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }
    };

    socket.current.onclose = (e: CloseEvent) => {
      if (e.code === 1001) {
        // To reconnect if socket gets disconnected due to inactivity.
        initSocket();
      }
    };

    socket.current.onopen = () => {};

    props.setSocketInstance(socket);
  };

  const closeSocket = () => {
    if (socket.current) {
      socket.current.close();
      props.setSocketInstance(socket);
    }
  };

  return null;
};

const mapStateToProps = (state: AppState) => {
  return {
    currentUser: getCurrentUserOrNull(state),
    companyKycStatus: getCompanyKycStatus(state),
  };
};

const mapDispatchToProps = {
  setSocketInstance: setSocketInstance,
  pushNewMessage: pushNewMessage,
  setUserPresence: setUserPresence,
  getConversations: getConversations,
  facilityAgreementChanged: facilityAgreementChanged,
  getAllNotifications: NotificationsActions.getAllNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocketManager);
