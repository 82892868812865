/**
 * A helper to match on just a string union.
 */
export function matchString<K extends string, A, F extends K = K>(
  v: K,
  cases: { [P in F]: (c: P) => A }
): K extends F ? A : A | undefined {
  return cases[v as unknown as F]?.(v as unknown as F) as unknown as any;
}

/**
 * A helper to match on just a string union. Will error if any cases are missing.
 */
export function matchStringExhaustive<K extends string, A>(v: K, cases: { [P in K]: (c: P) => A }): A {
  return cases[v]?.(v);
}

/**
 * Lodash' `isEmpty` treats the number 0 as empty, which is not what we want
 */
export function isEmptyish<T>(v: T | null | undefined): v is undefined | null {
  if (v === undefined || v === null) return true;
  if (typeof v === 'string' && v.trim().length < 1) return true;
  return false;
}
