import { RsApiParamsInterface } from './RsApi';
import { RsApiConfig } from './RsApiConfig';

export class RsApiParams implements RsApiParamsInterface {
  public path: string;
  public config: RsApiConfig;

  constructor(path: string, config = new RsApiConfig()) {
    this.path = path;
    this.config = config;

    return this;
  }
}
