import RegisterNowComponent from '../../../../components/register-now/RegisterNowComponent';
import DepositAlternativeServiceBenefits from './components/DepositAlternativeServiceBenefits';
import DepositAlternativeServiceComparation from './components/DepositAlternativeServiceComparation';
import DepositAlternativeServiceDetails from './components/DepositAlternativeServiceDetails';
import DepositAlternativeServiceHeader from './components/DepositAlternativeServiceHeader';
import './RealstocksServiceDepositAlternative.scss';

export function RealstocksServiceDepositAlternative() {
  return (
    <div className="realstocks-service-deposit-alternative">
      <DepositAlternativeServiceHeader />
      <DepositAlternativeServiceDetails />
      <DepositAlternativeServiceComparation />
      <DepositAlternativeServiceBenefits />
      <RegisterNowComponent />
    </div>
  );
}
