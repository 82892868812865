import RegisterNowComponent from '../../../../components/register-now/RegisterNowComponent';
import NegotiationServiceHeader from './components/NegotiationServiceHeader';
import NegotiationServiceLegal from './components/NegotiationServiceLegal';
import NegotiationServiceOfferings from './components/NegotiationServiceOfferings';
import NegotiationServiceTemplates from './components/NegotiationServiceTemplates';
import './RealstocksServiceNegotiation.scss';

export function RealstocksServiceNegotiation() {
  return (
    <div className="realstocks-service-negotiation">
      <NegotiationServiceHeader />
      <NegotiationServiceTemplates />
      <NegotiationServiceLegal />
      <NegotiationServiceOfferings />
      <RegisterNowComponent />

      {/* add padding to <section></section> with padding to be reused */}
    </div>
  );
}
