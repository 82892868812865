import {
  AgreementType,
  CompanyPublicType,
  DocuSignIdentityVerificationType,
  LoanCompanyType,
  LoanUserMapType,
  LoanUserType,
  NegotiationType,
  TemplateType,
  TransitionType,
} from '@realstocks/types';
import { AgreementPageViewType } from '../../types/loans/AgreementPageViewType';

export const setLoan = (loan: NegotiationType) => ({
  type: 'SET_LOAN' as const,
  loan: loan,
});

export const setAgreement = (resource: 'agreement' | 'ndaAgreement', template: string, agreement: AgreementType) => ({
  type: 'SET_AGREEMENT' as const,
  resource,
  template,
  agreement,
});

export const setCompanies = (companies: LoanCompanyType[]) => ({
  type: 'SET_COMPANIES' as const,
  companies: companies,
});

export const setCompaniesPublic = (companies: CompanyPublicType[]) => ({
  type: 'SET_COMPANIES_PUBLIC' as const,
  companies: companies,
});

export const resetCompanies = () => ({
  type: 'RESET_COMPANIES' as const,
});

export const setAllUsers = (users: LoanUserType[]) => ({
  type: 'SET_ALL_USERS' as const,
  users: users,
});

export const setAllReferencedUsers = (id: string, users: LoanUserMapType) => ({
  type: 'SET_ALL_REFERENCED_USERS' as const,
  id: id,
  users: users,
});

export const setTransitions = (id: string, transitions: TransitionType[]) => ({
  type: 'SET_TRANSITIONS' as const,
  id: id,
  transitions: transitions,
});

export const setTemplates = (templates: TemplateType[]) => ({
  type: 'SET_TEMPLATES' as const,
  templates: templates,
});

export const resetTemplates = () => ({
  type: 'RESET_TEMPLATES' as const,
});

export const setDocuSignIdentityVerification = (identityVerification: DocuSignIdentityVerificationType) => ({
  type: 'SET_DOCUSIGN_IDENTITY_VERIFICATION' as const,
  identityVerification: identityVerification,
});

export const setAgreementPageView = (loanId: string, pageView: AgreementPageViewType) => ({
  type: 'SET_AGREEMENT_PAGE_VIEW' as const,
  loanId,
  pageView,
});

export const setAgreementSelectedSection = (loanId: string, selectedSection: string) => ({
  type: 'SET_AGREEMENT_SELECTED_SECTION' as const,
  loanId,
  selectedSection,
});

export const facilityAgreementChanged = (data: { id: string }) => ({
  type: 'FACILITY_AGREEMENT_CHANGED' as const,
  data,
});

export const resetLoanState = () => ({
  type: 'RESET_ALL' as const,
});

export type LoanActions =
  | ReturnType<typeof setLoan>
  | ReturnType<typeof setAgreement>
  | ReturnType<typeof setCompanies>
  | ReturnType<typeof setCompaniesPublic>
  | ReturnType<typeof setAllUsers>
  | ReturnType<typeof setAllReferencedUsers>
  | ReturnType<typeof setTransitions>
  | ReturnType<typeof setTemplates>
  | ReturnType<typeof resetTemplates>
  | ReturnType<typeof setAgreementPageView>
  | ReturnType<typeof setAgreementSelectedSection>
  | ReturnType<typeof facilityAgreementChanged>
  | ReturnType<typeof setDocuSignIdentityVerification>
  | ReturnType<typeof resetCompanies>
  | ReturnType<typeof resetLoanState>;
