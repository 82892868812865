import { FieldMetaProps } from 'formik';
import { FieldIssuesType } from '../RsForm';

export const getInputErrorClass = (
  name: string,
  meta: FieldMetaProps<any>,
  ignoreRequireTouched: boolean,
  getFieldIssues?: (fieldName: string) => FieldIssuesType | undefined
): string => {
  if ((meta.touched || ignoreRequireTouched) && meta.error) return 'has-error';
  else if (!meta.touched && getFieldIssues) {
    const fieldIssues = getFieldIssues(name);
    if (fieldIssues) {
      return fieldIssues.level === 'warning' ? 'field-issues-warning' : 'field-issues-error';
    }
  }
  return '';
};
