import { Pagination } from '../common/pagination.type';
import { Sorting } from '../common/sorting.type';
import { IndexObjectType } from '../index-table/index-object-type.enum';
import { IndexUpdateStatus } from '../index-table/index-update-status.enum';
import { IndexUpdateType } from '../index-table/index-update-type.enum';

export enum IndexLogsQuerySortingKey {
  Id = 'id',
  CreatedAt = 'createdAt',
  Cause = 'cause',
  Type = 'type',
  ObjectId = 'objectId',
  ObjectType = 'objectType',
  ProcessTime = 'processTime',
  Status = 'status',
  ErrorMessage = 'errorMessage',
}

export interface IndexLogsQueryInputDto {
  search?: string;
  withStatus?: boolean;
  sorting?: Sorting<IndexLogsQuerySortingKey>;
  pagination?: Pagination;
}

export interface IndexLogsQueryOutputItem {
  cause: string;
  type: IndexUpdateType;
  objectId: string;
  objectType: IndexObjectType;
  processTime: number;
  status: IndexUpdateStatus;
  errorMessage?: string;
  logId?: string;
  logCreatedAt?: string;
}

export interface IndexLogsQueryOutputDto {
  items: IndexLogsQueryOutputItem[];
  count: number;
}
