import { getStaticMedia } from '../../../../../constants/Aws';
import {
  HomepageServiceBlock,
  HomepageServiceSquareComponent,
} from '../../../../home/components/home-page-services/HomepageServiceBlock';

export default function DepositAlternativeServiceHeader() {
  return (
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6">
            <div className="container-center">
              <HomepageServiceBlock
                service={
                  new HomepageServiceSquareComponent(
                    'Deposit Alternative',
                    getStaticMedia('/icons/static-pages/deposit-alternative.svg'),
                    '',
                    '',
                    true
                  )
                }
              />
            </div>
          </div>
          <div className="column is-6">
            <h4 className="has-text-centered headline-underlined">PRODUCT VISION</h4>
            <p className="has-text-weight-bold has-text-centered">
              This product requires an FSA license, an exchange offering digital asset trading and an efficient
              settlement solution.
            </p>
            <p className="has-text-weight-bold has-text-centered">
              Realstocks combines super core fully asset backed commercial real estate loans with digital asset trading
              capabilities of established exchanges.
            </p>
            <p className="has-text-weight-bold has-text-centered">
              Resulting in positive returns with high liquidity for investors.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
